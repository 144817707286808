import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  createStyles,
  Dialog,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router";

import "jspdf-autotable";
import { CustomTheme } from "../../../../../types/customTheme";
import {
  fetchAllCancelSalesInfo,
  fetchAllUserInfo,
} from "../../../../../services/salesApp/salesService";
import { getCookie } from "../../../../../utils/cookies";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../../utils/consts";
import TableBody from "./TableBody";
import { fetchAllDiscountInfoSales } from "../../../../../services/globalService";
import SkeleTonLoading from "../../../../../components/common/Loading/SkeleTonLoading";

export interface PaymentReportInfoNodeProps {
  openSaleDetailsModal: any;
  setOpenSaleDetailsModal: any;
  filterDetails: any;
  discountOrGroupFilterDetails: any;
}

const useStyles = (nodesLength: number, bodyLength: number) =>
  makeStyles((theme: CustomTheme) => createStyles({}));

const SaleDetailsModal: React.FunctionComponent<PaymentReportInfoNodeProps> = ({
  openSaleDetailsModal,
  setOpenSaleDetailsModal,
  filterDetails,
  discountOrGroupFilterDetails,
}) => {
  const dialogContentRef: any = useRef(null);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [discountInfo, setDiscountInfo] = useState<any>([]);
  const [salesNodeList, setSalesNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(false);
  const [userList, setUserList] = useState<any>();
  const [userListFilter, setUserListFilter] = useState<any>([]);
  const idToken = getCookie("idToken");
  const match: any = useRouteMatch();

  const handleGetSaleDetailsInfo = async () => {
    try {
      const res = await fetchAllCancelSalesInfo(
        idToken,
        match.params.locationId,
        200000,
        1,
        "DESC",
        "date",
        discountOrGroupFilterDetails,
      );

      setSalesNodeList(res.data.data);
      setIsOpenSkeletonLoading(false);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
    setIsOpenSkeletonLoading(false);
  };

  const handleUserList = (userDetails: any) => {
    const userArray: any = [];
    const userObject: any = {};
    // Used the filter
    Object.values(userDetails).map((data: any) => {
      userArray.push({ id: data.id, label: data.name });
      if (!_.isEmpty(userList)) {
        if (_.isEmpty(userList[data.id])) {
          userObject[data.id] = { id: data.id, label: data.name };
        }
      } else {
        userObject[data.id] = { id: data.id, label: data.name };
      }
    });
    setUserListFilter(userArray);
    getAllDiscountInfo();
    setUserList((prevState: any) => ({ ...prevState, ...userObject }));
  };

  const getUserInfo = async (locationId: any) => {
    try {
      const res = await fetchAllUserInfo(idToken, locationId);
      if (!_.isEmpty(res.data.data)) {
        handleUserList(res.data.data);
      } else {
        setUserListFilter([]);
        handleGetSaleDetailsInfo();
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const getAllDiscountInfo = async () => {
    fetchAllDiscountInfoSales(match.params.locationId)
      .then((res) => {
        setDiscountInfo(res.data.data);
        handleGetSaleDetailsInfo();
      })
      .catch(() => {
        setIsOpenSkeletonLoading(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    if(openSaleDetailsModal){
      getUserInfo(match.params.locationId);
      setIsOpenSkeletonLoading(true);
    }

  }, [openSaleDetailsModal]);

  const theme: CustomTheme = useTheme();

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "10px",
          border: `1px solid ${theme.palette.background.entity_border}`,
          backgroundColor: theme.palette.background.default,
        },
      }}
      open={openSaleDetailsModal}
      fullWidth
      disableBackdropClick
      onClose={() => setOpenSaleDetailsModal(false)}
      style={{ zIndex: 4 }}
      maxWidth="xl"
    >
      {!isOpenSkeletonLoading ? (
        <TableBody
          nodeList={salesNodeList}
          filterDetails={filterDetails}
          userList={userList}
          nodes={nodes}
          setNodes={setNodes}
          discountInfo={discountInfo}
          setOpenSaleDetailsModal={setOpenSaleDetailsModal}
        />
      ) : (
        <div style={{ margin: "24px" }}>
          <SkeleTonLoading isReport={true} />
        </div>
      )}
    </Dialog>
  );
};

export default SaleDetailsModal;
