import { PaletteType } from "@material-ui/core";

export default {
  palette: {
    type: "light" as PaletteType,
    primary: {
      main: "#00e676",
    },
    secondary: {
      main: "#ffeb3b",
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF",
      sidebarBackground: "#F7F9FB",
      imgBackground: "#F5F5F5",
      entity_background: "#FCFCFC",
      menu_entity_background: "#E8E8E8",
      entity_highlight_background: "#F4F5F7",
      entity_highlight_alternative: "#DDF0F8",
      entity_highlight_background_color_strip: "#444850",
      entity_dialog_textfield_background: "#F4F5F7",
      entity_highlight_background_Default: "#FEFEFE",
      entity_highlight_directory_background:"#FAFAFA", 
      entity_highlight_background_mode_title: "#FEFEFE",
      entity_highlight_background_mode_prev: "#FAFBFC",
      entity_highlight_background_mode_current: "#eeeff0",
      entity_highlight_background_body_mode: "#FCFDFD",
      entity_highlight_background_body_current: "#D1D6DA",
      entity_highlight_background_mode_footer: "#EDF1F5",
      entity_highlight_background_prev_footer: "#e9eef3",
      entity_highlight_background_current_footer: "#e0e2e3",
      table_header_background: "#F6F8FA",
      g_line_1: "#18B5FA",
      g_line_2: "#E5BB4F",
      g_line_3: "#CF5B6A",
      g_line_4: "#895EC4",
      g_line_5: "#3A9D76",
      g_line_lighter_1: "#8fdcff",
      g_line_lighter_2: "#f7e6a1",
      g_line_lighter_3: "#f2a4b0",
      g_line_lighter_4: "#bda4d7",
      g_line_lighter_5: "#45ba8b",
      g_line_lighter_6: "#18B5FA",
      entity_border: "#DFE1E6",
      entity_border_highlight_background: "#DFE1E6",
      entity_border_entity_background: "#DFE1E6",
      entity_border_menu_entity_background: "#CCCDD1",
    },
    error: {},
    warning: {},
    info: {},
    contrastThreshold: 3,
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      icon: "rgba(0, 0, 0, 0.12)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.8,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    custom: {
      yellow: {
        main: "#E5BB4F",
        dark: "#E5BB4F",
        light: "#E5BB4F",
        contrastText: "#253858",
      },
      green: {
        main: "#3A9D76",
        dark: "#3A9D76",
        light: "#3A9D76",
        contrastText: "#fff",
      },
      blue: {
        main: "#18B5FA",
        dark: "#18B5FA",
        light: "#18B5FA",
        contrastText: "#fff",
      },
      orange: {
        main: "#F08F3A",
        dark: "#F08F3A",
        light: "#F08F3A",
        contrastText: "#253858",
      },
      red: {
        main: "#CF5B6A",
        dark: "#CF5B6A",
        light: "#CF5B6A",
        contrastText: "#fff",
        secondary: "#ff1515",
      },
      purple: {
        main: "#895EC4",
        dark: "#895EC4",
        light: "#895EC4",
        contrastText: "#fff",
      },
      grey: {
        main: "#7D7D7D",
        dark: "#D3D3D3",
        light: "#7D7D7D",
        contrastText: "#fff",
      },
    },
  },
};
