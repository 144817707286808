import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import {
  fetchLoyaltyProgramInfo,
  fetchRewardsInfo,
} from "../../../../services/customerApp/customerDetails";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CustomerNode from "./CustomerNode";
import AlertDialogDefault from "../../../../components/alerts/AlertDialogDefault";
import { Hidden } from "@material-ui/core";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";

/**
 * RewardListPage Component
 *
 * This component is responsible for rendering a paginated list of customer data.
 * It fetches customer information from an API and allows users to navigate through pages and sort the data.
 */
const RewardListPage: React.FunctionComponent<{}> = () => {
  const [rewardNode, setRewardNode] = useState<any>([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [programId, setProgramId] = useState("");

  const match: any = useRouteMatch();

  const handleGetRewardsDetails = async (programId: any) => {
    try {
      const res = await fetchRewardsInfo(match.params.locationId, programId);
      setRewardNode(res.data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleGetLocationProgram = async () => {
    try {
      const { data } = await fetchLoyaltyProgramInfo(match.params.locationId);
      const programDetails: any = Object.values(data.data)[0];
      const programId: any = Object.keys(data.data)[0];
      setIsActive(programDetails?.status);
      if (programDetails?.status) {
        setIsOwner(
          programDetails?.owner === match.params.locationId ? true : false,
        );
        handleGetRewardsDetails(programId);
        setProgramId(programId);
      } else {
        setIsLoading(false);
      }
      return data.data;
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Customer - Loyalty Rewards";
    handleGetLocationProgram();
  }, []);

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <CustomerNode
        rewardNode={rewardNode}
        isLoading={isLoading}
        isOwner={isOwner}
        programId={programId}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
      <AlertDialogDefault
        open={!isActive}
        title="Warning"
        desc="Customer loyalty points management is disabled on your account. Please contact your EPOS account manager to request access."
        severity="warning"
        color="secondary"
        disableBackdropClick
      />
    </>
  );
};

export default withAuthority(RewardListPage, Authorities.CUSTOMER_LIST_READ);
