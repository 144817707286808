import { axiosClient } from "../client";


const queryString = require("query-string");

const SALE_ITEM_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/sales/item`;

const SALE_ITEM_INFO_GET_ALL_VERSION_2 = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/sales/item-v2`;

export const fetchAllItemsReportInfo = (token, locationId, filterData, basedOnShift) => 
axiosClient({
  method: "GET",
  url: queryString.stringifyUrl({
    url: SALE_ITEM_INFO_GET_ALL(locationId),
    query: {  where: filterData, based_on_shift: basedOnShift }}, {sort: false, arrayFormat: 'comma', skipNull: true, skipEmptyString: true},
    
  ),
  headers: {
    "X-Role-Location": locationId,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const fetchAllItemsReportVersion2Info = (token, locationId, filterData, basedOnShift) => 
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: SALE_ITEM_INFO_GET_ALL_VERSION_2(locationId),
      query: {  where: filterData, based_on_shift: basedOnShift }}, {sort: false, arrayFormat: 'comma', skipNull: true, skipEmptyString: true},
      
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
