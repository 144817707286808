import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import "jspdf-autotable";
import { Icon } from "@iconify/react";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { handleExcel } from "./convertPdfExcel/convertDataToExcel";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import { sortNumbers, sortStrings } from "../../../../utils/commonArrayMap";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import SaleDetailsModal from "./SaleDetailsModal";

export interface PaymentReportInfoNodeProps {
  discountReportNodeList: any;
  locationSelectorList: any;
  filterDetails: any;
  filterData: any;
  basedOnShift: any;
  locationSelectedList: any;
  handleOnClickText: any;
  userDetails: any;
  discountInfo: any;
}

const useStyles = (nodesLength: number, bodyLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "16px",
        fontSize: "12px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "12px",
      },
      endTextStyle: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
        paddingRight: "20px",
      },
      endTextStyleIndexFour: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
      },
      headerEndTextStyle: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "8px",
      },
      footerEndTextStyle: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
      },
      footerEndTextStyles: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "20px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRowFinal: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${nodesLength + 2})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      finalTotalRow: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${bodyLength + 1})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
        [`&:nth-of-type(n+${bodyLength + 2}):nth-of-type(-n+${
          nodesLength + 1
        })`]: {
          color: theme.palette.custom["purple"].contrastText,
          backgroundColor: theme.palette.custom.purple.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.purple.light,
          },
        },
      },
      totalRow: {
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(n)": {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "25%",
          width: "25%",
        },
        [`&:nth-of-type(n+2):nth-of-type(-n+${3})`]: {
          minWidth: "25%",
          width: "25%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        "&:nth-of-type(4)": {
          minWidth: "25%",
          width: "25%",
          "& .css-16gtl2w": {
            paddingRight: "12px",
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
          },
        },
      },
    }),
  );

/**
 * DiscountSummaryReportNode Component
 *
 * This component renders a table displaying payment report data.
 * It also provides options to export the report as PDF or CSV.
 * Users can view sale details by clicking on items in the table.
 */
const DiscountSummaryReportNode: React.FunctionComponent<
  PaymentReportInfoNodeProps
> = ({
  discountReportNodeList,
  locationSelectorList,
  filterDetails,
  filterData,
  basedOnShift,
  locationSelectedList,
  handleOnClickText,
  userDetails,
  discountInfo,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [preDefinedPdfHeaders, setPreDefinedPdfHeaders] = useState<any>([]);
  const [regularHeadersList, setRegularHeadersList] = useState<any>([]);
  const [preDefinedHeadersList, setPreDefinedHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [preDefinedExcelHeaders, setPreDefinedExcelHeaders] = useState<any>([]);
  const [regularNodes, setRegularNodes] = useState<any>({ nodes: [] });
  const [preDefinedNodes, setPreDefinedNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [preDefinedNodesPdfExcel, setPreDefinedNodesPdfExcel] = useState<any>(
    [],
  );
  const [discountOrGroupFilterDetails, setDiscountOrGroupFilterDetails] =
    useState("");

  const [isOpenSaleDetailsModal, setIsOpenSaleDetailsModal] = useState(false);
  const [nodesLength, setNodeLength] = useState(0);
  const [bodyLength, setBodyLength] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 1600px)");
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const convertToInteger = (str: any) => {
    return Math.floor(parseFloat(str));
  };

  useEffect(() => {
    setRegularNodes({ nodes: [] });
    setPreDefinedNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /* Creating the data for the Payment report table and creating the data for pdf and excel files.
       Using a API request */
  useEffect(() => {
    let cloneDiscountReportNode: any = _.cloneDeep(discountReportNodeList);
    let footer: any = [];

    if (!_.isEmpty(cloneDiscountReportNode)) {
      const regularDiscountSummaryNodeList: any = [];
      const preDefinedSummaryNodeList: any = [];

      cloneDiscountReportNode.map((value: any) => {
        const discount = value.discountOrGroup;
        const discountValue = parseFloat(value.totalDiscountValue).toFixed(2);
        const noOfOrders = value.saleCount;
        const salesTotal = parseFloat(value.totalSalesAmount).toFixed(2);

        if (value.discountType === "regular") {
          if (discount.toString() !== "0") {
            regularDiscountSummaryNodeList.push({
              discount: convertToInteger(discount),
              discountValue,
              noOfOrders,
              salesTotal,
              discountOrGroup: discount,
            });
          }
        } else {
          if (discount.toString() !== "-99") {
            preDefinedSummaryNodeList.push({
              discount: discountInfo[discount]?.title || discount,
              discountValue,
              noOfOrders,
              salesTotal,
              discountOrGroup: discount,
            });
          }
        }
      });

      // Set pdf headers
      setPdfHeaders([
        { title: "Discount %", field: "discount" },
        { title: "Discount Value", field: "discountValue" },
        { title: "No of Orders", field: "noOfOrders" },
        { title: "Sales Total", field: "salesTotal" },
      ]);

      setPreDefinedPdfHeaders([
        { title: "Discount Name", field: "discount" },
        { title: "Discount Value", field: "discountValue" },
        { title: "No of Orders", field: "noOfOrders" },
        { title: "Sales Total", field: "salesTotal" },
      ]);

      // Set excel headers
      setExcelHeaders([
        { header: "Discount %", key: "discount", width: 52, height: 68 },
        {
          header: "Discount Value",
          key: "discountValue",
          width: 32,
          height: 68,
        },
        { header: "No of Orders", key: "noOfOrders", width: 32, height: 68 },
        {
          header: "Sales Total",
          key: "salesTotal",
          width: 32,
          height: 68,
        },
      ]);

      setPreDefinedExcelHeaders([
        { header: "Discount Name", key: "discount", width: 52, height: 68 },
        {
          header: "Discount Value",
          key: "discountValue",
          width: 32,
          height: 68,
        },
        { header: "No of Orders", key: "noOfOrders", width: 32, height: 68 },
        {
          header: "Sales Total",
          key: "salesTotal",
          width: 32,
          height: 68,
        },
      ]);

      // Set table headers
      setRegularHeadersList([
        "Discount %",
        "Discount Value",
        "No of Orders",
        "Sales Total",
      ]);

      setPreDefinedHeadersList([
        "Discount Name",
        "Discount Value",
        "No of Orders",
        "Sales Total",
      ]);
      setNodeLength(regularDiscountSummaryNodeList.length + 1);

      // Payment report length
      setBodyLength(regularDiscountSummaryNodeList.length);

      // set the table data in the state
      setRegularNodes({ nodes: regularDiscountSummaryNodeList });

      setPreDefinedNodes({ nodes: preDefinedSummaryNodeList });

      // set the table footer in the state
      // setFooter(footer);

      // Merge the body and footer of PDF/ Excel.
      // const merged = [...regularDiscountSummaryNodeList, ...footer];
      // const merged = [...regularDiscountSummaryNodeList];

      // set the pdf excel data in the state
      setNodesPdfExcel(regularDiscountSummaryNodeList);
      setPreDefinedNodesPdfExcel(preDefinedSummaryNodeList);
    }
  }, [discountReportNodeList, userDetails]);

  // Create a pay In Out sorting function
  const sort = useSort(
    regularNodes,
    {
      state: {
        sortKey: "Discount %",
        reverse: false,
      },
    },
    {
      sortFns: {
        "Discount %": (array) => sortNumbers(array, "discount"),
        "Discount Value": (array) => sortNumbers(array, "discountValue"),
        "No of Orders": (array) => sortNumbers(array, "noOfOrders"),
        "Sales Total": (array) => sortNumbers(array, "salesTotal"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  const sortPreDefined = useSort(
    regularNodes,
    {},
    {
      sortFns: {
        "Discount Name": (array) => sortNumbers(array, "discount"),
        "Discount Value": (array) => sortNumbers(array, "discountValue"),
        "No of Orders": (array) => sortNumbers(array, "noOfOrders"),
        "Sales Total": (array) => sortNumbers(array, "salesTotal"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSaleModal = (item: any) => {
    const filter = `discountGroup!(${item.discountOrGroup}),` + filterData;
    setDiscountOrGroupFilterDetails(filter);
    setIsOpenSaleDetailsModal(true);
  };

  const handleOpenSaleModalPercentage = (item: any) => {
    const filter = `discountPercent>${item.discountOrGroup},discountPercent<${item.discountOrGroup}.99,` + filterData;
    setDiscountOrGroupFilterDetails(filter);
    setIsOpenSaleDetailsModal(true);
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const theme: CustomTheme = useTheme();
  const classes: any = useStyles(nodesLength, bodyLength)();
  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {(!_.isEmpty(regularNodes.nodes) ||
              !_.isEmpty(preDefinedNodes.nodes)) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { marginLeft: "16px" }
                }
              >
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        preDefinedPdfHeaders,
                        nodesPdfExcel,
                        preDefinedNodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleExcel(
                        excelHeaders,
                        preDefinedExcelHeaders,
                        nodesPdfExcel,
                        preDefinedNodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      {!_.isEmpty(regularNodes.nodes) && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "8px",
            }}
          >
            <Typography variant="h5">Percentage Discounts Summary</Typography>
          </div>
          <CardCommon>
            <Table
              data={regularNodes}
              sort={sort}
              layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
            >
              {(tableList) => (
                <>
                  <Header style={{ zIndex: 0 }}>
                    <HeaderRow className={classes.headerStyle}>
                      {regularHeadersList.map((data: any, index: any) => (
                        <HeaderCellSort
                          sortKey={data}
                          pinLeft={index === 0 ? true : false}
                          className={classes.baseCellRow}
                        >
                          <Typography
                            variant="caption"
                            className={
                              index === 0
                                ? classes.startTextStyle
                                : classes.textStyleHeader
                            }
                            style={{
                              color: theme.palette.custom.orange.contrastText,
                              fontWeight: "bold",
                            }}
                          >
                            {data}
                          </Typography>
                        </HeaderCellSort>
                      ))}
                    </HeaderRow>
                  </Header>

                  <Body className={classes.bodyStyle}>
                    {tableList.map((item, bodyIndex) => (
                      <>
                        <Row
                          key={item.gross20}
                          item={item}
                          className={classes.headerRow}
                          onClick={() => {
                            handleOpenSaleModalPercentage(item);
                          }}
                        >
                          {Object.keys(item).map((data: any, index: any) => (
                            <>
                              {data !== "discountOrGroup" && (
                                <Cell
                                  pinLeft={index === 0 ? true : false}
                                  className={classes.baseCellRow}
                                >
                                  <Typography
                                    variant="caption"
                                    align="left"
                                    className={
                                      index === 0
                                        ? classes.startTextStyle
                                        : index === 3
                                        ? classes.endTextStyle
                                        : classes.textStyle
                                    }
                                    style={
                                      data === "name"
                                        ? { fontWeight: "bold" }
                                        : { fontWeight: "normal" }
                                    }
                                  >
                                    {item[data]}
                                  </Typography>
                                </Cell>
                              )}
                            </>
                          ))}
                        </Row>
                      </>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          </CardCommon>
        </>
      )}

      {!_.isEmpty(preDefinedNodes.nodes) && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            <Typography variant="h5">Predefined Discounts Summary</Typography>
          </div>
          <CardCommon>
            <Table
              data={preDefinedNodes}
              sort={sortPreDefined}
              layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
            >
              {(tableList) => (
                <>
                  <Header style={{ zIndex: 0 }}>
                    <HeaderRow className={classes.headerStyle}>
                      {preDefinedHeadersList.map((data: any, index: any) => (
                        <HeaderCellSort
                          sortKey={data}
                          pinLeft={index === 0 ? true : false}
                          className={classes.baseCellRow}
                        >
                          <Typography
                            variant="caption"
                            className={
                              index === 0
                                ? classes.startTextStyle
                                : classes.textStyleHeader
                            }
                            style={{
                              color: theme.palette.custom.orange.contrastText,
                              fontWeight: "bold",
                            }}
                          >
                            {data}
                          </Typography>
                        </HeaderCellSort>
                      ))}
                    </HeaderRow>
                  </Header>

                  <Body className={classes.bodyStyle}>
                    {tableList.map((item, bodyIndex) => (
                      <>
                        <Row
                          key={item.gross20}
                          item={item}
                          className={classes.headerRow}
                          onClick={() => {
                            handleOpenSaleModal(item);
                          }}
                        >
                          {Object.keys(item).map((data: any, index: any) => (
                            <>
                              {data !== "discountOrGroup" && (
                                <Cell
                                  pinLeft={index === 0 ? true : false}
                                  className={classes.baseCellRow}
                                >
                                  <Typography
                                    variant="caption"
                                    align="left"
                                    className={
                                      index === 0
                                        ? classes.startTextStyle
                                        : index === 3
                                        ? classes.endTextStyle
                                        : classes.textStyle
                                    }
                                    style={
                                      data === "name"
                                        ? { fontWeight: "bold" }
                                        : { fontWeight: "normal" }
                                    }
                                  >
                                    {item[data]}
                                  </Typography>
                                </Cell>
                              )}
                            </>
                          ))}
                        </Row>
                      </>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          </CardCommon>
        </>
      )}

      <SaleDetailsModal
        openSaleDetailsModal={isOpenSaleDetailsModal}
        setOpenSaleDetailsModal={setIsOpenSaleDetailsModal}
        filterDetails={filterDetails}
        discountOrGroupFilterDetails={discountOrGroupFilterDetails}
      />
    </>
  );
};

export default DiscountSummaryReportNode;
