import { Checkbox, Chip, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      paddingLeft: "12px",
      paddingRight: "12px",
      marginTop: "8px",
      borderRadius: "10px",
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      width: "100%",
      backgroundColor: theme.palette.background.entity_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface Props {
  selectorList: { label: string; id: string }[];
  getLocationSelectorTypingList: any;
  handleSelectedList: any;
  searchLocationName: any;
  setSearchLocationName: any;
  selectedList: any;
  multiple?: any;
}

/* This component allows the user to select and type the desired item using dropdowns. That item is given after 2 seconds.
    After clicking the 'All Locations' checkbox, all locations can be selected. */
const MultipleSelectorTextField: React.FunctionComponent<Props> = ({
  selectorList,
  getLocationSelectorTypingList,
  handleSelectedList,
  searchLocationName,
  setSearchLocationName,
  selectedList,
  multiple = true,
}) => {
  /* After two seconds of typing the last letter, the typed word is given. */
  useEffect(() => {
    const timeOutId = setTimeout(
      () => getLocationSelectorTypingList(searchLocationName),
      2000,
    );
    return () => clearTimeout(timeOutId);
  }, [searchLocationName]);

  /* Set the search value */
  const handleLocationList = (e: any) => {
    setSearchLocationName(e.target.value);
  };

  /* Get the selected item name. */
  const getOptionLabel = (option: any) => `${option.label}`;

  const allSelected = selectorList.length === selectedList.length;

  const filter = createFilterOptions();

  /* Show the location select checkbox. */
  const optionRenderer = (option: any, { selected }: any) => {
    const selectAllProps: any =
      option.id === "selectAll" && allSelected ? { checked: selectorList } : {};
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {getOptionLabel(option)}
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
      </div>
    );
  };

  /* This function is called when one or more items are selected, and when all items are cleared */
  const handleSelectAll = (isSelected: any) => {
    // If selected all items,
    if (isSelected) {
      handleSelectedList("location", selectorList);
    } else {
      // If not selected any item,
      handleClearOptions();
    }
  };

  /* This function is called when one or more items are selected. */
  const handleToggleOption = (selectedOptions: any) => {
    handleSelectedList("location", selectedOptions);
  };

  /* This function is called when all items are selected. */
  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  /* This function is called when all items are creared. */
  const handleClearOptions = () => {
    handleSelectedList("location", []);
  };

  /* Checking whether the user has selected an item, removed the item, 
     and all items have been removed and calling the function based on that reason. */
  const handleChange = (event: any, selectedOptions: any, reason: any) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option: any) => option.id === "selectAll")) {
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  const handleChangeSingle = (
    event: any,
    selectedOptions: any,
    reason: any,
  ) => {
    if (reason === "select-option" || reason === "remove-option") {
      handleToggleOption && handleToggleOption([selectedOptions]);
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  /* Get the selected item id. */
  const getOptionSelected = (option: any, anotherOption: any) => {
    return option.id === anotherOption.id;
  };

  /* Remove the selected location */
  const handleDelete = () => {
    handleSelectedList("location", []);
  };

  const classes = useStyles();

  return (
    <>
      {multiple ? (
        <Autocomplete
          className={classes.root}
          multiple
          disableClearable
          id="multiple_selector_id"
          options={selectorList}
          value={selectedList}
          style={{ width: "100%" }}
          getOptionLabel={(option) => option.label}
          onChange={handleChange}
          getOptionSelected={getOptionSelected}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            return [{ label: "All Locations", id: "selectAll" }, ...filtered];
          }}
          renderOption={optionRenderer}
          renderTags={(value, getTagProps) =>
            selectorList.length === selectedList.length ? (
              <Chip
                style={{ maxWidth: "70%" }}
                variant="outlined"
                label={"All Locations"}
                onDelete={handleDelete}
              />
            ) : (
              value.map((option, index) => (
                <Chip
                  style={{ maxWidth: "70%" }}
                  variant="outlined"
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              className={classes.textField}
              onChange={(e: any) => {
                handleLocationList(e);
              }}
            />
          )}
        />
      ) : (
        <Autocomplete
          className={classes.root}
          disableClearable
          id="multiple_selector_id"
          options={selectorList}
          value={selectedList[0]}
          style={{ width: "100%" }}
          getOptionLabel={(option) => option.label || ""}
          onChange={handleChangeSingle}
          getOptionSelected={getOptionSelected}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            return [...filtered];
          }}
          renderOption={optionRenderer}
          renderTags={(value, getTagProps) =>
            selectorList.length === selectedList.length ? (
              <Chip
                style={{ maxWidth: "70%" }}
                variant="outlined"
                label={"All Locations"}
                onDelete={handleDelete}
              />
            ) : (
              value.map((option, index) => (
                <Chip
                  style={{ maxWidth: "70%" }}
                  variant="outlined"
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              className={classes.textField}
              onChange={(e: any) => {
                handleLocationList(e);
              }}
            />
          )}
        />
      )}
    </>
  );
};

export default MultipleSelectorTextField;
