import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import _ from "lodash";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  handleSubmit: any;
  categoryList: any;
  handleHidden: any;
  handleHVisible: any;
  disableSales: any;
  handleClose: any;
}

const EditModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  handleSubmit,
  categoryList,
  handleHidden,
  handleHVisible,
  disableSales,
  handleClose,
}) => {
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Enable Columns</DialogTitle>
        <DialogContent>
          <Grid container>
            {categoryList &&
              categoryList.map(
                (data: any) =>
                   (
                    <>
                      <Grid item xs={8}>
                        {data.label}
                      </Grid>
                      <Grid item xs={4}>
                        {disableSales.includes(data.id) ? (
                          <Button onClick={() => handleHVisible(data.id)}>
                            <VisibilityOffIcon />
                          </Button>
                        ) : (
                          <Button onClick={() => handleHidden(data.id)}>
                            <VisibilityIcon />
                          </Button>
                        )}
                      </Grid>
                    </>
                  ),
              )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleClose}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            color={"orange"}
            onClick={handleSubmit}
          >
            {"Save"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default EditModal;
