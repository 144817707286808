import React from "react";
import _ from "lodash";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";

export interface CustomerNodeProps {
  isOpen: any;
  setIsOpen: any;
  isEdit: any;
  handleReset: any;
  isLoadingButton: any;
  handleSubmitData: any;
  title: any;
  subTitle: any;
  description: any;
  img: any;
  isError: any;
  setIsError: any;
  setTitle: any;
  setSubTitle: any;
  setDescription: any;
  setImg: any;
}

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

const BannerCreateAndUpdateModal: React.FunctionComponent<
  CustomerNodeProps
> = ({
  isOpen,
  setIsOpen,
  isEdit,
  handleReset,
  isLoadingButton,
  handleSubmitData,
  title,
  subTitle,
  description,
  img,
  isError,
  setIsError,
  setTitle,
  setSubTitle,
  setDescription,
  setImg,
}) => {
  const handleDisable = () => {};
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Add New Banner</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>Title</Typography>
              <div style={{ display: "flex" }}>
                <TextfieldDefaultNew
                  id="title"
                  name="title"
                  type="text"
                  value={title}
                  onChange={(e: any) => {
                    setTitle(e.target.value);
                    setIsError(false);
                  }}
                />
              </div>
              {!title && isError ? (
                <ValidationMessage message={"Title is required"} />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>Sub Title</Typography>
              <div style={{ display: "flex" }}>
                <TextfieldDefaultNew
                  id="subTitle"
                  name="subTitle"
                  type="text"
                  value={subTitle}
                  onChange={(e: any) => setSubTitle(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Description
              </Typography>
              <div style={{ display: "flex" }}>
                <TextfieldDefaultNew
                  id="description"
                  name="description"
                  type="text"
                  value={description}
                  onChange={(e: any) => setDescription(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>Img</Typography>
              <div style={{ display: "flex" }}>
                <TextfieldDefaultNew
                  id="img"
                  name="img"
                  type="text"
                  value={img}
                  onChange={(e: any) => setImg(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleReset}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={isEdit ? "orange" : "green"}
            onClick={handleSubmitData}
          >
            {isEdit ? "Save" : "Create"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default BannerCreateAndUpdateModal;
