import { Box, Checkbox, Grid, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import CardCommon from "../../../../components/card/CardCommon";
import { Theme } from "../../../../types/customTheme";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import {
  convertDateFormat,
  convertDateTimeFormat,
  convertTimeFormat,
} from "../../../../utils/ConvertDateTimeFormat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridField: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "12px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
      },
    },
    gridFieldLast: {
      display: "flex",
      justifyContent: "end",
      placeItems: "center",
      fontSize: "12px",
      paddingRight: "16px",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingRight: "0px",
        marginBottom: "12px",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      fontSize: "12px",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    buttonClick: {
      minHeight: "48px",
      width: "100%",
      margin: "0px",
      padding: "0px",
      color: "white",
    },
    buttonNotClick: {
      minHeight: "48px",
      width: "100%",
      margin: "0px",
      padding: "0px",
    },
    liveStyle: {
      color: theme.palette.custom.green.main,
      fontSize: "14px",
      marginRight: "8px",
      marginBottom: "1px",
    },
  }),
);

export interface ShiftInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  setIdList: any;
  idList: any;
  setSelectedList: any;
  selectedList: any;
  numberOfLocations: any;
  deviceIdObj: any;
  deviceIdErr: any;
  setIsSelectAllShift: any;
}

/**
 * ShiftInfoNode Component:
 *
 * This component represents a single node (or row) in the Shift component,
 * displaying detailed information about a shift. It includes data such as
 * location, terminal, start date/time, end date/time, and duration. The component
 * allows users to select shifts, view shift details, and provides visual indicators
 * for live shifts.
 */
const ShiftInfoNode: React.FunctionComponent<ShiftInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  setIdList,
  idList,
  setSelectedList,
  selectedList,
  numberOfLocations,
  deviceIdObj,
  deviceIdErr,
  setIsSelectAllShift,
}) => {
  const [locationName, setLocationName] = useState("");
  const [saleId, setSaleId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState<any>("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState("");

  /* Get date difference between start and end date. Return date format is days, hours, minutes, and seconds  */
  const timeDiffCalc = (dateFuture: any, dateNow: any) => {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate seconds
    const seconds = Math.floor(diffInMilliSeconds);
    diffInMilliSeconds -= seconds * 60;

    let difference = "";
    if (days > 0) {
      difference += `${days} days`;
    }

    if (hours !== 0) {
      if (days !== 0) {
        difference += ", ";
      }
      difference += `${hours} hours`;
    }
    if (minutes !== 0) {
      if (hours !== 0) {
        difference += ", ";
      }
      difference += `${minutes} min`;
    }
    if (seconds !== 0) {
      if (minutes !== 0) {
        difference += ", ";
      }
      difference += `${seconds} sec`;
    }

    return difference;
  };

  const setNodeDataToState = useCallback((nodeValues) => {
    const { locationId, startDate, endDate, deviceId } = nodeValues;
    const locationDetails = getFilterListFromArrayObject(
      locationSelectorList,
      locationId,
    );

    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      setLocationName(locationDetails[0].label);
    } else {
      // If there is no description of the location, the state will be entered as 'Unknown location'.
      setLocationName("Unknown location");
    }
    setDeviceId(deviceId);
    setStartDate(convertDateFormat(startDate));
    setStartTime(convertTimeFormat(startDate));
    if (endDate === null) {
      setEndDate("-");
      setEndTime("-");
    } else {
      setEndDate(convertDateFormat(endDate));
      setEndTime(convertTimeFormat(endDate));
    }

    const duration = endDate
      ? timeDiffCalc(new Date(endDate), new Date(startDate))
      : "";
    setDuration(duration);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const handleOpenSaleDetails = (nodeData: any) => {
    const { id, locationId, startDate, endDate } = nodeData;
    if (!idList.includes(id)) {
      const locationName = getFilterListFromArrayObject(
        locationSelectorList,
        locationId,
      );
      setSaleId(id);
      //Set selected saleId
      setIdList([...idList, id]);
      //Set selected shift information
      if (deviceIdErr) {
        setSelectedList([
          ...selectedList,
          {
            id: id,
            Location: locationName[0].label,
            Terminal: handleTerminal(),
            "Start Date": convertDateTimeFormat(startDate),
            "End Date": endDate === null ? "-" : convertDateTimeFormat(endDate),
            Duration: endDate ? duration : "Live Shift",
          },
        ]);
      } else {
        setSelectedList([
          ...selectedList,
          {
            id: id,
            Location: locationName[0].label,
            Terminal: handleTerminal(),
            TID: handleTid(),
            "Start Date": convertDateTimeFormat(startDate),
            "End Date": endDate === null ? "-" : convertDateTimeFormat(endDate),
            Duration: endDate ? duration : "Live Shift",
          },
        ]);
      }
    } else {
      //remove selected idList
      const removeIdList = idList.filter((data: any) => data !== id);
      const removeSelectedList = selectedList.filter(
        (data: any) => data.id !== id,
      );
      setIsSelectAllShift(false);
      setSelectedList(removeSelectedList);
      setIdList(removeIdList);
      setSaleId("");
    }
  };

  const handleChangeSaleRowColor = (sale: any) => {
    const { id } = sale;
    if (idList.includes(id)) {
      return "green";
    }
  };

  const handleTerminal = () => {
    const data = Object.values(deviceIdObj).filter(
      (data: any) => data.dev_id === deviceId,
    );

    const idObj: any = data[0];
    if (idObj) {
      switch (idObj?.sub_type) {
        case "-99":
          return "POS";
        case "pay-kiosk":
          return "Pay Kiosk";
        case "order-kiosk":
          return "Order Kiosk";
        case "wix":
          return "Online POS";
        default:
          return idObj.sub_type;
      }
    } else {
      return deviceId;
    }
  };

  const handleTid = () => {
    const data = Object.values(deviceIdObj).filter(
      (data: any) => data.dev_id === deviceId,
    );

    const idObj: any = data[0];
    if (idObj) {
      return `T-${idObj.prefix}`;
    } else {
      return deviceId;
    }
  };

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();

  return (
    <>
      <CardCommon
        color={handleChangeSaleRowColor(nodeData)}
        backgroundColor={saleId !== nodeData.id && "entity_background"}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            className={
              saleId === nodeData.id
                ? classes.buttonClick
                : classes.buttonNotClick
            }
            style={{
              minHeight: "44px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => handleOpenSaleDetails(nodeData)}
          >
            <Box
              display="grid"
              gridTemplateColumns="repeat(18, 1fr)"
              style={{ minHeight: "44px", alignItems: "center" }}
            >
              {numberOfLocations > 1 && (
                <Box
                  gridColumn={
                    maxWidth
                      ? "span 18"
                      : numberOfLocations > 1 && !deviceIdErr
                      ? "span 3"
                      : "span 4"
                  }
                >
                  <Grid
                    style={{
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                    }}
                    item
                    className={classes.gridFieldFirstColumn}
                  >
                    <Checkbox checked={idList.includes(nodeData.id)} />
                    {locationName}
                  </Grid>
                </Box>
              )}
              <Box gridColumn={maxWidth ? "span 18" : "span 2"}>
                <Grid
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                  item
                  className={
                    numberOfLocations > 1
                      ? classes.gridField
                      : classes.gridFieldFirstColumn
                  }
                >
                  {!(numberOfLocations > 1) && (
                    <Checkbox checked={idList.includes(nodeData.id)} />
                  )}
                  {handleTerminal()}
                </Grid>
              </Box>
              {!deviceIdErr && (
                <Box gridColumn={maxWidth ? "span 18" : "span 2"}>
                  <Grid
                    style={{ textAlign: "left" }}
                    item
                    className={classes.gridField}
                  >
                    {handleTid()}
                  </Grid>
                </Box>
              )}
              <Box
                gridColumn={
                  maxWidth
                    ? "span 18"
                    : numberOfLocations > 1
                    ? "span 2"
                    : "span 3"
                }
              >
                <Grid
                  style={{ textAlign: "left" }}
                  item
                  className={classes.gridField}
                >
                  {startDate}
                </Grid>
              </Box>
              <Box gridColumn={maxWidth ? "span 18" : "span 2"}>
                <Grid
                  style={{ textAlign: "left" }}
                  item
                  className={classes.gridField}
                >
                  {startTime}
                </Grid>
              </Box>
              <Box
                gridColumn={
                  maxWidth
                    ? "span 18"
                    : numberOfLocations > 1
                    ? "span 2"
                    : "span 3"
                }
              >
                <Grid
                  style={{ textAlign: "left" }}
                  item
                  className={classes.gridField}
                >
                  {endDate}
                </Grid>
              </Box>
              <Box gridColumn={maxWidth ? "span 18" : "span 2"}>
                <Grid
                  style={{ textAlign: "left" }}
                  item
                  className={classes.gridField}
                >
                  {endTime}
                </Grid>
              </Box>
              <Box
                gridColumn={
                  maxWidth
                    ? "span 18"
                    : numberOfLocations > 1
                    ? !deviceIdErr
                      ? "span 3"
                      : "span 4"
                    : !deviceIdErr
                    ? "span 4"
                    : "span 6"
                }
              >
                <Grid
                  item
                  style={{ textAlign: "right" }}
                  className={classes.gridFieldLast}
                >
                  {duration ? (
                    duration
                  ) : (
                    <>
                      <FiberManualRecordIcon className={classes.liveStyle} />
                      Live Shift
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default withAuthority(ShiftInfoNode, Authorities.SALE_READ);
