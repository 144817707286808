import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";

import { getCookie } from "../../../../utils/cookies";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchBannerInfo, fetchLoyaltyProgramInfo } from "../../../../services/customerApp/customerDetails";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import BannerNode from "./BannerNode";
import { Box, Hidden } from "@material-ui/core";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";
import AlertDialogDefault from "../../../../components/alerts/AlertDialogDefault";

/**
 * BannerListPage Component
 *
 * This component is responsible for rendering a paginated list of customer data.
 * It fetches customer information from an API and allows users to navigate through pages and sort the data.
 */
const BannerListPage: React.FunctionComponent<{}> = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [bannerNode, setBannerNode] = useState<any>([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");
  /**
   * handleGetBannerDetails Function
   *
   * This function is responsible for fetching customer data from the API based on specified parameters.
   * It sets the page size, current page, and sorting method based on the input parameters.
   * It then makes an API call to fetch customer data and updates the state with the response.
   * If there is an error during the API call, it sets an error message in the state.
   * Finally, it sets the isLoading state to false to indicate that data fetching is complete.
   */
  const handleGetBannerDetails = async () => {
    try {
      const res = await fetchBannerInfo(match.params.locationId);
      setBannerNode(res.data.data);
    } catch (err) {
      // If there is an error during the API call, set an error message in the state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
    setIsLoading(false);
  };

  const handleGetLocationProgram = async () => {
    try {
      const { data } = await fetchLoyaltyProgramInfo(match.params.locationId);
      const programDetails: any = Object.values(data.data)[0];
      setIsActive(programDetails?.status);
      if (programDetails?.status) {
        setIsOwner(
          programDetails?.owner === match.params.locationId ? true : false,
        );
        handleGetBannerDetails();
      } else {
        setIsLoading(false);
      }
      return data.data;
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * useEffect Hook
   *
   * This hook is used to perform side effects in the component.
   * In this case, it sets the document title, fetches customer data, and fetches location information.
   */
  useEffect(() => {
    // Set the document title
    document.title = "Customer - Loyalty Banners";

    // Fetch location information
    handleGetLocationProgram();
  }, []);

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <BannerNode isLoading={isLoading} bannerNode={bannerNode} isOwner={isOwner} />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
       <AlertDialogDefault
        open={!isActive}
        title="Warning"
        desc="Customer loyalty points management is disabled on your account. Please contact your EPOS account manager to request access."
        severity="warning"
        color="secondary"
        disableBackdropClick
      />
    </>
  );
};

export default withAuthority(BannerListPage, Authorities.CUSTOMER_LIST_READ);
