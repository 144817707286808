import moment from "moment";
import React from "react";

export const convertDateTimeFormatAndConvertTimeBold = (date: string) => (
  <div style={{ display: "flex", textTransform: "none" }}>
    {convertDateTimeFormat(date).slice(
      0,
      convertDateTimeFormat(date).length - 5,
    )}
    <div style={{ fontWeight: "bold" }}>
      {convertDateTimeFormat(date).slice(-5)}
    </div>
  </div>
);

/* Convert date time format. */
export const convertDateTimeFormat = (date: string) =>
  moment(date).format("MMM DD,\xa0 YYYY\xa0\xa0\xa0 HH:mm");

/* Convert date time format. */
export const convertDateTimeFormatInventory = (date: string) =>
  moment(date).format("Do MMM YYYY\xa0\xa0\xa0 hh:mm A");

/* Convert date format. */
export const convertDateFormat = (date: string) =>
  moment(date).format("MMM DD,\xa0 YYYY");

export const convertDateFormatYearValidation = (date: string) => {
  if (moment(date).format("YYYY") === moment(new Date()).format("YYYY")) {
    return moment(date).format("MMM DD");
  } else {
    return moment(date).format("MMM DD,\xa0 YYYY");
  }
};

/* Convert time format. */
export const convertTimeFormat = (date: string) => moment(date).format("HH:mm");

/** Convert a given date to a moment object with the format 'YYYY-MM-DD' */
export const convertDateToMoment = (date: any) =>
  moment(date).format("YYYY-MM-DD");

/** Get the start date of the given month in the format 'YYYY-MM-DD' */
export const getStartDateDateMonthYear = (date: any, startFormat: any) =>
  moment(date).startOf(startFormat).format("YYYY-MM-DD");

/** Get the end date of the given month in the format 'YYYY-MM-DD' */
export const getEndDateDateMonthYear = (date: any, endFormat: any) =>
  moment(date).endOf(endFormat).format("YYYY-MM-DD");
