import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Card,
  createStyles,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CardCommon from "../../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../../types/customTheme";
import { da } from "date-fns/locale";


const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    cardStyleGreen: {
      height: "60px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.custom.green.main,
      },
    },
    cardStyleRed: {
      height: "60px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.custom.red.main,
      },
    },
    cardStyle: {
      height: "60px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.custom.green.main,
      },
    },
    cardStylePurple: {
      height: "60px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.custom.blue.main,
      },
    },
  }),
);

export interface AvailabilitySelectionModalProps {
  shiftReportNode: any;
}

/* This creates the banking and summery tables. This component only works if the key is banking or summery. */
const BankingInfoTable: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({
  shiftReportNode,
}) => {
  const [nodes, setNodes] = useState<any>([]);

  useEffect(() => {
    setNodes(shiftReportNode);
  }, [shiftReportNode]);


  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <div style={{ marginTop: "24px" }}>
        <Card
          style={{
            borderRadius: "10px",
          }}
        >
          {!_.isEmpty(nodes) &&
            nodes.map((data: any, index: any) => (
              <div
                className={
                  data.type === "Declared Cash" ||
                  data.type === "Target Cash" ||
                  data.type === "Cash to Bank" ||
                  (data.type === "Deficit / Surplus" &&
                    parseFloat(data.amount) === 0)
                    ? classes.cardStylePurple
                    : data.type === "Deficit / Surplus" &&
                      parseFloat(data.amount) > 0
                    ? classes.cardStyleGreen
                    : data.type === "Deficit / Surplus" &&
                      parseFloat(data.amount) < 0
                    ? classes.cardStyleRed
                    : classes.cardStyle
                }
              >
                <Grid
                  container
                  style={{ placeItems: "center", height: "60px" }}
                >
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: "16px", fontWeight: "bold" }}
                  >
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      {data.type}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingRight: "16px",
                      display: "flex",
                      justifyContent: "end",
                      color: "white",
                    }}
                  >
                    {data.amount}
                  </Grid>
                </Grid>
              </div>
            ))}
        </Card>
    </div>
  );
};

export default BankingInfoTable;
