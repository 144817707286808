import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import {
  createMapProductInfo,
  deleteMapProductInfo,
  fetchHardwareInfo,
  fetchPrinterMappingInfo,
  fetchProductConfigInfo,
  fetchSinglePrinterMappingInfo,
  fetchStrategyInfo,
  updateMapProductInfo,
} from "../../../../../services/menuApp/printerMenuService";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../../utils/consts";
import CardCommon from "../../../../../components/card/CardCommon";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { HttpStatus, buttonColors } from "../../../../../utils/enum";
import PrinterNode from "./PrinterNode";
import { AxiosError } from "axios";
import { CustomTheme } from "../../../../../types/customTheme";
import { saveLogs } from "../../../../../utils/firebase/logs";

export interface MappingModalProps {
  isOpen: any;
  setIsOpen: any;
  setError: any;
  printerMappingItemName: any;
  selectedPrinterMappingItemOrCategoryId: any;
  type: any;
  selectedCatMenuTitle: any;
  categoryName: any;
  setIsOpenModal: any;
  isStack: any;
  setIsOpenStackProductModal: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    discountTypeSection: {
      borderRadius: 8,
    },
    tabPanel: {
      backgroundColor: theme.palette.background.paper,
    },
    circleStyle: {
      fontSize: "16px",
      marginTop: "4px",
      color: theme.palette.custom.green.main,
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    cancelButtonStyle: {
      marginTop: "0px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "12px",
      },
    },
  }),
);

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`discount-type-tab-${index}`}
      aria-labelledby={`discount-type-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "16px 0" }}>{children}</div>}
    </div>
  );
};

/**
 * MappingModal Component
 *
 * This component renders a modal for mapping printers. It displays tabs for different discount types
 * and allows users to map printers accordingly.
 */
const MappingModal: React.FunctionComponent<MappingModalProps> = ({
  isOpen,
  setIsOpen,
  setError,
  printerMappingItemName,
  selectedPrinterMappingItemOrCategoryId,
  type,
  selectedCatMenuTitle,
  categoryName,
  setIsOpenModal,
  isStack,
  setIsOpenStackProductModal,
}) => {
  const [value, setValue] = useState(0);
  const [printerList, setPrinterList] = useState<any>([]);
  const [strategyList, setStrategyList] = useState([]);
  const [displayNode, setDisplayNode] = useState<any>([]);
  const [availableStrategyIds, setAvailableStrategyIds] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [productConfigVersion, setProductConfigVersion] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState({});
  const [errorCard, setErrorCard] = useState<any>({});

  const match: any = useRouteMatch();

  /**
   * Function: handlePrintersAndHandleDisplaysNode
   *
   * Handles filtering printers and display nodes from the hardware node list.
   * Calls getPrinterMappingInfo function to fetch printer mapping information.
   *
   * @param {Array} hardwareNode - List of hardware nodes
   */
  const handlePrintersAndHandleDisplaysNode = (hardwareNode: any) => {
    // Check if hardwareNode is not empty
    if (!_.isEmpty(hardwareNode)) {
      // Filter display nodes (type === "kds")
      const filterDisplayList = hardwareNode.filter(
        (data: any) => data.type === "kds",
      );

      // Set displayNode state with filtered display nodes
      setDisplayNode(filterDisplayList);

      // Filter printer nodes (type === "printer" or "printer-sticker")
      const filterPrinterList = hardwareNode.filter(
        (data: any) =>
          data.type === "printer" || data.type === "printer-sticker",
      );

      // Fetch printer mapping information
      getPrinterMappingInfo(filterDisplayList, filterPrinterList);
    } else {
      // If hardwareNode is empty, set displayNode to empty array and setIsLoading to false
      setDisplayNode([]);
      setIsLoading(false);
    }
  };

  /**
   * Function: getHardwareInfo
   *
   * Retrieves hardware information from the backend API for the specified location ID.
   * Calls handlePrintersAndHandleDisplaysNode to process the retrieved hardware data.
   * Handles errors by setting the error state and isLoading state.
   */
  const getHardwareInfo = async () => {
    try {
      // Fetch hardware information from the backend API
      const res = await fetchHardwareInfo(match.params.locationId);

      // Process the retrieved hardware data
      handlePrintersAndHandleDisplaysNode(res.data.data);
    } catch (err) {
      // If an error occurs during fetching, handle it by setting the error state and isLoading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * Function: getFilteredIds
   *
   * Takes an array of data objects and extracts filtered IDs along with their corresponding names.
   * Returns an object containing filtered IDs as keys and arrays of corresponding names as values.
   *
   * @param {any} data - Array of data objects
   * @returns {Object} - Object containing filtered IDs and corresponding names
   */
  const getFilteredIds = (data: any) => {
    // Initialize output object
    const output: any = {};

    // Iterate through the data array
    data.forEach((item: any) => {
      const filterMapping = item?.filterMapping;

      // Check if filterMapping is not empty
      if (filterMapping && Object.keys(filterMapping).length > 0) {
        // Iterate through keys of filterMapping
        Object.keys(filterMapping).forEach((key) => {
          // Initialize array if not exist
          if (!output[key]) {
            output[key] = [];
          }

          // Add name to the array
          output[key].push(item?.name);
        });
      }
    });

    return output;
  };

  /**
   * useEffect hook to update available strategy IDs when strategyList, printerList, or displayNode change.
   * It merges printerList and displayNode, then extracts available strategy IDs and sets them in the state.
   */
  useEffect(() => {
    // Check if strategyList is not empty
    if (!_.isEmpty(strategyList)) {
      // Merge printerList and displayNode
      const mergeList = [...printerList, ...displayNode];
      // Check if mergeList is not empty
      if (!_.isEmpty(mergeList)) {
        // Extract available strategy IDs
        const availableStrategy = getFilteredIds(mergeList);
        // Set available strategy IDs in the state
        setAvailableStrategyIds(availableStrategy);
      } else {
        // If mergeList is empty, set available strategy IDs as an empty object
        setAvailableStrategyIds({});
      }
    }
  }, [strategyList, printerList, displayNode]);

  /**
   * Function to fetch strategy information from the backend API.
   * It sets the retrieved strategy list in the state variable.
   */
  const getStrategyInfo = async () => {
    try {
      // Fetch strategy information from the backend
      const res = await fetchStrategyInfo(match.params.locationId);
      // Set the strategy list in the state variable
      setStrategyList(res.data.data);
    } catch (err) {
      // Handle unexpected errors by setting the error state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };
  /**
   * Function to fetch printer mapping information from the backend API.
   * It retrieves printer mapping information based on the location ID, selected printer mapping item or category ID, and type.
   * After fetching the data, it calls the handleFilerMapping function to process and handle the mapping data.
   * If an error occurs during the process, it sets the error state variable and updates the loading state.
   * @param {Array} displayList - The list of display nodes.
   * @param {Array} printerList - The list of printer nodes.
   */
  const getPrinterMappingInfo = async (displayList: any, printerList: any) => {
    try {
      // Fetch printer mapping information from the backend
      const res = await fetchPrinterMappingInfo(
        match.params.locationId,
        selectedPrinterMappingItemOrCategoryId,
        type,
      );
      // Handle the fetched printer mapping data
      handleFilerMapping(res?.data?.data, displayList, printerList);
    } catch (err) {
      // Handle unexpected errors by setting the error state and updating the loading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * Function to fetch information for a single printer mapping from the backend API.
   * It retrieves information for a specific printer mapping based on the location ID and hardware ID.
   * After fetching the data, it updates the version of the hardware information and calls the handleUpdateStockMap function.
   * If an error occurs during the process, it sets the error state variable and updates the loading state.
   * @param {string} printerOrDisplay - The type of hardware (printer or display).
   * @param {object} hardwareInfo - The hardware information object.
   */
  const getSinglePrinterMappingInfo = async (
    printerOrDisplay: any,
    hardwareInfo: any,
  ) => {
    try {
      // Fetch information for a single printer mapping from the backend
      const res = await fetchSinglePrinterMappingInfo(
        match.params.locationId,
        hardwareInfo.id,
      );
      // Clone the hardware information object to avoid mutation
      const cloneHardwareInfo = _.cloneDeep(hardwareInfo);
      // Update the version of the hardware information with the fetched version
      cloneHardwareInfo.version = res.data.data.version;
      // Call the handleUpdateStockMap function with the updated hardware information
      handleUpdateStockMap(printerOrDisplay, cloneHardwareInfo);
    } catch (err) {
      // Handle unexpected errors by setting the error state and updating the loading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * Function to handle filtering and mapping of printer and display nodes based on the provided filter data.
   * It creates a mapping of print codes to filter items and merges the filter data with the provided printer and display lists.
   * After merging the data, it sets the state variables for printer list, display node, and loading states.
   * @param {Array} filter - The filter data containing information about printer mappings.
   * @param {Array} displayList - The list of display nodes.
   * @param {Array} printerList - The list of printer nodes.
   */
  const handleFilerMapping = (
    filter: any,
    displayList: any,
    printerList: any,
  ) => {
    // Create a mapping of print codes to filter items
    const printCodeMapping = filter?.reduce((acc: any, item: any) => {
      acc[item.printCode] = acc[item.printCode] || {};
      acc[item.printCode][item.strategy] = item;
      return acc;
    }, {});

    // Function to merge filter data with the provided list
    const mergeData = (list: any) => {
      return list.map((item: any) => {
        // Retrieve the filter mapping for the current item's code, or an empty object if not found
        const filterMapping = printCodeMapping?.[item?.code] ?? {};
        // Merge the item with the filter mapping and return the result
        return { ...item, filterMapping };
      });
    };

    // Merge filter data with the display list
    const outputDisplayList = mergeData(displayList);
    // Merge filter data with the printer list
    const outputHardwareList = mergeData(printerList);

    // Set the printer list state variable with the merged printer data
    setPrinterList(outputHardwareList);

    // Set the display node state variable with the merged display data
    setDisplayNode(outputDisplayList);

    // Set the loading states to indicate that the process is complete
    setIsLoading(false);
    setIsLoadingButton({});
  };

  /**
   * Asynchronously fetches the product configuration information for the specified location.
   * Upon successful retrieval, updates the product configuration version state variable.
   * If an error occurs during the process, sets the error state and updates the loading state accordingly.
   */
  const getProductConfig = async () => {
    try {
      // Fetch product configuration information from the API
      const res = await fetchProductConfigInfo(match.params.locationId);

      // Extract the printer mappings version from the response data and set it in the state
      setProductConfigVersion(res.data.data.printerMappingsVersion);
    } catch (err) {
      // If an error occurs during the fetch operation, handle it by setting the error state and updating the loading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * useEffect hook used to perform side effects after the component mounts.
   * It invokes the functions to fetch hardware information, strategy information, and product configuration information.
   * The empty dependency array ensures that these fetch operations are performed only once after the component mounts.
   */
  useEffect(() => {
    if (isOpen) {
      // Invoke the functions to fetch hardware information, strategy information, and product configuration information
      getHardwareInfo(); // Fetch hardware information
      getStrategyInfo(); // Fetch strategy information
      getProductConfig(); // Fetch product configuration information
    }
  }, [isOpen]);

  /**
   * Function to update the inheritance property in the filterMapping of items in a list based on certain conditions.
   * It maps through the list and updates the inheritance property of the filterMapping object for the item that matches the given criteria.
   * @param {Array} list - The list of items to be updated.
   * @param {string} strategy - The strategy associated with the item.
   * @param {Object} res - The response containing the updated information.
   * @returns {Array} - The updated list of items with inheritance property updated as per the provided criteria.
   */
  const updateInheritance = (list: any, strategy: any, res: any) => {
    return list.map((item: any) => {
      // Check if the item's code matches the printCode in the response and if the filterMapping for the given strategy exists
      if (item.code === res.printCode && item.filterMapping[strategy]) {
        // If the conditions are met, update the inheritance property of the filterMapping for the item
        return {
          ...item,
          filterMapping: {
            ...item.filterMapping,
            [strategy]: {
              ...item.filterMapping[strategy],
              inheritance: res.inheritance, // Update the inheritance property with the value from the response
            },
          },
        };
      }
      // If the conditions are not met, return the item as it is
      return item;
    });
  };

  /**
   * Function to handle the change event of a checkbox.
   * It constructs updated hardware information based on the checkbox change event and calls the function to update the stock map accordingly.
   * @param {Event} event - The checkbox change event.
   * @param {Object} hardwareInfo - Information about the hardware.
   * @param {string} printerOrDisplay - Indicates whether it's a printer or display.
   * @param {string} itemOrCategory - Indicates whether it's an item or category.
   * @param {string} strategyId - The ID of the strategy associated with the hardware.
   */
  const handleChangeCheckBox = (
    event: any,
    hardwareInfo: any,
    printerOrDisplay: any,
    itemOrCategory: any,
    strategyId: any,
  ) => {
    // Construct updated hardware information based on the checkbox change event
    const updatedHardwareInfo = {
      type: itemOrCategory,
      locationId: hardwareInfo.locationId,
      printCode: hardwareInfo.code,
      session: "",
      refId: selectedPrinterMappingItemOrCategoryId,
      strategy: strategyId,
      id: hardwareInfo.filterMapping[strategyId].id,
      inheritance: event.target.checked, // Set the inheritance property based on the checkbox checked status
      version: hardwareInfo.filterMapping[strategyId].version,
    };

    // Call the function to handle the update of stock map with the updated hardware information
    handleUpdateStockMap(printerOrDisplay, updatedHardwareInfo);
  };

  /**
   * Function to handle the change event when switching between tabs.
   * It updates the state value to the index of the selected tab.
   * @param {React.ChangeEvent<{}>} event - The change event.
   * @param {number} newValue - The index of the newly selected tab.
   */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // Update the state value to the index of the selected tab
    setValue(newValue);
  };

  /**
   * Function to update inheritance when creating a new mapping.
   * It updates the inheritance property in the filterMapping of the corresponding item in the list.
   * @param {any} list - The list of items.
   * @param {any} res - The response data containing information about the new mapping.
   * @returns {Array} - The updated list with inheritance information added.
   */
  const updateInheritanceWhenCreate = (list: any, res: any) => {
    // Map over each item in the list
    return list.map((item: any) => {
      // Check if the item's code matches the printCode of the response data
      if (item.code === res.printCode) {
        // Create a new data object with information from the response
        const data = {
          created: res.created,
          id: res.id,
          inheritance: res.inheritance,
          locationId: res.locationId,
          printCode: res.printCode,
          refId: res.refId,
          session: res.session,
          strategy: res.strategy.id,
          type: res.type,
          updated: res.updated,
        };
        // Update the filterMapping of the item with the new inheritance information
        return {
          ...item,
          filterMapping: {
            ...item.filterMapping,
            [res.strategy.id]: {
              ...data,
            },
          },
        };
      }
      // Return the item unchanged if its code doesn't match the printCode of the response data
      return item;
    });
  };

  /**
   * Function to handle the creation of a new stock mapping.
   * It sends a request to create a new mapping for the provided hardwareInfo.
   * If successful, it updates the respective list (printerList or displayNode) with the new mapping information.
   * If an error occurs, it sets an error message in the errorCard state.
   * @param {any} hardwareInfo - Information about the hardware for which the mapping is being created.
   * @param {string} printerOrDisplay - Indicates whether the mapping is for a printer or a display.
   */
  const handleCreateStockMap = async (
    hardwareInfo: any,
    printerOrDisplay: any,
  ) => {
    try {
      // Send a request to create a new mapping
      const res = await createMapProductInfo(
        match.params.locationId,
        hardwareInfo,
      );

      // Check if the mapping is for a printer or a display and update the respective list accordingly
      if (printerOrDisplay === "printer") {
        const output = updateInheritanceWhenCreate(printerList, res.data.data);
        setPrinterList(output);
      } else {
        const output = updateInheritanceWhenCreate(displayNode, res.data.data);
        setDisplayNode(output);
      }

      // Clear loading state and error card
      setIsLoadingButton({});
      setErrorCard({});

      const summaryString = `${hardwareInfo.printCode} Printer Mapping is added`;
      saveLogs(
        {
          message: "Printer Mapping Information Changed",
          summary: summaryString,
          type: "create",
          code: "info",
          metadata: { changedBy: "" },
        },
        "menu",
        match.params.locationId,
      );
    } catch (error) {
      // If an error occurs, set an error message in the errorCard state
      setErrorCard((prevState: any) => ({
        ...prevState,
        [hardwareInfo.printCode]: {
          ...prevState[hardwareInfo.printCode],
          message: "Can you try again.",
        },
      }));
      // Clear loading state
      setIsLoadingButton({});
    }
  };

  /**
   * Function to handle the update of an existing stock mapping.
   * It sends a request to update the mapping information for the provided hardwareInfo.
   * If successful, it updates the respective list (printerList or displayNode) with the updated mapping information.
   * If an error occurs, it sets an error message in the errorCard state.
   * @param {string} printerOrDisplay - Indicates whether the mapping is for a printer or a display.
   * @param {any} hardwareInfo - Information about the hardware for which the mapping is being updated.
   */
  const handleUpdateStockMap = async (
    printerOrDisplay: any,
    hardwareInfo: any,
  ) => {
    try {
      // Send a request to update the mapping information
      const res = await updateMapProductInfo(
        match.params.locationId,
        hardwareInfo,
      );

      // Check if the response status indicates a conflict (409)
      if (res.data.status === HttpStatus.CONFLICT_409) {
        // If conflict, retrieve the single printer mapping information
        getSinglePrinterMappingInfo(printerOrDisplay, hardwareInfo);
      } else {
        // If no conflict, update the respective list (printerList or displayNode) with the updated mapping information
        if (printerOrDisplay === "printer") {
          const output = updateInheritance(
            printerList,
            hardwareInfo.strategy,
            res.data.data,
          );
          setPrinterList(output);
        } else {
          const output = updateInheritance(
            displayNode,
            hardwareInfo.strategy,
            res.data.data,
          );

          setDisplayNode(output);
        }
      }
      const summaryString = `${
        hardwareInfo.printCode
      } inheritance changed from ${!hardwareInfo.inheritance} to ${
        hardwareInfo.inheritance
      }`;
      saveLogs(
        {
          message: "Printer Mapping Information Changed",
          summary: summaryString,
          type: "update",
          code: "info",
          metadata: { changedBy: "" },
        },
        "menu",
        match.params.locationId,
      );
      // Clear error card state
      setErrorCard({});
    } catch (error) {
      // If an error occurs, handle it
      const err: any = error as AxiosError;

      // Set an error message in the errorCard state
      if (err?.status !== HttpStatus.CONFLICT_409) {
        setErrorCard((prevState: any) => ({
          ...prevState,
          [hardwareInfo.printCode]: {
            ...prevState[hardwareInfo.printCode],
            message: "Can you try again.",
          },
        }));
      }
    }
  };

  /**
   * Function to handle the deletion of a map product.
   * It sends a request to delete the map product with the specified filterMapping ID.
   * If successful, it retrieves the updated printer mapping information.
   * If an error occurs, it sets an error message in the errorCard state.
   * @param {any} filterMapping - Information about the filter mapping to be deleted.
   * @param {any} hardwareInfo - Information about the hardware associated with the filter mapping.
   */
  const handleDeleteMapProduct = async (
    filterMapping: any,
    hardwareInfo: any,
  ) => {
    // Set isLoadingButton state to indicate loading
    setIsLoadingButton({ [hardwareInfo.id]: true });
    try {
      const deleteData = {
        type: filterMapping.type,
        printCode: filterMapping.printCode,
        refId: filterMapping.refId,
        strategy: filterMapping.strategy,
      };
      // Send a request to delete the map product
      await deleteMapProductInfo(match.params.locationId, deleteData);
      // Retrieve the updated printer mapping information
      getPrinterMappingInfo(displayNode, printerList);

      // Clear error card state
      setErrorCard({});

      const summaryString = `${filterMapping.id} Printer mapping information is deactivated`;
      saveLogs(
        {
          message: "Printer Mapping Information Changed",
          summary: summaryString,
          type: "warning",
          code: "deactivate",
          metadata: { changedBy: "" },
        },
        "menu",
        match.params.locationId,
      );
    } catch (error) {
      // If an error occurs, handle it
      setIsLoadingButton({});

      // Set an error message in the errorCard state
      setErrorCard((prevState: any) => ({
        ...prevState,
        [hardwareInfo.code]: {
          ...prevState[hardwareInfo.code],
          message: "Can you try again.",
        },
      }));
    }
  };

  /**
   * Function to handle the submission of map product information.
   * It sets isLoadingButton state to indicate loading.
   * Constructs updated hardware information object.
   * Calls handleCreateStockMap to create or update the stock map based on the provided information.
   * @param {any} itemOrCategory - Type of item or category being mapped.
   * @param {any} hardwareInfo - Information about the hardware being mapped.
   * @param {any} strategyId - ID of the strategy being used for mapping.
   * @param {any} printerOrDisplay - Type of hardware being mapped (printer or display).
   */
  const handleSubmit = (
    itemOrCategory: any,
    hardwareInfo: any,
    strategyId: any,
    printerOrDisplay: any,
  ) => {
    // Set isLoadingButton state to indicate loading
    setIsLoadingButton({ [hardwareInfo.id]: true });

    // Construct updated hardware information object
    const updatedHardwareInfo = {
      type: itemOrCategory,
      locationId: hardwareInfo.locationId,
      printCode: hardwareInfo.code,
      inheritance: true,
      session: "",
      refId: selectedPrinterMappingItemOrCategoryId,
      strategy: strategyId,
    };

    // Call handleCreateStockMap to create or update the stock map based on the provided information
    handleCreateStockMap(updatedHardwareInfo, printerOrDisplay);
  };

  const theme: CustomTheme = useTheme();
  const classes: any = useStyles();
  const tabletScreenSize = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileScreenSize = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      {
        <DialogCommonDefault
          open={isOpen}
          setOpen={setIsOpen}
          isNeedFixedHeight={true}
          maxWidth={"xl"}
        >
          <DialogTitle>
            <div className={classes.title}>
              <div style={{ display: "flex" }}>
                <Typography
                  variant={
                    mobileScreenSize ? "body1" : tabletScreenSize ? "h6" : "h5"
                  }
                >
                  {`Map printers - ${selectedCatMenuTitle} -> ${
                    type === "category" ? "" : categoryName + " ->"
                  }`}
                </Typography>
                <Typography
                  variant={
                    mobileScreenSize ? "body1" : tabletScreenSize ? "h6" : "h5"
                  }
                  style={{
                    paddingLeft: "4px",
                    color: theme.palette.custom.yellow.main,
                  }}
                >
                  {printerMappingItemName}
                </Typography>
              </div>
              <div className={classes.cancelButtonStyle}>
                <ButtonCommon
                  variant="contained"
                  style={{ fontSize: 11, width: "100px" }}
                  color={buttonColors.CANCEL_BUTTON_COLOR}
                  onClick={() => {
                    setIsOpen(false);
                    isStack
                      ? setIsOpenStackProductModal(true)
                      : setIsOpenModal(false);
                  }}
                >
                  Cancel
                </ButtonCommon>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <CardCommon>
              <Tabs
                centered
                value={value}
                variant="scrollable"
                selectionFollowsFocus
                scrollButtons="on"
                onChange={handleChange}
                aria-label="discount-types-tabs"
              >
                {strategyList.map((data: any, index: number) => (
                  <Tab
                    label={
                      availableStrategyIds?.[data.id] ? (
                        <Tooltip
                          title={availableStrategyIds?.[data.id]?.map(
                            (data: any) => (
                              <div>{data}</div>
                            ),
                          )}
                        >
                          <div
                            style={
                              data.valid
                                ? {
                                    display: "flex",
                                    justifyContent: "center",
                                  }
                                : {
                                    display: "flex",
                                    justifyContent: "center",
                                    color: theme.palette.custom.red.main,
                                  }
                            }
                          >
                            {data.name}
                            <div style={{ marginLeft: "4px" }}>
                              <FiberManualRecordIcon
                                className={classes.circleStyle}
                              />
                            </div>
                          </div>
                        </Tooltip>
                      ) : (
                        <div
                          style={
                            data.valid
                              ? {}
                              : {
                                  color: theme.palette.custom.red.main,
                                }
                          }
                        >
                          {data.name}
                        </div>
                      )
                    }
                    id={`strategy-type-tab-${index}`}
                    aria-controls={`strategy-type-tab-${index}`}
                  />
                ))}
              </Tabs>
            </CardCommon>

            {!isLoading ? (
              strategyList.map((data: any, index: number) => (
                <div className={classes.root}>
                  <TabPanel value={value} index={index}>
                    <div className={classes.discountTypeSection}>
                      <Typography
                        variant="h4"
                        component="div"
                        align="left"
                        style={{ margin: 4 }}
                      />
                    </div>
                    <PrinterNode
                      displayNode={displayNode}
                      printerList={printerList}
                      handleChangeCheckBox={handleChangeCheckBox}
                      strategyId={data.id}
                      version={productConfigVersion}
                      handleSubmit={handleSubmit}
                      error={errorCard}
                      isLoadingButton={isLoadingButton}
                      type={type}
                      handleDeleteMapProduct={handleDeleteMapProduct}
                    />
                  </TabPanel>
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "10%",
                }}
              >
                <CircularProgress size={50} color="secondary" />
              </div>
            )}
          </DialogContent>
        </DialogCommonDefault>
      }
    </>
  );
};

export default MappingModal;
