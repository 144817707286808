import React from "react";
import _ from "lodash";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";

export interface CustomerNodeProps {}

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

const BannerHeader: React.FunctionComponent<CustomerNodeProps> = ({}) => {
  return (
    <>
      <CardCommon backgroundColor={"table_header_background"}>
        <Grid container style={{ height: "32px", placeItems: "center" }}>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingLeft: "12px",
            }}
          >
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Access</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Created</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>API Key</Typography>
          </Grid>
          
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "76px",
            }}
          >
            <Typography>Status</Typography>
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default BannerHeader;
