import { Chip, makeStyles, TextField, createStyles } from "@material-ui/core";
import React from "react";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { CustomTheme } from "../../../types/customTheme";

export interface Props {
  selectorTextFieldName: any;
  selectorList: { label: string; id: string }[];
  handleAllSelectedList: any;
  selectedList: any;
  disabled?: any;
  isOrderReport?: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {},
    alignTextField: {
      paddingLeft: "12px",
      paddingRight: "12px",
      marginTop: "8px",
    },
    textField: {
      cursor: "pointer",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    textFieldDisabled: {
      cursor: "pointer",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.custom.grey.dark,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

/* Select several items and use this component. */
const MultipleSelector: React.FunctionComponent<Props> = ({
  selectorTextFieldName,
  selectorList,
  handleAllSelectedList,
  selectedList,
  disabled,
  isOrderReport,
}) => {
  const handleSelectedList = (selectedList: any) => {
    // If the selector name is 'Location', Return the 'location' selected data.
    if (selectorTextFieldName === "Location") {
      handleAllSelectedList("location", selectedList);
    } else if (selectorTextFieldName === "mode") {
      // If the selector name is 'mode', Return the 'mode' selected data.
      handleAllSelectedList("mode", selectedList);
    } else if (selectorTextFieldName === "channel") {
      // If the selector name is 'channel', Return the 'channel' selected data.
      handleAllSelectedList("channel", selectedList);
    } else if (selectorTextFieldName === "platform") {
      // If the selector name is 'platform', Return the 'platform' selected data.
      handleAllSelectedList("platform", selectedList);
    } else if (selectorTextFieldName === "partySize") {
      // If the selector name is 'partySize', Return the 'party size' selected data.
      handleAllSelectedList("partySize", selectedList);
    } else if (selectorTextFieldName === "vatPercent") {
      // If the selector name is 'vatPercent', Return the 'vat percent' selected data.
      handleAllSelectedList("vatPercent", selectedList);
    } else if (selectorTextFieldName === "terminal") {
      // If the selector name is 'terminal', Return the 'Terminal' selected data.
      handleAllSelectedList("terminal", selectedList);
    } else if (selectorTextFieldName === "status") {
      // If the selector name is 'status', Return the 'vat percent' selected data.
      handleAllSelectedList("status", selectedList);
    }  else if (selectorTextFieldName === "courier") {
      // If the selector name is 'status', Return the 'vat percent' selected data.
      handleAllSelectedList("courier", selectedList);
    } else if (selectorTextFieldName === "paymentType") {
      // If the selector name is 'paymentType', Return the 'payment type' selected data.
      if (!_.isEmpty(selectedList) && !isOrderReport) {
        handleAllSelectedList("paymentType", [
          selectedList[selectedList.length - 1],
        ]);
      } else {
        handleAllSelectedList("paymentType", selectedList);
      }
    } else if (selectorTextFieldName === "user") {
      // If the selector name is 'user', Return the 'user' selected data.
      handleAllSelectedList("user", selectedList);
    } else if (selectorTextFieldName === "payInOut") {
      // If the selector name is 'payInOut', Return the 'pay In Out' selected data.
      handleAllSelectedList("payInOut", selectedList);
    } else if (selectorTextFieldName === "orderStatus") {
      // If the selector name is 'orderStatus', Return the 'order Status' selected data.
      handleAllSelectedList("orderStatus", selectedList);
    } else if (selectorTextFieldName === "deliveryType") {
      // If the selector name is 'deliveryType', 'Return the 'delivery type' selected data.
      handleAllSelectedList("deliveryType", selectedList);
    } else if (selectorTextFieldName === "driver") {
      // If the selector name is 'deliveryType', 'Return the 'delivery type' selected data.
      handleAllSelectedList("driver", selectedList);
    }
  };

  const classes = useStyles();
  return (
    <>
      <Autocomplete
        className={classes.alignTextField}
        multiple
        disableClearable
        id="multiple_selector_id"
        options={selectorList}
        value={selectedList}
        disabled={disabled}
        disablePortal
        getOptionLabel={(option) => option.label}
        onChange={(e, selectedList: any, reason) => {
          handleSelectedList(selectedList);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.label}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            className={disabled ? classes.textFieldDisabled : classes.textField}
            {...params}
            variant="outlined"
          />
        )}
      />
    </>
  );
};

export default MultipleSelector;
