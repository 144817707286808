import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { useLocation, useRouteMatch } from "react-router";

import ShiftHeader from "./DriverSessionsHeader";
import withAuthority from "../../../../components/Auth/withAuthority";
import {
  fetchAllShiftReportInfo,
  fetchAllShiftInfo,
  getAllDeviceIdInfo,
} from "../../../../services/salesApp/shiftService";
import { shiftListFilterObject } from "../../../../utils/consts/list";
import { getCookie } from "../../../../utils/cookies";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import Pagination from "../../../../components/common/Pagination";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import Authorities from "../../../../auth/authorities";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { fetchAllUserInfo } from "../../../../services/salesApp/salesService";
import { convertDateTimeFormat } from "../../../../utils/ConvertDateTimeFormat";
import DriverSessionsHeader from "./DriverSessionsHeader";
import { fetchAllDriverSessionReportInfo } from "../../../../services/salesApp/driverSessionSummaryReport";
import SaleReportOneDate from "../../../../components/common/SaleReportOneDate";


/**
 * DriverSessions Component:
 *
 * This component manages the display of shift-related information and interactions,
 * including filtering, sorting, pagination, and error handling. It fetches data from
 * backend APIs and renders shift reports and lists accordingly. The component integrates
 * with user authentication to control access based on user permissions.
 */
const DriverSessions: React.FunctionComponent<{}> = () => {
  const [driverSummaryNodeList, setDriverSummaryNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [filterDetails, setFilterDetails] = useState("");
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState<any>([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [driverList, setDriverList] = useState<any>([]);
  const [driverSelectedList, setDriverSelectedList] = useState<any>([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  const getAllFilterLocation = async () => {
    // Call the API to fetch all filter locations
    fetchAllFilterLocations("")
      .then((res) => {
        // If the request is successful
        let locationList: any = [];
        // Check if the response data is not empty
        if (!_.isEmpty(res.data.data)) {
          // Iterate over each location in the response data
          res.data.data.map((location: any) => {
            // Push location details to the locationList array
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
        }

        // Update the location selector list with fetched locations
        setLocationSelectorList(locationList);
        // Set the flag to indicate that location info is fetched
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        // If an error occurs during fetching, handle it by setting an error message
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  /* Get all shift information using API call and response data set to driverSummaryNodeList state. */
  const getShiftFilterInfo = async (filter: any) => {
    try {
      const res = await fetchAllDriverSessionReportInfo(
        idToken,
        filter.split(";")[0],
        { start: filter.split(";")[1] },
      );

      setDriverSummaryNodeList(res.data.data);
      setIsLoadingPage(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    getAllFilterLocation();
    document.title = "Sale - Driver Sessions Summary";
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any) => {
    setIsLoadingPage(true);
    setFilterData(filterData);
    getShiftFilterInfo(filterData);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const getLocationSelectorTypingList = () => {};
  return (
    <>
      <Box>
        <SaleReportOneDate
          getFilterData={handleFilterData}
          isOpenSkeletonLoading={isLoadingPage}
          nodeList={driverSummaryNodeList}
          topic="Driver Sessions Report"
          handleOnClickText={handleOnClickText}
          setOpenFilterCard={setOpenFilterCard}
          openFilterCard={openFilterCard}
          filterDetails={filterDetails}
          setLocationSelectedList={setLocationSelectedList}
          locationSelectorList={locationSelectorList}
          getLocationSelectorTypingList={getLocationSelectorTypingList}
          locationSelectedList={locationSelectedList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          driverList={driverList}
          setDriverSelectedList={setDriverSelectedList}
          driverSelectedList={driverSelectedList}
        >
          <DriverSessionsHeader
            isLoading={isLoadingPage}
            driverSessions={driverSummaryNodeList}
            filterDetails={filterDetails}
            filterData={filterData}
            locationSelectorList={locationSelectorList}
            setOpenFilterCard={setOpenFilterCard}
            driverList={driverList}
            setDriverList={setDriverList}
            driverSelectedList={driverSelectedList}
          />
          {/* {!_.isEmpty(driverSummaryNodeList) && (
            <Pagination
              handleChangePaginationUsingSelector={
                handleChangePaginationUsingSelector
              }
              handleChangePaginationUsingPageNumber={
                handleChangePaginationUsingPageNumber
              }
              totalPages={totalPages}
              currentPage={currentPage}
              pageSize={pageSize}
              isShift={true}
              totalResult={totalResult}
            />
          )} */}
        </SaleReportOneDate>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(DriverSessions, Authorities.SALE_READ);
