import moment from "moment";
import saveAs from "file-saver";
import _ from "lodash";
import { ExportExcelColor } from "../../../../../root/theme/exportColor";

const excelRawChangeColor = (data: any, index: number, bodyLength: number) => {
  if (index % 2 === 0) {
    return {
      argb: [ExportExcelColor.OddRowColor],
    };
  } else {
    return {
      argb: [ExportExcelColor.EvenRowColor],
    };
  }
};

/* Create a excel file */
export const handleDownloadCsv = (
  excelHeaders: any,
  nodes: any,
  filterDetails: any,
  bodyLength: any,
) => {
  const ExcelJS = require("exceljs");
  const workbook = new ExcelJS.Workbook();
  let excelHeader: any = {};
  let excelData: any = {};

  const worksheet = workbook.addWorksheet("Sales By Cashier");

  /* Worksheet add headers */
  worksheet.columns = excelHeaders;

  /* Find the last character in the excel */
  const len = excelHeaders.length + 64;
  let char = String.fromCharCode(len);

  /* Add a title */
  worksheet.getCell("A1").value = "Sales By Cashier report";

  worksheet.mergeCells(`B1:${char}1`);

  const splitFilter = filterDetails.split("*");
  let filterOptions = "";

  /* Change of sub-title according to the number of locations.
  If the location is one, then that location is displayed, 
  if more than one location, the number of locations is displayed. */
  if (splitFilter[0].split(",").length === 1) {
    filterOptions =
      "Sales By Cashier Summary Report for " +
      splitFilter[0].split(",")[0] +
      " " +
      "Generated for the period of " +
      splitFilter[1];
  } else {
    filterOptions =
      "Sales By Cashier Summary Report for " +
      splitFilter[0].split(",").length +
      " Locations " +
      "Generated for the period of " +
      splitFilter[1];
  }

  /* Add sub-title using filter options. */
  worksheet.getCell("B1").value = filterOptions;

  /* Add a empty row. */
  worksheet.addRow({});

  /* Add a headers and change the header and empty row height. */
  excelHeaders.map((data: any) => {
    excelHeader[data.key] = data.header;
  });
  worksheet.addRow(excelHeader);
  worksheet.getRow(1).height = 28;
  worksheet.getRow(2).height = 28;

  /* Add a body in the excel. */
  nodes.map((data: any, index: number) => {
    Object.keys(excelHeader).map((header: any) => {
      excelData[header] = data[header];
    });
    worksheet.addRow(excelData);

    // Change the alignment in the cell.
    const row = worksheet.getRow(index + 3);
    row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
      let char = String.fromCharCode(64 + colNumber);
      for (var i = 1; i <= excelHeaders.length - 1; i++) {
        let chars = String.fromCharCode(65 + i);

        if (i === excelHeaders.length - 1) {
          worksheet.getCell(`${chars}${index + 3}`).alignment = {
            vertical: "bottom",
            horizontal: "right",
          };
        } else {
          worksheet.getCell(`${chars}${index + 3}`).alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
        }
      }

      // Change the font style in the specific cell.
      if (colNumber === 1) {
        worksheet.getCell(`${char}${index + 3})`).font = { bold: true };
      }
      if (index + 1 === nodes.length) {
        worksheet.getCell(`A${nodes.length + 3})`).font = { bold: true };
      }
    });

    worksheet.getRow(index + 3).height = 28;
  });

  /* Change the footer color and styles */
  const row = worksheet.getRow(nodes.length + 3);
  row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
    let char = String.fromCharCode(64 + colNumber);
    for (var i = 1; i <= excelHeaders.length - 1; i++) {
      let chars = String.fromCharCode(65 + i);

      if (i === excelHeaders.length - 1) {
        worksheet.getCell(`${chars}${nodes.length + 3})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };
      } else {
        worksheet.getCell(`${chars}${nodes.length + 3})`).alignment = {
          vertical: "bottom",
          horizontal: "center",
        };
      }
    }
  });

  /* Change the footer height */
  worksheet.getRow(nodes.length + 3).height = 28;

  /* Change the font color in the all cells */
  worksheet.addConditionalFormatting({
    ref: `A4:${char}${nodes.length + 3}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { color: { argb: "FFFFFF" } },
        },
      },
    ],
  });

  /* Change the title style */
  worksheet.addConditionalFormatting({
    ref: "A1",
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { bold: true, size: 10 },
        },
      },
    ],
  });

  /* Change the sub title stile */
  worksheet.addConditionalFormatting({
    ref: `B1:${char}1`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: {
            bold: true,
            size: 10,
          },
        },
      },
    ],
  });

  /* Change all row colors. */
  nodes.map((data: any, index: any) => {
    if (index + 1 !== nodes.length) {
      worksheet.addConditionalFormatting({
        ref: `A${index + 4}:${char}${index + 5}`,
        rules: [
          {
            type: "expression",
            formulae: ["MOD(2,2)=0"],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: excelRawChangeColor(data, index, bodyLength),
              },
              font: { color: { argb: "000000" } },
            },
          },
        ],
      });
    }
  });

  /* Bold the letters in the first column. */
  const column = worksheet.getColumn(1);
  column.style = { font: { bold: true } };

  /* Change the header row color */
  worksheet.addConditionalFormatting({
    ref: `A3:${char}3`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: ExportExcelColor.HeaderRowColor },
          },
          font: {
            bold: true,
            color: { argb: "ffffff" },
          },
        },
      },
    ],
  });

  const today = moment().format("MMM_Do_YY").toString();
  workbook.xlsx.writeBuffer().then(function (buffer: any) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `Sales_By_Cashier_Report${today}_${Math.floor(
        100000 + Math.random() * 900000,
      )}.xlsx`,
    );
  });
};
