import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import {
  cashierSummaryFilterObject,
} from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import CashierSummaryReportNode from "./CashierSummaryReportNode";
import { fetchAllCashierSummaryReportInfo } from "../../../../services/salesApp/cashierSummaryReportService";
import { fetchAllUserInfo } from "../../../../services/salesApp/salesService";
import { fetchAllHardwareReportInfo } from "../../../../services/salesApp/revenueCenterReport";

/**
 * CashierSummaryReport Component
 *
 * The CashierSummaryReport component is responsible for displaying the payment summary report
 * for a specific location within the PrestoExpress application. It utilizes Material-UI
 * components and various utility functions to fetch and display payment-related information.
 */
const CashierSummaryReport: React.FunctionComponent<{}> = () => {
  const [cashierReportNodeList, setCashierReportNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [userDetails, setUserDetails] = useState<any>({});
  const [terminalSelectorList, setTerminalSelectorList] = useState<any>([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  const getUserInfo = async () => {
    try {
      const res = await fetchAllUserInfo(idToken, match.params.locationId);
      setUserDetails(res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Asynchronous function to fetch payment report information based on filter criteria.
   * It updates the component state with the fetched data or handles errors appropriately.
   */
  const getCashierReportInfo = async (filter: any, basedOnShift: boolean) => {
    // Clear the existing payment report node list
    setCashierReportNodeList([]);
    try {
      // Fetch payment report information from the server
      const response = await fetchAllCashierSummaryReportInfo(
        idToken,
        match.params.locationId,
        filter,
      );

      setCashierReportNodeList(response.data);
      setIsLoading(false);

      // Update the state with the fetched data or an empty array if no data is available
    } catch (err) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * handleTerminal Function
   *
   * This function takes an object representing terminal information and returns a human-readable
   * terminal type based on its subtype. If the object is null or undefined, it returns the terminal id.
   *
   * @param {object} idObj - An object representing terminal information.
   * @returns {string} - A human-readable terminal type or the terminal id.
   */
  const handleTerminal = (idObj: any) => {
    // Check if idObj exists
    if (idObj) {
      // Switch statement to determine the terminal type based on its subtype
      switch (idObj.sub_type) {
        case "-99":
          return "POS"; // Return "POS" for subtype -99
        case "pay-kiosk":
          return "Pay Kiosk"; // Return "Pay Kiosk" for subtype "pay-kiosk"
        case "order-kiosk":
          return "Order Kiosk"; // Return "Order Kiosk" for subtype "order-kiosk"
        case "wix":
          return "Online POS"; // Return "Online POS" for subtype "wix"
        default:
          return idObj.sub_type; // Return the subtype if it doesn't match any case
      }
    } else {
      // Return the terminal id if idObj is null or undefined
      return idObj.id;
    }
  };

  /**
   * handleTid Function
   *
   * This function takes an object representing terminal information and returns a formatted terminal ID
   * by appending a prefix "T-" to the terminal's prefix value. If the object is null or undefined,
   * it returns the terminal id.
   */
  const handleTid = (idObj: any) => {
    // Check if idObj exists
    if (idObj) {
      // Return a formatted terminal ID with prefix "T-"
      return `T-${idObj.prefix}`;
    } else {
      // Return the terminal id if idObj is null or undefined
      return idObj.id;
    }
  };

  /**
   * This asynchronous function, handleGetHardwareInformation, is responsible for fetching hardware information
   * related to the specified location ID using the fetchAllHardwareReportInfo API function.
   */
  const handleGetHardwareInformation = async () => {
    try {
      // Fetch hardware information for the specified location ID
      const res = await fetchAllHardwareReportInfo(match.params.locationId);
      const hardwareList: any = [];
      Object.values(res.data.data).map((hardware: any) => {
        hardwareList.push({
          id: hardware.dev_id,
          label: `${handleTid(hardware)} (${handleTerminal(hardware)})`,
        });
      });

      // Update hardware information node list state with fetched data
      setTerminalSelectorList(hardwareList);
      setIsGetLocationInfo(true);
    } catch (err) {
      // Handle errors
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR); // Set error message
    }
  };

  /**
   * Fetches and updates the location selector list based on the provided search name.
   *
   * @param {string} searchName - The search name used for filtering locations.
   */
  const getAllFilterLocation = async (searchName: any) => {
    try {
      // Fetch all filter locations using the provided search name
      const response = await fetchAllFilterLocations(searchName);

      // Process the fetched data to create a list of
      if (!_.isEmpty(response.data.data)) {
        const locationList = response.data.data.map((location: any) => ({
          id: location.id,
          label: location.businessDisplayName,
        }));

        // Update the location selector list and set the flag indicating location information is retrieved
        setLocationSelectorList(locationList);
      } else {
        setLocationSelectorList([]);
      }
      handleGetHardwareInformation();
    } catch (error) {
      // Handle errors by setting an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * useEffect hook to set the document title and fetch initial location filter data.
   * It runs only once when the component mounts.
   */
  useEffect(() => {
    // Set the document title for the page
    document.title = "Sale - Cashier Summary Report";

    getUserInfo();
    // Fetch initial location filter data with an empty searchName
    getAllFilterLocation("");
  }, []);

  /**
   * Handles the filter data and triggers the update of the payment report.
   */
  const handleFilterData = (
    filterData: any,
    basedOnShift: boolean,
    count: any,
  ) => {
    setIsLoading(true);

    // Fetch and update the payment report information based on the provided filter data and shift flag
    getCashierReportInfo(filterData, basedOnShift);

    // Set the filter data and shift flag
    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  /**
   * Handles the click event on the text, opens the filter card, and scrolls to the top.
   */
  const handleOnClickText = () => {
    setOpenFilterCard(true);

    // Scroll to the top of the window with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={cashierSummaryFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={cashierReportNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Cashier summary report for"
          topic="Cashier Summary"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
          payInOut={false}
          isInitialLoad={isInitialLoad}
          setIsInitialLoad={setIsInitialLoad}
          initialParamSize={5}
          isRemoveBasedOnShift={true}
          isRemoveDueAndOrderTime={true}
          isNeedTerminal={true}
          terminalSelectorList={terminalSelectorList}
        >
          <CashierSummaryReportNode
            cashierReportNodeList={cashierReportNodeList}
            locationSelectorList={locationSelectorList}
            filterDetails={filterDetails}
            filterData={filterData}
            basedOnShift={basedOnShift}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            userDetails={userDetails}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(CashierSummaryReport, Authorities.SALE_READ);
