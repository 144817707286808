import React from "react";
import withAuthority from "../../Auth/withAuthority";
import Authorities from "../../../auth/authorities";
import { Skeleton } from "@material-ui/lab";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { CustomTheme } from "../../../types/customTheme";
import CardCommon from "../../card/CardCommon";
import { useLocation } from "react-router-dom";
import moment from "moment";

export interface SkeleTonLoadingProps {
  isReport: boolean;
  isLocation: any;
  isNeedTimeout: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    buttonStyle: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    rowOdd: {
      backgroundColor: theme.palette.background.paper,
    },
    rowEven: {
      backgroundColor: theme.palette.background.entity_background,
    },
  }),
);

const SkeleTonLoading: React.FunctionComponent<SkeleTonLoadingProps> = ({
  isReport,
  isLocation,
  isNeedTimeout,
}) => {
  const { search } = useLocation();
  const params: any = new URLSearchParams(search);

  const handleTime = () => {
    // Extract start and end dates from params
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");

    // Use moment to parse the dates
    const start = moment(startDate);
    const end = moment(endDate);

    // Calculate the difference in days, inclusive of the start date
    const dayDifference = end.diff(start, "days") + 1;

    // Determine the time string based on the day difference
    if (dayDifference > 2) {
      if (dayDifference < 8) return "5 seconds";
      if (dayDifference < 32) return "15 seconds";
      if (dayDifference < 61) return "30 seconds";
      if (dayDifference < 91) return "45 seconds";
      if (dayDifference < 121) return "1 minute";
      if (dayDifference < 241) return "2 minutes";
      if (dayDifference < 367) return "4 minutes";
      if (dayDifference < 788) return "5 minutes";
    }

    return ""; // Return an empty string if dayDifference is 2 or less
  };
  const classes = useStyles();
  return (
    <Grid container style={{ marginTop: "32px" }}>
      {isNeedTimeout && handleTime() && (
        <Grid item xs={12}>
          <Typography variant="h6">
            {`The report may take up to ${handleTime()} to generate. Please wait`}
          </Typography>
        </Grid>
      )}
      {!isLocation && (
        <Grid item xs={12}>
          <Skeleton
            variant="text"
            className={classes.buttonStyle}
            width={280}
            height={32}
          />
        </Grid>
      )}
      {!isLocation && (
        <Grid item xs={12}>
          <Skeleton
            variant="text"
            className={classes.buttonStyle}
            width={280}
            height={32}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Skeleton
          variant="rect"
          className={classes.buttonStyle}
          width={280}
          height={40}
        />
        {isReport && (
          <Skeleton
            variant="rect"
            className={classes.buttonStyle}
            width={130}
            height={40}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <CardCommon>
          <Skeleton
            variant="rect"
            width={"100%"}
            className={classes.rowOdd}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowOdd}
            width={"100%"}
            height={40}
          />
          <Skeleton
            variant="rect"
            className={classes.rowEven}
            width={"100%"}
            height={40}
          />
        </CardCommon>
      </Grid>
    </Grid>
  );
};

export default withAuthority(SkeleTonLoading, Authorities.DASHBOARD_READ);
