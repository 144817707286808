import moment from "moment";
import saveAs from "file-saver";
import _ from "lodash";
import { ExportExcelColor } from "../../../../../root/theme/exportColor";

/* Changing row colors */
const excelRawChangeColor = (index: number) => {
  if (index % 2 === 0) {
    return {
      argb: [ExportExcelColor.OddRowColor],
    };
  } else {
    return {
      argb: [ExportExcelColor.EvenRowColor],
    };
  }
};

const isObject = (value: any) => {
  return value !== null && typeof value === "object" && !Array.isArray(value);
};

/* Create new table */
const handleTable = (
  key: any,
  nodes: any,
  excelHeaders: any,
  worksheet: any,
  rowLength: any,
  nodeLength: any,
  footerLength: any,
) => {
  let excelHeader: any = {};
  let excelData: any = {};
  /* Worksheet add headers */

  /* Find the last character in the excel */
  const len = excelHeaders.length + 64;
  let char = String.fromCharCode(len);

  /* Add a headers and change the header and empty row height. */
  excelHeaders.map((data: any) => {
    excelHeader[data.key] = data.header;
  });
  if (key !== "Summary") {
    if (key === "Banking") {
      excelHeader["Currency"] = "";
    }
    worksheet.addRow(excelHeader);
  } else {
    /* Only if it is not a table, the first object is inserted as the header, 
    and then the inserted object is removed from the list. */
    let excelHeader: any = {};
    excelHeader["Title"] = nodes[0].Title;

    excelHeader["Amount"] = nodes[0].Amount;
    worksheet.addRow(excelHeader);
    nodes.shift();
  }

  /* Add a body in the excel. */
  nodes.map((data: any, index: number) => {
    Object.keys(excelHeader).map((header: any) => {
      excelData[header] = data[header];
    });
    worksheet.addRow(excelData);

    // Change the table header alignment, and font weight in the cell.
    if (index === 0) {
      const row = worksheet.getRow(index + rowLength);
      row.eachCell(
        { includeEmpty: true },
        function (cell: any, colNumber: any) {
          let char = String.fromCharCode(64 + colNumber);
          worksheet.getCell(`A${index + rowLength})`).alignment = {
            vertical: "bottom",
            horizontal: "left",
          };
          worksheet.getCell(`${char}${index + rowLength})`).alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          if (Object.keys(nodes[0]).length === colNumber) {
            worksheet.getCell(`${char}${index + rowLength})`).alignment = {
              vertical: "bottom",
              horizontal: "right",
            };
          }

          if (key === "paid-in-out") {
            worksheet.getCell(`E${index + rowLength})`).alignment = {
              vertical: "bottom",
              horizontal: "right",
            };
          }
          // Change the font style in the specific cell.
          if (colNumber === 1) {
            worksheet.getCell(`${char}${index + rowLength})`).font = {
              bold: true,
              color: { argb: "FFFFFF" },
            };
          }
        },
      );

      // Change the height of the raw
      worksheet.getRow(index + rowLength).height = 28;
    }

    // Change the table body alignment, and first column font weight in the cell.
    const row = worksheet.getRow(index + rowLength + 1);
    row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
      let char = String.fromCharCode(64 + colNumber);
      let charNew = String.fromCharCode(64 + colNumber + 1);
      worksheet.getCell(`A${index + rowLength + 1})`).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };
      worksheet.getCell(`${charNew}${index + rowLength + 1})`).alignment = {
        vertical: "bottom",
        horizontal: "center",
      };
      if (Object.keys(nodes[0]).length === colNumber) {
        worksheet.getCell(`${char}${index + rowLength + 1})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };
      }

      if (key === "paid-in-out") {
        worksheet.getCell(`E${index + rowLength})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };

        worksheet.getCell(`E${index + rowLength + 1})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };
      }

      // Change the first column font weight in the specific cell.
      if (colNumber === 1) {
        worksheet.getCell(`${char}${index + rowLength + 1})`).font = {
          bold: true,
        };
      }
    });

    worksheet.getRow(index + rowLength + 1).height = 28;
  });

  /* Change the footer height */
  worksheet.getRow(nodes.length + 3).height = 28;

  /* Change the title style */
  worksheet.addConditionalFormatting({
    ref: "A1",
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { bold: true, size: 10 },
        },
      },
    ],
  });

  /* Change the sub title stile */
  worksheet.addConditionalFormatting({
    ref: `B1:${char}1`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: {
            bold: true,
            size: 10,
          },
        },
      },
    ],
  });

  /* Change all row colors. */
  nodes.map((data: any, index: any) => {
    if (key === "Breakdown of Shifts" || key === "Summary") {
      worksheet.addConditionalFormatting({
        ref: `A${rowLength + index + 1}:${char}${rowLength + index + 1}`,
        rules: [
          {
            type: "expression",
            formulae: ["MOD(2,2)=0"],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: excelRawChangeColor(rowLength + index + 1),
              },
              font: { color: { argb: "FFFFFF" } },
            },
          },
        ],
      });
    } else {
      if (key === "cancelled-receipts" || key === "void-transaction") {
        if (index + 1 < nodes.length - footerLength + 1) {
          worksheet.addConditionalFormatting({
            ref: `A${rowLength + index + 1}:${char}${rowLength + index + 1}`,
            rules: [
              {
                type: "expression",
                formulae: ["MOD(2,2)=0"],
                style: {
                  fill: {
                    type: "pattern",
                    pattern: "solid",
                    bgColor: excelRawChangeColor(rowLength + index + 1),
                  },
                  font: { color: { argb: "FFFFFF" } },
                },
              },
            ],
          });
        }
      } else {
        if (index + 1 !== nodes.length) {
          worksheet.addConditionalFormatting({
            ref: `A${rowLength + index + 1}:${char}${rowLength + index + 1}`,
            rules: [
              {
                type: "expression",
                formulae: ["MOD(2,2)=0"],
                style: {
                  fill: {
                    type: "pattern",
                    pattern: "solid",
                    bgColor: excelRawChangeColor(rowLength + index + 1),
                  },
                  font: { color: { argb: "FFFFFF" } },
                },
              },
            ],
          });
        }
      }
    }
  });

  /* Bold the letters in the first column. */
  const column = worksheet.getColumn(1);
  column.style = { font: { bold: true } };

  /* Change the Banking and Summary first row row color */
  worksheet.addConditionalFormatting({
    ref: `A${rowLength}:${char}${rowLength}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: {
              argb:
                key === "Summary"
                  ? ExportExcelColor.OddRowColor
                  : ExportExcelColor.HeaderRowColor,
            },
            font: { color: { argb: "ffffff" } },
          },
          font: {
            bold: key === "Summary" ? false : true,
            color: {
              argb: key === "Summary" ? "ffffff" : "ffffff",
            },
          },
        },
      },
    ],
  });

  // Change the cancelled-receipts and void-transaction table footer raw color
  if (key === "cancelled-receipts" || key === "void-transaction") {
    // Total Amount color
    worksheet.addConditionalFormatting({
      ref: `A${nodeLength + footerLength + rowLength}:${char}${
        nodeLength + footerLength + rowLength
      }`,
      rules: [
        {
          type: "expression",
          formulae: ["MOD(2,2)=0"],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: ExportExcelColor.green },
            },
            font: {
              bold: true,
              color: { argb: "ffffff" },
            },
          },
        },
      ],
    });

    // Total user color
    worksheet.addConditionalFormatting({
      ref: `A${nodeLength + rowLength + 1}:${char}${
        nodeLength + rowLength + footerLength - 1
      }`,
      rules: [
        {
          type: "expression",
          formulae: ["MOD(2,2)=0"],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: ExportExcelColor.blueDark },
            },
            font: {
              bold: true,
              color: { argb: "ffffff" },
            },
          },
        },
      ],
    });
  } else {
    /* Change the footer row color */
    if (key !== "Breakdown of Shifts" && key !== "Summary") {
      worksheet.addConditionalFormatting({
        ref: `A${nodes.length + rowLength}:${char}${nodes.length + rowLength}`,
        rules: [
          {
            type: "expression",
            formulae: ["MOD(2,2)=0"],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: { argb: ExportExcelColor.green },
              },
              font: {
                bold: true,
                color: { argb: "ffffff" },
              },
            },
          },
        ],
      });
    }
  }
};

const handleTableCash = (
  key: any,
  nodes: any,
  excelHeaders: any,
  worksheet: any,
  rowLength: any,
  nodeLength: any,
  footerLength: any,
) => {
  let excelHeader: any = {};
  let excelData: any = {};
  /* Worksheet add headers */

  /* Find the last character in the excel */
  const len = excelHeaders.length + 64;
  let char = String.fromCharCode(len);

  /* Add a headers and change the header and empty row height. */
  excelHeaders.map((data: any) => {
    excelHeader[data.key] = data.header;
  });
  worksheet.addRow(excelHeader);

  /* Add a body in the excel. */
  nodes.map((data: any, index: number) => {
    Object.keys(excelHeader).map((header: any) => {
      excelData[header] = data[header];
    });
    worksheet.addRow(excelData);

    // Change the table header alignment, and font weight in the cell.
    if (index === 0) {
      const row = worksheet.getRow(index + rowLength);
      row.eachCell(
        { includeEmpty: true },
        function (cell: any, colNumber: any) {
          let char = String.fromCharCode(64 + colNumber);
          worksheet.getCell(`A${index + rowLength})`).alignment = {
            vertical: "bottom",
            horizontal: "left",
          };
          worksheet.getCell(`${char}${index + rowLength})`).alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          if (Object.keys(nodes[0]).length === colNumber) {
            worksheet.getCell(`${char}${index + rowLength})`).alignment = {
              vertical: "bottom",
              horizontal: "right",
            };
          }

          // Change the font style in the specific cell.
          if (colNumber === 1) {
            worksheet.getCell(`${char}${index + rowLength})`).font = {
              bold: true,
              color: { argb: "FFFFFF" },
            };
          }
        },
      );

      // Change the height of the raw
      worksheet.getRow(index + rowLength).height = 28;
    }

    // Change the table body alignment, and first column font weight in the cell.
    const row = worksheet.getRow(index + rowLength + 1);
    row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
      let char = String.fromCharCode(64 + colNumber);
      let charNew = String.fromCharCode(64 + colNumber + 1);
      worksheet.getCell(`A${index + rowLength + 1})`).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };
      worksheet.getCell(`${charNew}${index + rowLength + 1})`).alignment = {
        vertical: "bottom",
        horizontal: "center",
      };
      if (Object.keys(nodes[0]).length === colNumber) {
        worksheet.getCell(`${char}${index + rowLength + 1})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };
      }
      // Change the first column font weight in the specific cell.
      if (colNumber === 1) {
        worksheet.getCell(`${char}${index + rowLength + 1})`).font = {
          bold: true,
        };
      }
    });

    worksheet.getRow(index + rowLength + 1).height = 28;
  });

  /* Change the footer height */
  worksheet.getRow(nodes.length + 3).height = 28;

  /* Change the title style */
  worksheet.addConditionalFormatting({
    ref: "A1",
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { bold: true, size: 10 },
        },
      },
    ],
  });

  /* Change the sub title stile */
  worksheet.addConditionalFormatting({
    ref: `B1:${char}1`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: {
            bold: true,
            size: 10,
          },
        },
      },
    ],
  });

  /* Change all row colors. */
  nodes.map((data: any, index: any) => {
    if (key === "Breakdown of Shifts" || key === "Summary") {
      worksheet.addConditionalFormatting({
        ref: `A${rowLength + index + 1}:${char}${rowLength + index + 1}`,
        rules: [
          {
            type: "expression",
            formulae: ["MOD(2,2)=0"],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: excelRawChangeColor(rowLength + index + 1),
              },
              font: { color: { argb: "FFFFFF" } },
            },
          },
        ],
      });
    } else {
      if (key === "cancelled-receipts" || key === "void-transaction") {
        if (index + 1 < nodes.length - footerLength + 1) {
          worksheet.addConditionalFormatting({
            ref: `A${rowLength + index + 1}:${char}${rowLength + index + 1}`,
            rules: [
              {
                type: "expression",
                formulae: ["MOD(2,2)=0"],
                style: {
                  fill: {
                    type: "pattern",
                    pattern: "solid",
                    bgColor: excelRawChangeColor(rowLength + index + 1),
                  },
                  font: { color: { argb: "FFFFFF" } },
                },
              },
            ],
          });
        }
      } else {
        if (index + 1 !== nodes.length) {
          worksheet.addConditionalFormatting({
            ref: `A${rowLength + index + 1}:${char}${rowLength + index + 1}`,
            rules: [
              {
                type: "expression",
                formulae: ["MOD(2,2)=0"],
                style: {
                  fill: {
                    type: "pattern",
                    pattern: "solid",
                    bgColor: excelRawChangeColor(rowLength + index + 1),
                  },
                  font: { color: { argb: "FFFFFF" } },
                },
              },
            ],
          });
        }
      }
    }
  });

  /* Bold the letters in the first column. */
  const column = worksheet.getColumn(1);
  column.style = { font: { bold: true } };

  /* Change the Banking and Summary first row row color */
  worksheet.addConditionalFormatting({
    ref: `A${rowLength}:${char}${rowLength}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: {
              argb:
                key === "Summary"
                  ? ExportExcelColor.OddRowColor
                  : ExportExcelColor.HeaderRowColor,
            },
            font: { color: { argb: "ffffff" } },
          },
          font: {
            bold: key === "Summary" ? false : true,
            color: {
              argb: key === "Summary" ? "ffffff" : "ffffff",
            },
          },
        },
      },
    ],
  });

  // Change the cancelled-receipts and void-transaction table footer raw color
  if (key === "cancelled-receipts" || key === "void-transaction") {
    // Total Amount color
    worksheet.addConditionalFormatting({
      ref: `A${nodeLength + footerLength + rowLength}:${char}${
        nodeLength + footerLength + rowLength
      }`,
      rules: [
        {
          type: "expression",
          formulae: ["MOD(2,2)=0"],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: ExportExcelColor.green },
            },
            font: {
              bold: true,
              color: { argb: "ffffff" },
            },
          },
        },
      ],
    });

    // Total user color
    worksheet.addConditionalFormatting({
      ref: `A${nodeLength + rowLength + 1}:${char}${
        nodeLength + rowLength + footerLength - 1
      }`,
      rules: [
        {
          type: "expression",
          formulae: ["MOD(2,2)=0"],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: ExportExcelColor.blueDark },
            },
            font: {
              bold: true,
              color: { argb: "ffffff" },
            },
          },
        },
      ],
    });
  } else {
    /* Change the footer row color */
    if (key !== "Breakdown of Shifts" && key !== "Summary") {
      worksheet.addConditionalFormatting({
        ref: `A${nodes.length + rowLength}:${char}${nodes.length + rowLength}`,
        rules: [
          {
            type: "expression",
            formulae: ["MOD(2,2)=0"],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: { argb: ExportExcelColor.green },
              },
              font: {
                bold: true,
                color: { argb: "ffffff" },
              },
            },
          },
        ],
      });
    }
  }
};

/* Create a excel file */
export const handleExcel = (
  excelHeaders: any,
  nodes: any,
  filterDetails: any,
  headersLists: any,
) => {
  const ExcelJS = require("exceljs");
  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet("Shift");

  // Change the first and second raw height
  worksheet.getRow(1).height = 28;
  worksheet.getRow(2).height = 28;
  worksheet.mergeCells("B1:F1");

  const splitFilter = filterDetails.split("*");
  let filterOptions = "";

  /* Change of sub-title according to the number of locations.
  If the location is one, then that location is displayed, 
  if more than one location, the number of locations is displayed. */
  if (splitFilter[0].split(",").length === 1) {
    filterOptions = "Shift Summary Report for " + splitFilter[0].split(",")[0];
  } else {
    filterOptions =
      "Shift Summary Report for " +
      splitFilter[0].split(",").length +
      " Locations " +
      "Generated for the period of " +
      splitFilter[1];
  }
  /* Add sub-title using filter options. */

  worksheet.getCell("B1").value = filterOptions;

  /* Add a empty row. */
  let rowLength = 3;
  Object.entries(nodes).forEach(([key, value]: any) => {
    if (
      (!key.includes("_tableName") && !_.isEmpty(value) && !isObject(value)) ||
      key === ""
    ) {
      if (key === "Banking") {
        worksheet.columns = excelHeaders[key];

        worksheet.getCell("A1").value = "Shift report";
        worksheet.getCell("B1").value = filterOptions;
        worksheet.addRow({});
        worksheet.getCell(`A${rowLength}`).value = nodes[`${key}_tableName`];
        worksheet.getCell(`A${rowLength}`).font = { bold: true };
        worksheet.getRow(rowLength).height = 28;

        handleTable(
          key,
          value,
          excelHeaders[key],
          worksheet,
          rowLength + 1,
          0,
          0,
        );

        worksheet.getRow(rowLength).height = 28;

        if (nodes["Banking_payInOut"].payIn.length > 1) {
          rowLength = rowLength + 1;

          worksheet.getRow(rowLength).height = 28;
          worksheet.getCell(`E${rowLength}`).alignment = { horizontal: "left" };
          worksheet.getCell(`F${rowLength}`).alignment = {
            horizontal: "right",
          };
          worksheet.getCell(`E${rowLength}`).value = "Type - Cash Added";
          worksheet.getCell(`F${rowLength}`).value = "Amount";
          worksheet.getCell(`E${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`F${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`E${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: ExportExcelColor.HeaderRowColor }, // Red background color (ARGB format)
          };
          worksheet.getCell(`F${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: ExportExcelColor.HeaderRowColor }, // Red background color (ARGB format)
          };
        }

        nodes["Banking_payInOut"].payIn.map((data: any, index: any) => {
          rowLength = rowLength + 1;
          worksheet.getRow(rowLength).height = 28;
          worksheet.getCell(`E${rowLength}`).alignment = { horizontal: "left" };
          worksheet.getCell(`F${rowLength}`).alignment = {
            horizontal: "right",
          };
          worksheet.getCell(`E${rowLength}`).value = data.type;
          worksheet.getCell(`F${rowLength}`).value = data.amount;
          worksheet.getCell(`E${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`F${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`E${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                nodes["Banking_payInOut"].payIn.length === index + 1
                  ? ExportExcelColor.green
                  : index % 2 === 0
                  ? ExportExcelColor.OddRowColor
                  : ExportExcelColor.EvenRowColor,
            }, // Red background color (ARGB format)
          };
          worksheet.getCell(`F${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                nodes["Banking_payInOut"].payIn.length === index + 1
                  ? ExportExcelColor.green
                  : index % 2 === 0
                  ? ExportExcelColor.OddRowColor
                  : ExportExcelColor.EvenRowColor,
            }, // Red background color (ARGB format)
          };
        });

        rowLength = rowLength + 2;

        if (nodes["Banking_payInOut"].payOut.length > 1) {
          worksheet.getRow(rowLength).height = 28;
          worksheet.getCell(`E${rowLength}`).alignment = { horizontal: "left" };
          worksheet.getCell(`F${rowLength}`).alignment = {
            horizontal: "right",
          };
          worksheet.getCell(`E${rowLength}`).value = "Type - Cash Deducted";
          worksheet.getCell(`F${rowLength}`).value = "Amount";
          worksheet.getCell(`E${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`F${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`E${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: ExportExcelColor.HeaderRowColor }, // Red background color (ARGB format)
          };
          worksheet.getCell(`F${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: ExportExcelColor.HeaderRowColor }, // Red background color (ARGB format)
          };
        }
        nodes["Banking_payInOut"].payOut.map((data: any, index: any) => {
          rowLength = rowLength + 1;
          worksheet.getRow(rowLength).height = 28;
          worksheet.getCell(`E${rowLength}`).alignment = { horizontal: "left" };
          worksheet.getCell(`F${rowLength}`).alignment = {
            horizontal: "right",
          };
          worksheet.getCell(`E${rowLength}`).value = data.type;
          worksheet.getCell(`F${rowLength}`).value = data.amount;
          worksheet.getCell(`E${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`F${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`E${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                nodes["Banking_payInOut"].payOut.length === index + 1
                  ? ExportExcelColor.red
                  : index % 2 === 0
                  ? ExportExcelColor.OddRowColor
                  : ExportExcelColor.EvenRowColor,
            }, // Red background color (ARGB format)
          };
          worksheet.getCell(`F${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                nodes["Banking_payInOut"].payOut.length === index + 1
                  ? ExportExcelColor.red
                  : index % 2 === 0
                  ? ExportExcelColor.OddRowColor
                  : ExportExcelColor.EvenRowColor,
            }, // Red background color (ARGB format)
          };
        });
        rowLength = value.length + 5;

        nodes["Banking_info"].map((data: any, index: any) => {
          // But the column width is set like this:
          rowLength = rowLength + 1;
          worksheet.getRow(rowLength).height = 28;
          worksheet.getCell(`A${rowLength}`).alignment = { horizontal: "left" };
          worksheet.getCell(`B${rowLength}`).alignment = {
            horizontal: "right",
          };
          worksheet.getCell(`A${rowLength}`).value = data.type;
          worksheet.getCell(`B${rowLength}`).value = data.amount;
          worksheet.getCell(`A${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`B${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`A${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                data.type === "Deficit / Surplus" && data.amount > 0
                  ? ExportExcelColor.green
                  : data.type === "Deficit / Surplus" && data.amount < 0
                  ? ExportExcelColor.red
                  : ExportExcelColor.blueLight,
            }, // Red background color (ARGB format)
          };
          worksheet.getCell(`B${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                data.type === "Deficit / Surplus" && data.amount > 0
                  ? ExportExcelColor.green
                  : data.type === "Deficit / Surplus" && data.amount < 0
                  ? ExportExcelColor.red
                  : ExportExcelColor.blueLight,
            }, // Red background color (ARGB format)
          };

          worksheet.getColumn("A").width = 300; // More reasonable width (30 characters)
          worksheet.getColumn("B").width = 300;
        });

        rowLength = value.length + 5;
        nodes["Takings_info"].map((data: any, index: any) => {
          // But the column width is set like this:
          rowLength = rowLength + 1;
          worksheet.getRow(rowLength).height = 28;
          worksheet.getCell(`D${rowLength}`).alignment = { horizontal: "left" };
          worksheet.getCell(`E${rowLength}`).alignment = {
            horizontal: "right",
          };
          worksheet.getCell(`D${rowLength}`).value = data.type;
          worksheet.getCell(`E${rowLength}`).value = data.amount;
          worksheet.getCell(`D${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`E${rowLength}`).font = {
            bold: true,
            color: { argb: ExportExcelColor.white },
          };
          worksheet.getCell(`D${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
              nodes.length === index + 1
                ? ExportExcelColor.green
                : index % 2 === 0
                ? ExportExcelColor.OddRowColor
                : ExportExcelColor.EvenRowColor,
            }, // Red background color (ARGB format)
          };
          worksheet.getCell(`E${rowLength}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb:
                nodes.length === index + 1
                  ? ExportExcelColor.green
                  : index % 2 === 0
                  ? ExportExcelColor.OddRowColor
                  : ExportExcelColor.EvenRowColor,
            }, // Red background color (ARGB format)
          };

          worksheet.getColumn("D").width = 300; // More reasonable width (30 characters)
          worksheet.getColumn("E").width = 300;
        });
        rowLength += 2;

        // rowLength=rowLength+2
      } else if (key !== "Banking_info" && key !== "Takings_info") {
        worksheet.columns = excelHeaders[key];
        worksheet.getCell("A1").value = "Shift report";
        worksheet.getCell("B1").value = filterOptions;
        worksheet.addRow({});
        worksheet.getCell(`A${rowLength}`).value = nodes[`${key}_tableName`];
        worksheet.getCell(`A${rowLength}`).font = { bold: true };
        worksheet.getRow(rowLength).height = 28;
        let footerLength = 0;
        let nodeLength = 0;
        if (key === "cancelled-receipts") {
          nodeLength = nodes["cancelledSaleNodesLength"];
          footerLength = nodes["cancelledSaleFooterLength"];
        } else if (key === "void-transaction") {
          nodeLength = nodes["voidTransactionNodesLength"];
          footerLength = nodes["voidTransactionFooterLength"];
        } else {
          nodeLength = 0;
          footerLength = 0;
        }

        rowLength = rowLength + 1;

        handleTable(
          key,
          value,
          excelHeaders[key],
          worksheet,
          rowLength,
          nodeLength,
          footerLength,
        );
        rowLength = rowLength + value.length + 3;
      }
    }
  });

  const today = moment().format("MMM_Do_YY").toString();
  workbook.xlsx.writeBuffer().then(function (buffer: any) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `shift__Report${today}_${Math.floor(
        100000 + Math.random() * 900000,
      )}.xlsx`,
    );
  });
};
