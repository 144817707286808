import { jsPDF } from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import moment from "moment";

import { ExportPdfColor } from "../../../../../root/theme/exportColor";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";

// Function to handle PDF conversion
const handleConvertPdf = (
  doc: any,
  filterDetails: any,
  totalPagesExp: any,
  img: any,
  locationLength: any,
  startY: any,
  groupName: any,
  departmentName: any,
  index: any,
  groupId: any,
  nodes: any,
) => {
  const locationFilter = filterDetails.split("*")[0];
  const pageWidth = doc.internal.pageSize.getWidth();

  // Define settings and content for each page
  doc.autoTable({
    // Add topic and sub topics.
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();

      // Set font size for topic and subtopics
      doc.setFontSize(12);
      doc.text("Presto Express Solutions", data.settings.margin.left, 12);

      doc.text(
        "Item Sales By Category Report",
        data.settings.margin.left + 123,
        12,
      );

      // Set larger font size for main title
      doc.setFontSize(16);

      // Insert main title on the first page
      if (doc.internal.getNumberOfPages() === 1) {
        /* Change the title according to the number of locations.
          If the location size is increased, show presto express. if the location size is the one, show that location. */
        doc.setTextColor("#32363E");
        if (locationFilter.includes(",")) {
        } else {
          doc.text(
            filterDetails
              .split("*")[0]
              .replace("Item Sales By Category report for location/s:", ""),
            pageWidth / 2,
            36,
            {
              align: "center",
            },
          );
        }

        doc.setTextColor(0, 0, 0);
        doc.text(
          "Item Sales By Category Report",
          pageWidth / 2,
          36 + locationLength,
          "center",
        );
        doc.setFontSize(10);

        /* Change the sub title according to the number of locations.
          If the location size is increased, show number of locations. if the location size is the one, show that location. */
        let filterLocationsDetails = "";
        if (locationFilter.split(",").length === 1) {
          filterLocationsDetails =
            "Item Sales By Category Report for " + locationFilter.split(",")[0];
        } else {
          filterLocationsDetails =
            "Item Sales By Category Report for " +
            locationFilter.split(",").length +
            " Locations";
        }

        doc.text(
          doc.splitTextToSize(filterLocationsDetails, 210 - 15 - 15),
          pageWidth / 2,
          44 + locationLength,
          {
            align: "center",
          },
        );

        /* Set the start date and end date */
        doc.text(
          doc.splitTextToSize(
            "Generated for the period of " + filterDetails.split("*")[1],
            210 - 15 - 15,
          ),
          pageWidth / 2,
          50 + locationLength,
          {
            align: "center",
          },
        );
      }
      doc.setFont(undefined, "bold");
      if (nodes[index - 1]?.groupId !== groupId) {
        doc.setFontSize(14);
        doc.text(groupName, data.settings.margin.left, startY);

        doc.setFontSize(12);
        doc.text(departmentName, data.settings.margin.left, startY + 7);
      } else {
        doc.text(departmentName, data.settings.margin.left, startY + 3);
      }
      doc.setFont(undefined, "normal");

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);
      doc.addImage(
        img,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });
};

const handleCreateTable = (doc: any, columns: any, nodes: any, startY: any) => {
  /* Create a pdf file. */
  doc.autoTable({
    columns: columns.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes.items,
    startY: startY,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 8,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: { fontStyle: "bold", halign: "left" },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,
    // Change footer style.
    willDrawCell: function (data: any) {
      const rows = data.table.body;
      if (data.row.index === rows.length - 1) {
        doc.setFillColor(ExportPdfColor.green);
        doc.setTextColor(255, 255, 255);
        // doc.setFont(undefined, 'bold')
      }
    },
    /* Change the backgroundcolor in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.dataKey === "itemName") {
          data.cell.styles.cellPadding = [0, 0, 0, 2.7];
          data.cell.styles.halign = "left";
        } else if (data.column.dataKey === "vatAmount") {
          data.cell.styles.halign = "right";
        } else {
          data.cell.styles.halign = "center";
        }
      }
      if (data.row.raw.itemName === "Total" && data.column.index === 0) {
        data.cell.styles.cellPadding = [0, 0, 0, 2.7];
      }
      if (data.column.index === 6) {
        data.cell.styles.halign = "right";
      }
      if (data.row.index !== 0) {
        if (data.row.raw.nodes === "main") {
          data.cell.styles.fillColor = [ExportPdfColor.blueLight];
          data.cell.styles.textColor = ["#ffffff"];
        } else {
          if (data.row.raw.nodes === "sub") {
            data.cell.styles.fillColor = [ExportPdfColor.alternativeRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          } else {
            if (data.row.index % 2 === 0) {
              data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
              data.cell.styles.textColor = ["#ffffff"];
            } else {
              data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
              data.cell.styles.textColor = ["#ffffff"];
            }
          }
        }
        if (data.row.raw.nodes === "sub" && data.column.index === 0) {
          data.cell.styles.cellPadding = [0, 0, 0, 8];
        }
      } else {
        if (data.row.raw.nodes === "main") {
          data.cell.styles.fillColor = [ExportPdfColor.blueLight];
          data.cell.styles.textColor = ["#ffffff"];
        }
      }
      if (
        (data.row.raw.isDuplicateItemName && data.column.index === 0) ||
        (data.row.raw.isDuplicateUnitPrice && data.column.index === 1) ||
        (data.row.raw.isDuplicateVatPercent && data.column.index === 5)
      ) {
        data.cell.styles.fillColor = [ExportPdfColor.purple];
        data.cell.styles.textColor = ["#ffffff"];
      }
      if (data.row.index + 1 === rows.length) {
        data.cell.styles.fontStyle = "bold";
      }
    },
  });
};

/* Create a pdf file */
export const handleDownloadPdf = (
  filterDetails: any,
  columns: any,
  nodes: any,
) => {
  const doc: any = new jsPDF("p");
  const totalPagesExp = "{total_pages_count_string}";
  const locationFilter = filterDetails.split("*")[0];
  const today = moment().format("MMM_Do_YY").toString();
  let locationLength = 0;

  if (!locationFilter.includes(",")) {
    locationLength = 10;
  }

  /* If there is a image, insert it into the img variable. */
  const img = new Image();
  img.src = eatprestoLogo;
  let startY = 70;
  nodes.map((tableData: any, index: number) => {
    // If the height of the previous table is greater than 240, a new page is added.
    if (startY > 230) {
      doc.addPage();
      startY = 30;
    }

    handleConvertPdf(
      doc,
      filterDetails,
      totalPagesExp,
      img,
      locationLength,
      startY,
      tableData.groupName,
      tableData.departmentName,
      index,
      tableData.groupId,
      nodes,
    );
    startY = startY + 10;
    if (nodes[index - 1]?.groupId !== tableData.groupId) {
      startY = startY + 2;
    }
    handleCreateTable(doc, columns, tableData, startY);
    startY = doc.lastAutoTable.finalY + 7;
  });

  // Total page number plugin only available in jspdf v1.0+

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(
    `Item_Sales_By_Category_Report_${today}_${Math.floor(
      100000 + Math.random() * 900000,
    )}.pdf`,
  );
};


























// import { jsPDF } from "jspdf";
// import "jspdf-autotable";
// import _ from "lodash";
// import moment from "moment";

// import { ExportPdfColor } from "../../../../../root/theme/exportColor";
// import eatprestoLogo from "../../../../../assets/images/logos/logo.png";

// // Function to handle PDF conversion
// const handleConvertPdf = (
//   doc: any,
//   filterDetails: any,
//   totalPagesExp: any,
//   img: any,
//   locationLength: any,
//   startY: any,
//   groupName: any,
//   departmentName: any,
//   index: any,
//   groupId: any,
//   nodes: any,
// ) => {
//   const locationFilter = filterDetails.split("*")[0];
//   const pageWidth = doc.internal.pageSize.getWidth();

//   // Define settings and content for each page
//   doc.autoTable({
//     // Add topic and sub topics.
//     didDrawPage: function (data: any) {
//       let pageNumber = "Page " + doc.internal.getNumberOfPages();

//       // Set font size for topic and subtopics
//       doc.setFontSize(12);
//       doc.text("Presto Express Solutions", data.settings.margin.left, 12);

//       doc.text(
//         "Item Sales By Category Report",
//         data.settings.margin.left + 123,
//         12,
//       );

//       // Set larger font size for main title
//       doc.setFontSize(16);

//       // Insert main title on the first page
//       if (doc.internal.getNumberOfPages() === 1) {
//         /* Change the title according to the number of locations.
//           If the location size is increased, show presto express. if the location size is the one, show that location. */
//         doc.setTextColor("#32363E");
//         if (locationFilter.includes(",")) {
//         } else {
//           doc.text(
//             filterDetails
//               .split("*")[0]
//               .replace("Item Sales By Category report for location/s:", ""),
//             pageWidth / 2,
//             36,
//             {
//               align: "center",
//             },
//           );
//         }

//         doc.setTextColor(0, 0, 0);
//         doc.text(
//           "Item Sales By Category Report",
//           pageWidth / 2,
//           36 + locationLength,
//           "center",
//         );
//         doc.setFontSize(10);

//         /* Change the sub title according to the number of locations.
//           If the location size is increased, show number of locations. if the location size is the one, show that location. */
//         let filterLocationsDetails = "";
//         if (locationFilter.split(",").length === 1) {
//           filterLocationsDetails =
//             "Item Sales By Category Report for " + locationFilter.split(",")[0];
//         } else {
//           filterLocationsDetails =
//             "Item Sales By Category Report for " +
//             locationFilter.split(",").length +
//             " Locations";
//         }

//         doc.text(
//           doc.splitTextToSize(filterLocationsDetails, 210 - 15 - 15),
//           pageWidth / 2,
//           44 + locationLength,
//           {
//             align: "center",
//           },
//         );

//         /* Set the start date and end date */
//         doc.text(
//           doc.splitTextToSize(
//             "Generated for the period of " + filterDetails.split("*")[1],
//             210 - 15 - 15,
//           ),
//           pageWidth / 2,
//           50 + locationLength,
//           {
//             align: "center",
//           },
//         );
//       }
//       doc.setFont(undefined, "bold");
//       if (nodes[index - 1]?.groupId !== groupId) {
//         doc.setFontSize(14);
//         doc.text(groupName, data.settings.margin.left, startY);

//         doc.setFontSize(12);
//         doc.text(departmentName, data.settings.margin.left, startY + 7);
//       } else {
//         doc.text(departmentName, data.settings.margin.left, startY + 3);
//       }
//       doc.setFont(undefined, "normal");

//       // Total page number plugin only available in jspdf v1.0+
//       if (typeof doc.putTotalPages === "function") {
//         pageNumber = pageNumber + " of " + totalPagesExp;
//       }
//       doc.setFontSize(10);
//       /* Set page number */
//       let pageSize = doc.internal.pageSize;
//       const pageHeight = pageSize.height
//         ? pageSize.height
//         : pageSize.getHeight();
//       doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);
//       doc.addImage(
//         img,
//         "JPEG",
//         data.settings.margin.left + 144,
//         pageHeight - 24,
//         40,
//         16,
//       );
//     },
//     margin: {
//       top: 30,
//       bottom: 30,
//     },
//   });
// };

// const handleCreateTable = (doc: any, columns: any, nodes: any, startY: any) => {
//   /* Create a pdf file. */
//   doc.autoTable({
//     columns: columns.map((col: any) => ({ ...col, dataKey: col.field })),
//     body: nodes.items,
//     startY: startY,
//     styles: {
//       align: "center",
//       fontSize: 8,
//       valign: "middle",
//       halign: "center",
//       borderRadius: "10px",
//     },
//     headStyles: {
//       fillColor: [ExportPdfColor.HeaderRowColor],
//       textColor: ["#ffffff"],
//       fontSize: 8,
//       valign: "middle",
//       halign: "center",
//     },
//     columnStyles: {
//       0: { fontStyle: "bold", halign: "left" },
//     },
//     alternateRowStyles: {
//       fillColor: [ExportPdfColor.OddRowColor],
//       textColor: ["#ffffff"],
//     },
//     tableLineWidth: 0.1,
//     // Change footer style.
//     willDrawCell: function (data: any) {
//       const rows = data.table.body;
//       if (data.row.index === rows.length - 1) {
//         doc.setFillColor(ExportPdfColor.green);
//         doc.setTextColor(255, 255, 255);
//         // doc.setFont(undefined, 'bold')
//       }
//     },
//     /* Change the backgroundcolor in the row. */
//     didParseCell: function (data: any, index: any) {
//       const rows = data.table.body;

//       if (data.section === "head") {
//         if (data.column.dataKey === "itemName") {
//           data.cell.styles.cellPadding = [0, 0, 0, 2.7];
//           data.cell.styles.halign = "left";
//         } else if (data.column.dataKey === "vatAmount") {
//           data.cell.styles.halign = "right";
//         } else {
//           data.cell.styles.halign = "center";
//         }
//       }
//       if (data.row.raw.itemName === "Total" && data.column.index === 0) {
//         data.cell.styles.cellPadding = [0, 0, 0, 2.7];
//       }
//       if (data.column.index === 6) {
//         data.cell.styles.halign = "right";
//       }
//       if (data.row.index !== 0) {
//         if (data.row.raw.nodes === "main") {
//           data.cell.styles.fillColor = [ExportPdfColor.blueLight];
//           data.cell.styles.textColor = ["#ffffff"];
//         } else {
//           if (data.row.raw.nodes === "sub") {
//             data.cell.styles.fillColor = [ExportPdfColor.alternativeRowColor];
//             data.cell.styles.textColor = ["#ffffff"];
//           } else {
//             if (data.row.index % 2 === 0) {
//               data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
//               data.cell.styles.textColor = ["#ffffff"];
//             } else {
//               data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
//               data.cell.styles.textColor = ["#ffffff"];
//             }
//           }
//         }
//         if (data.row.raw.nodes === "sub" && data.column.index === 0) {
//           data.cell.styles.cellPadding = [0, 0, 0, 8];
//         }
//       } else {
//         if (data.row.raw.nodes === "main") {
//           data.cell.styles.fillColor = [ExportPdfColor.blueLight];
//           data.cell.styles.textColor = ["#ffffff"];
//         }
//       }
//       if (
//         (data.row.raw.isDuplicateItemName && data.column.index === 0) ||
//         (data.row.raw.isDuplicateUnitPrice && data.column.index === 1) ||
//         (data.row.raw.isDuplicateVatPercent && data.column.index === 5)
//       ) {
//         data.cell.styles.fillColor = [ExportPdfColor.purple];
//         data.cell.styles.textColor = ["#ffffff"];
//       }
//       if (data.row.index + 1 === rows.length) {
//         data.cell.styles.fontStyle = "bold";
//       }
//     },
//   });
// };

// /* Create a pdf file */
// export const handleDownloadPdf = (
//   filterDetails: any,
//   columns: any,
//   nodes: any,
// ) => {
//   const doc: any = new jsPDF("p");
//   const totalPagesExp = "{total_pages_count_string}";
//   const locationFilter = filterDetails.split("*")[0];
//   const today = moment().format("MMM_Do_YY").toString();
//   let locationLength = 0;

//   if (!locationFilter.includes(",")) {
//     locationLength = 10;
//   }

//   /* If there is a image, insert it into the img variable. */
//   const img = new Image();
//   img.src = eatprestoLogo;
//   let startY = 70;
//   nodes.map((tableData: any, index: number) => {
//     // If the height of the previous table is greater than 240, a new page is added.
//     if (startY > 240) {
//       doc.addPage();
//       startY = 30;
//     }

//     handleConvertPdf(
//       doc,
//       filterDetails,
//       totalPagesExp,
//       img,
//       locationLength,
//       startY,
//       tableData.groupName,
//       tableData.departmentName,
//       index,
//       tableData.groupId,
//       nodes,
//     );
//     startY = startY + 10;
//     if (nodes[index - 1]?.groupId !== tableData.groupId) {
//       startY = startY + 2;
//     }
//     handleCreateTable(doc, columns, tableData, startY);
//     startY = doc.lastAutoTable.finalY + 7;
//   });

//   // Total page number plugin only available in jspdf v1.0+

//   if (typeof doc.putTotalPages === "function") {
//     doc.putTotalPages(totalPagesExp);
//   }

//   doc.save(
//     `Item_Sales_By_Category_Report_${today}_${Math.floor(
//       100000 + Math.random() * 900000,
//     )}.pdf`,
//   );
// };
