import Axios from "axios";
import { axiosClient } from "../client";

const queryString = require("query-string");

const DRIVER_SESSION_SUMMARY_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/driverSession/query`;


export const fetchAllDriverSessionReportInfo = (token, locationId, filterData) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: DRIVER_SESSION_SUMMARY_GET_ALL(locationId),
      query: filterData,
    }),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
