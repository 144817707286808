export const capitalizeWords = (str: any) => {
    return str
      .split(' ') // Split the string into an array of words
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)) // Uppercase first letter of each word
      .join(' '); // Join the words back into a string
  }

  export const capitalizeAndReplaceWords = (str: any) => {
    return str
      .split('_') // Split the string into an array of words
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)) // Uppercase first letter of each word
      .join(' '); // Join the words back into a string
  }