import moment from "moment";
import saveAs from "file-saver";
import _ from "lodash";
import { ExportExcelColor } from "../../../../../root/theme/exportColor";

const excelRawChangeColor = (data: any, index: number, bodyLength: number) => {
  if (index % 2 === 0) {
    return {
      argb: [ExportExcelColor.OddRowColor],
    };
  } else {
    return {
      argb: [ExportExcelColor.EvenRowColor],
    };
  }
};

/* Create a excel file */
export const handleDownloadCsv = (
  excelHeaders: any,
  nodes: any,
  worksheet: any,
  char: any,
  rowLength: any,
) => {
  let excelHeader: any = {};
  let excelData: any = {};

  /* Add a headers and change the header and empty row height. */
  excelHeaders.map((data: any) => (excelHeader[data.key] = data.header));

  worksheet.addRow(excelHeader);
  worksheet.getRow(1).height = 28;
  worksheet.getRow(2).height = 28;

  /* Add a body in the excel. */
  nodes.map((data: any, index: number) => {
    Object.keys(excelHeader).map((header: any) => {

      excelData[header] = data[header];
    });
    worksheet.addRow(excelData);

    // if (data.nodes === "sub") {
    //   worksheet.getCell(`A${index + 4})`).alignment = {
    //     vertical: "bottom",
    //     horizontal: "center",
    //   };
    // }
    // Change the alignment in the cell.
    const row = worksheet.getRow(index + rowLength);
    row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
      let char = String.fromCharCode(64 + colNumber);
      worksheet.getCell(`A${index + rowLength})`).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };
      worksheet.getCell(`B${index + rowLength})`).alignment = {
        vertical: "bottom",
        horizontal: "center",
      };
      worksheet.getCell(`C${index + rowLength})`).alignment = {
        vertical: "bottom",
        horizontal: "center",
      };
      worksheet.getCell(`D${index + rowLength})`).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };

      // Change the font style in the specific cell.
      // if (colNumber === 1) {
      //   worksheet.getCell(`${char}${index + rowLength})`).font = { bold: true };
      // }
    });

    worksheet.getRow(index + rowLength).height = 28;
  });

  /* Change the footer color and styles */
  const row = worksheet.getRow(nodes.length + rowLength);
  row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
    worksheet.getCell(`A${nodes.length + rowLength})`).alignment = {
      vertical: "bottom",
      horizontal: "left",
    };
    worksheet.getCell(`B${nodes.length + rowLength})`).alignment = {
      vertical: "bottom",
      horizontal: "center",
    };
    worksheet.getCell(`C${nodes.length + rowLength})`).alignment = {
      vertical: "bottom",
      horizontal: "center",
    };
    worksheet.getCell(`D${nodes.length + rowLength})`).alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
  });

  /* Change the footer height */
  worksheet.getRow(nodes.length + rowLength).height = 28;

  /* Change the font color in the all cells */
  worksheet.addConditionalFormatting({
    ref: `A${rowLength}:D${nodes.length + rowLength}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { color: { argb: "FFFFFF" } },
        },
      },
    ],
  });

  /* Change the title style */
  worksheet.addConditionalFormatting({
    ref: "A1",
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { bold: true, size: 10 },
        },
      },
    ],
  });

  /* Change the sub title stile */
  worksheet.addConditionalFormatting({
    ref: `B1:D1`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: {
            bold: true,
            size: 10,
          },
        },
      },
    ],
  });

  // worksheet.getCell(`A${nodes.length + rowLength})`).font = { bold: true };

  /* Change all row colors. */
  nodes.map((data: any, index: any) => {
    for (let i = 0; i < 4; i++) {
      const c = String.fromCharCode(65 + i);

      worksheet.getCell(`${c}${index + rowLength + 1}`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: excelRawChangeColor(data, index, rowLength),
      };
    }
  });

  /* Change the footer row color */
  // worksheet.addConditionalFormatting({
  //   ref: `A${nodes.length + rowLength}:D${nodes.length + rowLength}`,
  //   rules: [
  //     {
  //       type: "expression",
  //       formulae: ["MOD(2,2)=0"],
  //       style: {
  //         font: {
  //           bold: true,
  //         },
  //       },
  //     },
  //   ],
  // });

  /* Bold the letters in the first column. */
  const column = worksheet.getColumn(1);
  column.style = { font: { bold: true } };
  /* Change the header row color */
  worksheet.addConditionalFormatting({
    ref: `A${rowLength}:D${rowLength}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: ExportExcelColor.HeaderRowColor },
          },
          font: {
            bold: true,
            color: { argb: "ffffff" },
          },
        },
      },
    ],
  });

  // worksheet.getCell("A5").fill = {
  //   type: "pattern",
  //   pattern: "solid",
  //   fgColor: { argb: ExportExcelColor.purple },
  // };
};

export const handleExcel = (
  excelHeaders: any,
  preDefinedPdfHeaders: any,
  nodes: any,
  preDefinedNodesPdfExcel: any,
  filterDetails: any,
  bodyLength: any,
) => {
  const ExcelJS = require("exceljs");
  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet("Items");

  /* Worksheet add headers */
  worksheet.columns = excelHeaders;

  /* Find the last character in the excel */
  const len = excelHeaders.length + 64;
  let char = String.fromCharCode(len);

  /* Add a title */
  worksheet.getCell("A1").value = "Discount Summary Report report";

  worksheet.mergeCells(`B1:${char}1`);

  const splitFilter = filterDetails.split("*");
  let filterOptions = "";

  /* Change of sub-title according to the number of locations.
  If the location is one, then that location is displayed, 
  if more than one location, the number of locations is displayed. */
  if (splitFilter[0].split(",").length === 1) {
    filterOptions =
      "Discount Summary Report for " +
      splitFilter[0].split(",")[0] +
      " " +
      "Generated for the period of " +
      splitFilter[1];
  } else {
    filterOptions =
      "Discount Summary Report for " +
      splitFilter[0].split(",").length +
      " Locations " +
      "Generated for the period of " +
      splitFilter[1];
  }

  /* Add sub-title using filter options. */
  worksheet.getCell("B1").value = filterOptions;

  let rowLength = 3;
  /* Add a empty row. */
  if (!_.isEmpty(nodes)) {
    worksheet.getCell(`A${rowLength}`).font = { bold: true };
    worksheet.getRow(rowLength).height = 28;

   
    worksheet.getCell(`A${rowLength}`).value = {
      richText: [
        {
          text: "Percentage Discounts Summary",
          font: {
            color: { argb: "000000" },
            size: 14, // Font size (replace 12 with your desired font size)
            bold: true,
          },
        },
      ],
    };
    rowLength = rowLength + 2;
    worksheet.addRow({});
    handleDownloadCsv(excelHeaders, nodes, worksheet, char, rowLength);
    rowLength = rowLength + nodes.length + 1;
  }

  if (!_.isEmpty(preDefinedNodesPdfExcel)) {
    worksheet.getCell(`A${rowLength}`).font = { bold: true };
    worksheet.getRow(rowLength).height = 28;

    worksheet.addRow({});
    rowLength = rowLength + 1;
    worksheet.getCell(`A${rowLength}`).value = {
      richText: [
        {
          text: "Predefined Discounts Summary",
          font: {
            color: { argb: "000000" },
            size: 14, // Font size (replace 12 with your desired font size)
            bold: true,
          },
        },
      ],
    };
    rowLength = rowLength + 2;
    worksheet.addRow({});
    handleDownloadCsv(preDefinedPdfHeaders, preDefinedNodesPdfExcel, worksheet, char, rowLength);
    rowLength = rowLength + nodes.length + 1;
  }

  const today = moment().format("MMM_Do_YY").toString();
  workbook.xlsx.writeBuffer().then(function (buffer: any) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `Discount_Summary_Report${today}_${Math.floor(
        100000 + Math.random() * 900000,
      )}.xlsx`,
    );
  });
};
