import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import {
  salesByCashierFilterObject,
} from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import { fetchAllUserInfo } from "../../../../services/salesApp/salesService";
import { fetchCategoriesInfo } from "../../../../services/menuApp/menuService";
import { fetchAllSaleByCashierSummaryReportInfo } from "../../../../services/salesApp/salesByCategoryReportService";
import SalesByCashierReportNode from "./SalesByCashierReportNode";
import { getLocalStore } from "../../../../utils/store/localStore";

/**
 * SalesByCashierReport Component
 *
 * The SalesByCashierReport component is responsible for displaying the payment summary report
 * for a specific location within the PrestoExpress application. It utilizes Material-UI
 * components and various utility functions to fetch and display payment-related information.
 */
const SalesByCashierReport: React.FunctionComponent<{}> = () => {
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [isCategoryError, setIsCategoryError] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const [userIdList, setUserIdList] = useState<any>([]);
  const [allUsers, setAllUsers] = useState<any>([]);
  const [userDetails, setUserDetails] = useState<any>({});
  const [userDetailsObj, setUserDetailsObj] = useState<any>({});
  const [categoryList, setCategoryList] = useState<any>([]);
  const [salesByCashierReportNodeList, setSalesByCashierReportNodeList] =
    useState<any>([]);
  const [isActiveUserSwitch, setIsActiveUserSwitch] = useState(true);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  const getUserInfo = async () => {
    try {
      const res = await fetchAllUserInfo(idToken, match.params.locationId);
      // Step 1: Convert the object to an array of its values
      let dataArray = Object.values(res.data.data);

      // Step 2: Sort the array by the 'name' property
      dataArray.sort((a: any, b: any) => a.name.localeCompare(b.name));

      setUserDetails(dataArray);
      setUserDetailsObj(res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Asynchronous function to fetch payment report information based on filter criteria.
   * It updates the component state with the fetched data or handles errors appropriately.
   */
  const getCashierReportInfo = async (filter: any, basedOnShift: boolean) => {
    // Clear the existing payment report node list
    setSalesByCashierReportNodeList([]);
    try {
      // Fetch payment report information from the server
      const response = await fetchAllSaleByCashierSummaryReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );

      setSalesByCashierReportNodeList(response.data.data);
      setIsLoading(false);

      if (response?.data?.data) {
        const keys: any = [];
        const disableCategory = getLocalStore("hiddenCategory");
        const disableCategoryData = JSON.parse(disableCategory) || [];
        Object.entries(response?.data?.data).map(([key, data]: any) => {
          data.map((data1: any) => {
            if (
              !keys.includes(data1.userId) &&
              !disableCategoryData.includes(parseFloat(key))
            ) {
              keys.push({
                id: data1.userId,
                name: userDetailsObj[data1.userId].name,
              });
            }
          });
        });
        keys.sort((a: any, b: any) => a.name.localeCompare(b.name));

        const output: any = [];
        keys.map((data: any) => output.push(data.id));
        setAllUsers(response?.data?.data);
        setUserIdList(output);
      }
      // Update the state with the fetched data or an empty array if no data is available
    } catch (err) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleGetCategoryInformation = async () => {
    try {
      // Fetch hardware information for the specified location ID
      const res = await fetchCategoriesInfo(match.params.locationId);
      // Update hardware information node list state with fetched data
      setCategoryList(res.data.data);
      setIsGetLocationInfo(true);
    } catch (err) {
      setCategoryList([]);
      setIsCategoryError(true);
      setIsGetLocationInfo(true);
      // Handle errors
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR); // Set error message
    }
  };

  /**
   * This asynchronous function, handleGetHardwareInformation, is responsible for fetching hardware information
   */
  const handleGetUserInformation = async () => {
    try {
      // Fetch hardware information for the specified location ID
      const res = await fetchAllUserInfo(idToken, match.params.locationId);
      handleGetCategoryInformation();
    } catch (err) {
      // Handle errors
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR); // Set error message
    }
  };

  /**
   * Fetches and updates the location selector list based on the provided search name.
   *
   * @param {string} searchName - The search name used for filtering locations.
   */
  const getAllFilterLocation = async (searchName: any) => {
    try {
      // Fetch all filter locations using the provided search name
      const response = await fetchAllFilterLocations(searchName);

      // Process the fetched data to create a list of
      if (!_.isEmpty(response.data.data)) {
        const locationList = response.data.data.map((location: any) => ({
          id: location.id,
          label: location.businessDisplayName,
        }));

        // Update the location selector list and set the flag indicating location information is retrieved
        setLocationSelectorList(locationList);
      } else {
        setLocationSelectorList([]);
      }
      handleGetUserInformation();
    } catch (error) {
      // Handle errors by setting an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * useEffect hook to set the document title and fetch initial location filter data.
   * It runs only once when the component mounts.
   */
  useEffect(() => {
    // Set the document title for the page
    document.title = "Sale - Sales By Cashier Report";

    getUserInfo();
    // Fetch initial location filter data with an empty searchName
    getAllFilterLocation("");
  }, []);

  /**
   * Handles the filter data and triggers the update of the payment report.
   */
  const handleFilterData = (
    filterData: any,
    basedOnShift: boolean,
    count: any,
  ) => {
    setIsLoading(true);

    // Fetch and update the payment report information based on the provided filter data and shift flag
    getCashierReportInfo(filterData, basedOnShift);

    // Set the filter data and shift flag
    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  /**
   * Handles the click event on the text, opens the filter card, and scrolls to the top.
   */
  const handleOnClickText = () => {
    setOpenFilterCard(true);

    // Scroll to the top of the window with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={salesByCashierFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={salesByCashierReportNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Sales By Cashier report for"
          topic="Sales By Cashier"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
          payInOut={false}
          isInitialLoad={isInitialLoad}
          setIsInitialLoad={setIsInitialLoad}
          initialParamSize={5}
        >
          <SalesByCashierReportNode
            salesByCashierReportNodeList={salesByCashierReportNodeList}
            locationSelectorList={locationSelectorList}
            filterDetails={filterDetails}
            filterData={filterData}
            basedOnShift={basedOnShift}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            userDetails={userDetails}
            categoryList={categoryList}
            userIdList={userIdList}
            setIsLoading={setIsLoading}
            allUsers={allUsers}
            setUserIdList={setUserIdList}
            isActiveUserSwitch={isActiveUserSwitch}
            setIsActiveUserSwitch={setIsActiveUserSwitch}
            isCategoryError={isCategoryError}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(SalesByCashierReport, Authorities.SALE_READ);
