import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import _ from "lodash";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { paymentFilterObject, revenueCenterFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import RevenueCenterReportInfoNode from "./RevenueCenterReportInfoNode";
import {
  fetchAllHardwareReportInfo,
  fetchAllRevenueCenterInfo,
} from "../../../../services/salesApp/revenueCenterReport";

type RevenueCenterReport = {
  orders: number;
  subTotal: number;
  discount: number;
  total: number;
  cash: number;
  card: number;
  otherPayment: number;
  average: number;
  min: number;
  max: number;
};

/**
 * This component, RevenueCenterReport, is responsible for rendering a revenue center report for a specific location.
 * It fetches hardware information and revenue details from the backend APIs, allows filtering of data based on various criteria,
 * and displays the report along with any associated error messages or alerts.
 */
const RevenueCenterReport: React.FunctionComponent<{}> = () => {
  const [hardwareInformationNodeList, setHardwareInformationNodeList] =
    useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [revenueDetailsObj, setRevenueDetailsObj] = useState<any>({});

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /**
   * This asynchronous function, handleGetHardwareInformation, is responsible for fetching hardware information
   * related to the specified location ID using the fetchAllHardwareReportInfo API function.
   */
  const handleGetHardwareInformation = async () => {
    try {
      // Fetch hardware information for the specified location ID
      const res = await fetchAllHardwareReportInfo(match.params.locationId);

      // Update hardware information node list state with fetched data
      setHardwareInformationNodeList(res.data.data);
    } catch (err) {
      // Handle errors
      setIsLoading(false); // Set loading state to false
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR); // Set error message
    }
  };

  /**
   * This asynchronous function, handleGetRevenueInformation, is responsible for fetching revenue information
   * for a specific location and filter criteria, possibly based on shift, using the fetchAllRevenueCenterInfo API function.
   */
  const handleGetRevenueInformation = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      // Fetch revenue information for the specified location ID and filter criteria
      const res = await fetchAllRevenueCenterInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );

      // Update revenue details object state with fetched data
      setRevenueDetailsObj(res.data);

      // Set loading state to false
      setIsLoading(false);
    } catch (err) {
      // Handle errors
      setIsLoading(false); // Set loading state to false
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR); // Set error message
    }
  };

  /** A function that fetches all filter locations based on searchName and updates the state
   * of locationList, locationData, locationSelectorList and isGetLocationInfo based on the response
   * */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
        }

        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  /**
   * This useEffect hook is responsible for executing side effects when the component mounts for the first time.
   */
  useEffect(() => {
    // Set document title
    document.title = "Sale - Revenue Center Report";

    // Fetch all filter locations
    getAllFilterLocation("");

    // Fetch hardware information for the current location
    handleGetHardwareInformation();
  }, []);

  /**
   * This function is responsible for handling filter data and
   * triggering the retrieval of revenue information based on the provided filter data and shift information.
   */
  const handleFilterData = (filterData: any, basedOnShift: any) => {
    // Set isLoading to true to indicate data loading
    setIsLoading(true);

    // Fetch revenue information based on the provided filter data and shift information
    handleGetRevenueInformation(filterData, basedOnShift);

    // Update filterData state with the provided filter data
    setFilterData(filterData);

    // Update basedOnShift state with the provided basedOnShift value
    setBasedOnShift(basedOnShift);
  };

  /**
   * This function is responsible for handling the click event when the text is clicked.
   * It opens the filter card and scrolls the window to the top with smooth behavior.
   */
  const handleOnClickText = () => {
    // Open the filter card
    setOpenFilterCard(true);

    // Scroll the window to the top with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={revenueCenterFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={hardwareInformationNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Revenue Center summary report for"
          topic="Revenue Center Report"
          isNeedFilterDetails={true}
          isDailySale={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
        >
          <RevenueCenterReportInfoNode
            filterDetails={filterDetails}
            hardwareInformationNodeList={hardwareInformationNodeList}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            revenueDetailsObj={revenueDetailsObj}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(RevenueCenterReport, Authorities.SALE_READ);
