import { Box, Hidden, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { CustomTheme } from "../../../../types/customTheme";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";
import MenusPageLoading from "./MenusPageLoading";
import { fetchMenusInfo } from "../../../../services/menuApp/menuService";

export interface MenusProps {}

/*
  This component is responsible for displaying mapping strategies for a particular location.
  It fetches strategy information from the backend and renders it on the page.
*/
const Menus: React.FunctionComponent<MenusProps> = () => {
  const [menusNodeList, setMenusNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const match: any = useRouteMatch();

  /**
   * This function fetches strategy information from the backend for a specific location.
   * It uses the locationId from the route match object to make the API call.
   * Upon successful fetch, it sets the strategy node list and updates loading status.
   * If an error occurs during the fetch, it sets an error message and updates loading status.
   */
  const getMenusInfo = async () => {
    try {
      // Fetch strategy information from the backend
      const res = await fetchMenusInfo(match.params.locationId);
      // Set the strategy node list with the fetched data
      setMenusNodeList(res.data.data);
      // Update loading status to indicate data loading is complete
      setIsLoading(false);
    } catch (error) {
      // Set an error message if an unexpected error occurs during fetch
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      // Update loading status to indicate data loading is complete (even if it failed)
      setIsLoading(false);
    }
  };

  /**
   * This useEffect hook runs once when the component is mounted.
   * It sets the document title to "Menu - Mapping Strategies" and calls the getMenusInfo function to fetch strategy information.
   */
  useEffect(() => {
    // Set the document title to "Menu - Mapping Strategies"
    document.title = "Menu - Menus";
    // Call the getMenusInfo function to fetch strategy information
    getMenusInfo();
  }, []);

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <MenusPageLoading
          nodes={menusNodeList}
          isLoading={isLoading}
          setError={setError}
          getMenusInfo={getMenusInfo}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(Menus, Authorities.DISCOUNT_READ);
