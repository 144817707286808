import React from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";

export interface CustomerNodeProps {
  handleOpenBannerAddModal: any;
  isOwner: any;
}

const CreateNewBanner: React.FunctionComponent<CustomerNodeProps> = ({
  handleOpenBannerAddModal,
  isOwner,
}) => {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        >
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              width: "160px",
              marginRight: "14px",
            }}
            disable={!isOwner}
            color={buttonColors.UPDATE_BUTTON_COLOR}
            onClick={handleOpenBannerAddModal}
          >
            Add New Banner
          </ButtonCommon>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateNewBanner;
