import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import "jspdf-autotable";
import { Icon } from "@iconify/react";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import { sortNumbers, sortStrings } from "../../../../utils/commonArrayMap";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import EditModal from "./editModal";
import {
  getLocalStore,
  setLocalStore,
} from "../../../../utils/store/localStore";
import SwitchCommon from "../../../../components/switches/SwitchCommon";

export interface PaymentReportInfoNodeProps {
  salesByCashierReportNodeList: any;
  locationSelectorList: any;
  filterDetails: any;
  filterData: any;
  basedOnShift: any;
  locationSelectedList: any;
  handleOnClickText: any;
  userDetails: any;
  categoryList: any;
  userIdList: any;
  setIsLoading: any;
  allUsers: any;
  setUserIdList: any;
  isActiveUserSwitch: any;
  setIsActiveUserSwitch: any;
  isCategoryError: any;
}

const useStylesTab = makeStyles((theme: any) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    discountTypeSection: {
      borderRadius: 8,
    },
    tabPanel: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

const useStyles = (
  nodesLength: number,
  bodyLength: number,
  headerLength: number,
) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "16px",
        fontSize: "12px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "12px",
      },
      endTextStyle: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
        paddingRight: "20px",
      },
      endTextStyleIndexFour: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
      },
      headerEndTextStyle: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
      },
      footerEndTextStyle: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
      },
      footerEndTextStyles: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "20px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "20%",
          width: "20%",
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${headerLength - 1})`]: {
            minWidth: `${
              80 / (headerLength - 1) < 11 ? 11 : 80 / (headerLength - 1)
            }%`,
            width: `${
              80 / (headerLength - 1) < 11 ? 11 : 80 / (headerLength - 1)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${headerLength})`]: {
            minWidth: `${
              80 / (headerLength - 1) < 11 ? 11 : 80 / (headerLength - 1)
            }%`,
            width: `${
              80 / (headerLength - 1) < 11 ? 11 : 80 / (headerLength - 1)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
        },
        [theme.breakpoints.down("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${headerLength - 1})`]: {
            minWidth: `${
              80 / (headerLength - 1) < 20 ? 20 : 80 / (headerLength - 1)
            }%`,
            width: `${
              80 / (headerLength - 1) < 20 ? 20 : 80 / (headerLength - 1)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${headerLength})`]: {
            minWidth: `${
              80 / (headerLength - 1) < 20 ? 20 : 80 / (headerLength - 1)
            }%`,
            width: `${
              80 / (headerLength - 1) < 20 ? 20 : 80 / (headerLength - 1)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${headerLength - 1})`]: {
            minWidth: `${
              80 / (headerLength - 1) < 40 ? 40 : 80 / (headerLength - 1)
            }%`,
            width: `${
              80 / (headerLength - 1) < 40 ? 40 : 80 / (headerLength - 1)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${headerLength})`]: {
            minWidth: `${
              80 / (headerLength - 1) < 40 ? 40 : 80 / (headerLength - 1)
            }%`,
            width: `${
              80 / (headerLength - 1) < 40 ? 40 : 80 / (headerLength - 1)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
        },
      },
    }),
  );

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStylesTab();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "16px 0" }}>{children}</div>}
    </div>
  );
};

/**
 * SalesByCashierReportNode Component
 *
 * This component renders a table displaying payment report data.
 * It also provides options to export the report as PDF or CSV.
 * Users can view sale details by clicking on items in the table.
 */
const SalesByCashierReportNode: React.FunctionComponent<
  PaymentReportInfoNodeProps
> = ({
  salesByCashierReportNodeList,
  locationSelectorList,
  filterDetails,
  filterData,
  basedOnShift,
  locationSelectedList,
  handleOnClickText,
  userDetails,
  categoryList,
  userIdList,
  setIsLoading,
  allUsers,
  setUserIdList,
  isActiveUserSwitch,
  setIsActiveUserSwitch,
  isCategoryError,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcelAmount, setNodesPdfExcelAmount] = useState<any>([]);
  const [nodesPdfExcelDiscount, setNodesPdfExcelDiscount] = useState<any>([]);
  const [nodesPdfExcelOrders, setNodesPdfExcelOrders] = useState<any>([]);
  const [disableCategoryList, setDisableCategoryList] = useState<any>([]);
  const [categoryErrorList, setCategoryErrorList] = useState<any>([]);
  const [disableCategoryListUpdate, setDisableCategoryListUpdate] =
    useState<any>([]);
  const [nodesLength, setNodeLength] = useState(0);
  const [bodyLength, setBodyLength] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthLg = useMediaQuery("(max-width: 1280px)");
  const maxWidthCustom = useMediaQuery("(max-width: 1600px)");
  const [value, setValue] = React.useState(0);
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const disableCategory = getLocalStore("hiddenCategory");
    if (!_.isEmpty(JSON.parse(disableCategory))) {
      setDisableCategoryList(JSON.parse(disableCategory));
      setDisableCategoryListUpdate(JSON.parse(disableCategory));
    }
  }, []);

  useEffect(() => {
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /* Creating the data for the Payment report table and creating the data for pdf and excel files.
       Using a API request */
  useEffect(() => {
    let clonePaymentReportNode: any = _.cloneDeep(salesByCashierReportNodeList);
    let pdfExcelNodeAmount: any = [];
    let pdfExcelNodeDiscount: any = [];
    let pdfExcelNodeOrders: any = [];
    const headerList: any = ["Category Name"];
    const pdfHeaderList: any = [
      { title: "Category name", field: "categoryName" },
    ];
    const excelHeaderList: any = [
      { header: "Category name", key: "categoryName", width: 52, height: 68 },
    ];

    if (!_.isEmpty(clonePaymentReportNode)) {
      setIsLoading(true);
      let count = 0;
      const specialCategories = ["Hidden Food", "Hidden Drinks", "Add Price"];
      const specialCategoryObjects: any = [];
      const categoryListError: any = [];

      if (isCategoryError) {
        Object.entries(salesByCashierReportNodeList).map(
          ([key, value]: any) => {
            categoryListError.push({
              id: key,
              name: value[0].categoryName,
            });
          },
        );
        setCategoryErrorList(categoryListError);
      }

      const categoryData = isCategoryError ? categoryListError : categoryList;

      const output = categoryData
        .map((category: any) => {
          if (
            category.name !== "Online Hidden" &&
            category.name !== "Templates" &&
            category.name !== "Value Zero"
          ) {
            const abc: any = {};
            if (!disableCategoryListUpdate.includes(category.id)) {
              const users = userDetails
                .map((user: any) => {
                  if (user.name !== "FFA ADMIN") {
                    if (isActiveUserSwitch) {
                      if (!headerList.includes(user.name)) {
                        headerList.push(user.name);
                        pdfHeaderList.push({
                          title: user.name,
                          field: user.name,
                        });
                        excelHeaderList.push({
                          header: user.name,
                          key: user.name,
                          width: 32,
                          height: 68,
                        });
                      }

                      const userCategoryData = salesByCashierReportNodeList[
                        category.id
                      ]?.find((item: any) => item.userId === user.id);
                      if (userCategoryData) {
                        abc[user.name] = userCategoryData?.amount
                          ? userCategoryData?.amount
                          : "-";
                        return {
                          ...user,
                          discount: userCategoryData.discount,
                          qty: userCategoryData.qty,
                          amount: userCategoryData.amount,
                        };
                      } else {
                        abc[user.name] = "-";
                      }

                      return user;
                    } else {
                      if (userIdList.includes(user.id)) {
                        if (!headerList.includes(user.name)) {
                          headerList.push(user.name);
                          pdfHeaderList.push({
                            title: user.name,
                            field: user.name,
                          });
                          excelHeaderList.push({
                            header: user.name,
                            key: user.name,
                            width: 32,
                            height: 68,
                          });
                          count = count + 1;
                        }

                        const userCategoryData = salesByCashierReportNodeList[
                          category.id
                        ]?.find((item: any) => item.userId === user.id);

                        if (userCategoryData) {
                          abc[user.name] = userCategoryData?.amount
                            ? userCategoryData?.amount
                            : "-";
                          return {
                            ...user,
                            discount: userCategoryData.discount,
                            qty: userCategoryData.qty,
                            amount: userCategoryData.amount,
                          };
                        } else {
                          abc[user.name] = "-";
                        }

                        return user;
                      }
                    }
                  }
                })
                .filter(Boolean);

              const categoryObject = { ...category, user: users };

              if (specialCategories.includes(category.name)) {
                specialCategoryObjects.push(categoryObject);
              } else {
                pdfExcelNodeAmount.push({
                  categoryName: category.name,
                  ...abc,
                });
                pdfExcelNodeDiscount.push({
                  categoryName: category.name,
                  ...abc,
                });
                pdfExcelNodeOrders.push({
                  categoryName: category.name,
                  ...abc,
                });
                count = count + 1;

                return categoryObject;
              }
            }
          }
        })
        .filter(Boolean);

      // Append special category objects at the end
      specialCategoryObjects.forEach((specialCategory: any) => {
        pdfExcelNodeAmount.push({
          categoryName: specialCategory.name,
          ...specialCategory.user.reduce((acc: any, user: any) => {
            acc[user.name] = user.amount ? user.amount : "-";
            return acc;
          }, {}),
        });
        pdfExcelNodeDiscount.push({
          categoryName: specialCategory.name,
          ...specialCategory.user.reduce((acc: any, user: any) => {
            acc[user.name] = user.discount ? user.discount : "-";
            return acc;
          }, {}),
        });
        pdfExcelNodeOrders.push({
          categoryName: specialCategory.name,
          ...specialCategory.user.reduce((acc: any, user: any) => {
            acc[user.name] = user.qty ? user.qty : "-";
            return acc;
          }, {}),
        });

        count = count + 1;
        output.push(specialCategory);
      });

      // Set pdf headers
      setPdfHeaders(pdfHeaderList);

      // Set excel headers
      setExcelHeaders(excelHeaderList);

      // Set table headers
      setHeadersList(headerList);

      setNodeLength(output.length + 1);

      // Payment report length
      setBodyLength(output.length);

      // set the table data in the state
      setNodes({ nodes: output });
      // set the table footer in the state
      // setFooter(footer);

      // Merge the body and footer of PDF/ Excel.
      // const merged = [...cashierSummaryNodeList, ...footer];
      // const merged = [...cashierSummaryNodeList];

      // set the pdf excel data in the state
      setNodesPdfExcelAmount(pdfExcelNodeAmount);
      setNodesPdfExcelDiscount(pdfExcelNodeDiscount);
      setNodesPdfExcelOrders(pdfExcelNodeOrders);

      setIsLoading(false);
    }
  }, [
    salesByCashierReportNodeList,
    userDetails,
    isActiveUserSwitch,
    userIdList,
    disableCategoryListUpdate,
  ]);

  // Create a pay In Out sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: {
        Name: (array) => sortStrings(array, "name"),
        "No Of Orders": (array) => sortNumbers(array, "noOfOrders"),
        "Sales Total": (array) => sortNumbers(array, "salesTotal"),
        Discounts: (array) => sortNumbers(array, "discounts"),
        "No Of Voids": (array) => sortNumbers(array, "noOfVoids"),
        "Voids Total": (array) => sortNumbers(array, "voidsTotal"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  const handleActivateSwitch = () => {
    setIsActiveUserSwitch(!isActiveUserSwitch);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handleSubmit = () => {
    const keys: any = [];

    Object.entries(allUsers).map(([key, data]: any) => {
      data.map((data1: any) => {
        if (
          !keys.includes(data1.userId) &&
          !JSON.stringify(disableCategoryList).includes(key)
        ) {
          keys.push(data1.userId);
        }
      });
    });

    setIsLoading(true);
    setDisableCategoryListUpdate(JSON.stringify(disableCategoryList));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    setLocalStore("hiddenCategory", JSON.stringify(disableCategoryList));
    setIsOpenEditModal(false);
    setUserIdList(keys);
  };

  const handleHidden = (categoryId: any) => {
    const cloneDisableCategoryList = _.cloneDeep(disableCategoryList);
    cloneDisableCategoryList.push(categoryId);

    setDisableCategoryList(cloneDisableCategoryList);
  };

  const handleHVisible = (categoryId: any) => {
    const cloneDisableCategoryList = _.cloneDeep(disableCategoryList);
    const filter = cloneDisableCategoryList.filter(
      (data: any) => data !== categoryId,
    );

    setDisableCategoryList(filter);
  };

  const handleCloseModal = () => {
    setIsOpenEditModal(false);
    setDisableCategoryList(disableCategoryListUpdate);
  };

  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const theme: CustomTheme = useTheme();
  const classes: any = useStyles(nodesLength, bodyLength, headersList.length)();
  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {!_.isEmpty(nodes.nodes) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { display: "flex", marginLeft: "16px" }
                }
              >
                <FormControlLabel
                  style={{ marginRight: "16px" }}
                  control={<SwitchCommon />}
                  checked={isActiveUserSwitch}
                  onChange={handleActivateSwitch}
                  label="Show All Users"
                  labelPlacement="start"
                />
                <ButtonCommon
                  onClick={handleOpenEditModal}
                  variant="contained"
                  style={{
                    fontSize: 11,
                    width: "178px",
                    marginRight: "14px",
                  }}
                  color={buttonColors.UPDATE_BUTTON_COLOR}
                >
                  {"Show/Hide Categories"}
                </ButtonCommon>
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    width: "140px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        value === 0
                          ? nodesPdfExcelAmount
                          : value === 1
                          ? nodesPdfExcelDiscount
                          : nodesPdfExcelOrders,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        value === 0
                          ? nodesPdfExcelAmount
                          : value === 1
                          ? nodesPdfExcelDiscount
                          : nodesPdfExcelOrders,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <CardCommon>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="discount-types-tabs"
          variant="fullWidth"
        >
          <Tab label="AMOUNTS" id="tab-0" aria-controls="tab-0" />
          <Tab label="DISCOUNT" id="tab-0" aria-controls="tab-0" />
          <Tab label="ORDERS" id="tab-1" aria-controls="tab-1" />
        </Tabs>
      </CardCommon>
      <div className={classes.root}>
        <TabPanel value={value} index={0}>
          {!_.isEmpty(nodes?.nodes) && (
            <CardCommon>
              <Table
                data={nodes}
                sort={sort}
                layout={{
                  custom: maxWidthLg
                    ? 80 / (headersList?.length - 1) < 20
                      ? true
                      : maxWidthCustom
                    : 80 / (headersList?.length - 1) < 11
                    ? true
                    : maxWidthCustom,
                  horizontalScroll: maxWidthLg
                    ? 80 / (headersList?.length - 1) < 20
                      ? true
                      : maxWidth
                    : 80 / (headersList?.length - 1) < 11
                    ? true
                    : maxWidth,
                }}
              >
                {(tableList) => (
                  <>
                    <Header style={{ zIndex: 0 }}>
                      <HeaderRow className={classes.headerStyle}>
                        {headersList.map((data: any, index: any) => (
                          <HeaderCellSort
                            sortKey={data}
                            pinLeft={index === 0 ? true : false}
                            className={classes.baseCellRow}
                          >
                            <Typography
                              variant="caption"
                              className={
                                index === 0
                                  ? classes.startTextStyle
                                  : index + 1 === headersList.length
                                  ? classes.headerEndTextStyle
                                  : classes.textStyleHeader
                              }
                              style={{
                                color: theme.palette.custom.orange.contrastText,
                                fontWeight: "bold",
                              }}
                            >
                              {data}
                            </Typography>
                          </HeaderCellSort>
                        ))}
                      </HeaderRow>
                    </Header>

                    <Body className={classes.bodyStyle}>
                      {tableList.map((item, bodyIndex) => (
                        <>
                          <Row
                            key={item.gross20}
                            item={item}
                            className={classes.headerRow}
                          >
                            <Cell
                              pinLeft={true}
                              className={classes.baseCellRow}
                            >
                              <Typography
                                variant="caption"
                                align="left"
                                className={classes.startTextStyle}
                                style={{ fontWeight: "bold" }}
                              >
                                {item.name}
                              </Typography>
                            </Cell>

                            {item?.user.map((data: any, index: any) => (
                              <>
                                <Cell className={classes.baseCellRow}>
                                  <Typography
                                    variant="caption"
                                    align="left"
                                    className={
                                      index + 2 === headersList.length
                                        ? classes.endTextStyle
                                        : classes.textStyle
                                    }
                                    style={
                                      data === "name"
                                        ? { fontWeight: "bold" }
                                        : { fontWeight: "normal" }
                                    }
                                  >
                                    {data?.amount ? data?.amount : "-"}
                                  </Typography>
                                </Cell>
                              </>
                            ))}
                          </Row>
                        </>
                      ))}
                    </Body>
                  </>
                )}
              </Table>
            </CardCommon>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!_.isEmpty(nodes?.nodes) && (
            <CardCommon>
              <Table
                data={nodes}
                sort={sort}
                layout={{
                  custom: maxWidthLg
                    ? 80 / (headersList?.length - 1) < 20
                      ? true
                      : maxWidthCustom
                    : 80 / (headersList?.length - 1) < 11
                    ? true
                    : maxWidthCustom,
                  horizontalScroll: maxWidthLg
                    ? 80 / (headersList?.length - 1) < 20
                      ? true
                      : maxWidth
                    : 80 / (headersList?.length - 1) < 11
                    ? true
                    : maxWidth,
                }}
              >
                {(tableList) => (
                  <>
                    <Header style={{ zIndex: 0 }}>
                      <HeaderRow className={classes.headerStyle}>
                        {headersList.map((data: any, index: any) => (
                          <HeaderCellSort
                            sortKey={data}
                            pinLeft={index === 0 ? true : false}
                            className={classes.baseCellRow}
                          >
                            <Typography
                              variant="caption"
                              className={
                                index === 0
                                  ? classes.startTextStyle
                                  : index + 1 === headersList.length
                                  ? classes.headerEndTextStyle
                                  : classes.textStyleHeader
                              }
                              style={{
                                color: theme.palette.custom.orange.contrastText,
                                fontWeight: "bold",
                              }}
                            >
                              {data}
                            </Typography>
                          </HeaderCellSort>
                        ))}
                      </HeaderRow>
                    </Header>

                    <Body className={classes.bodyStyle}>
                      {tableList.map((item, bodyIndex) => (
                        <>
                          <Row
                            key={item.gross20}
                            item={item}
                            className={classes.headerRow}
                          >
                            <Cell
                              pinLeft={true}
                              className={classes.baseCellRow}
                            >
                              <Typography
                                variant="caption"
                                align="left"
                                className={classes.startTextStyle}
                                style={{ fontWeight: "bold" }}
                              >
                                {item.name}
                              </Typography>
                            </Cell>

                            {item?.user.map((data: any, index: any) => (
                              <>
                                <Cell className={classes.baseCellRow}>
                                  <Typography
                                    variant="caption"
                                    align="left"
                                    className={
                                      index + 2 === headersList.length
                                        ? classes.endTextStyle
                                        : classes.textStyle
                                    }
                                    style={
                                      data === "name"
                                        ? { fontWeight: "bold" }
                                        : { fontWeight: "normal" }
                                    }
                                  >
                                    {data?.discount ? data?.discount : "-"}
                                  </Typography>
                                </Cell>
                              </>
                            ))}
                          </Row>
                        </>
                      ))}
                    </Body>
                  </>
                )}
              </Table>
            </CardCommon>
          )}
        </TabPanel>

        <TabPanel value={value} index={2}>
          {!_.isEmpty(nodes?.nodes) && (
            <CardCommon>
              <Table
                data={nodes}
                sort={sort}
                layout={{
                  custom: maxWidthLg
                    ? 80 / (headersList?.length - 1) < 20
                      ? true
                      : maxWidthCustom
                    : 80 / (headersList?.length - 1) < 11
                    ? true
                    : maxWidthCustom,
                  horizontalScroll: maxWidthLg
                    ? 80 / (headersList?.length - 1) < 20
                      ? true
                      : maxWidth
                    : 80 / (headersList?.length - 1) < 11
                    ? true
                    : maxWidth,
                }}
              >
                {(tableList) => (
                  <>
                    <Header style={{ zIndex: 0 }}>
                      <HeaderRow className={classes.headerStyle}>
                        {headersList.map((data: any, index: any) => (
                          <HeaderCellSort
                            sortKey={data}
                            pinLeft={index === 0 ? true : false}
                            className={classes.baseCellRow}
                          >
                            <Typography
                              variant="caption"
                              className={
                                index === 0
                                  ? classes.startTextStyle
                                  : index + 1 === headersList.length
                                  ? classes.headerEndTextStyle
                                  : classes.textStyleHeader
                              }
                              style={{
                                color: theme.palette.custom.orange.contrastText,
                                fontWeight: "bold",
                              }}
                            >
                              {data}
                            </Typography>
                          </HeaderCellSort>
                        ))}
                      </HeaderRow>
                    </Header>

                    <Body className={classes.bodyStyle}>
                      {tableList.map((item, bodyIndex) => (
                        <>
                          <Row
                            key={item.gross20}
                            item={item}
                            className={classes.headerRow}
                          >
                            <Cell
                              pinLeft={true}
                              className={classes.baseCellRow}
                            >
                              <Typography
                                variant="caption"
                                align="left"
                                className={classes.startTextStyle}
                                style={{ fontWeight: "bold" }}
                              >
                                {item.name}
                              </Typography>
                            </Cell>

                            {item?.user.map((data: any, index: any) => (
                              <>
                                <Cell className={classes.baseCellRow}>
                                  <Typography
                                    variant="caption"
                                    align="left"
                                    className={
                                      index + 2 === headersList.length
                                        ? classes.endTextStyle
                                        : classes.textStyle
                                    }
                                    style={
                                      data === "name"
                                        ? { fontWeight: "bold" }
                                        : { fontWeight: "normal" }
                                    }
                                  >
                                    {data?.qty ? data?.qty : "-"}
                                  </Typography>
                                </Cell>
                              </>
                            ))}
                          </Row>
                        </>
                      ))}
                    </Body>
                  </>
                )}
              </Table>
            </CardCommon>
          )}
        </TabPanel>
      </div>

      <EditModal
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        handleSubmit={handleSubmit}
        categoryList={isCategoryError ? categoryErrorList : categoryList}
        handleHidden={handleHidden}
        handleHVisible={handleHVisible}
        disableCategoryList={disableCategoryList}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default SalesByCashierReportNode;
