import React, { useState } from "react";
import _ from "lodash";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import "jspdf-autotable";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { CustomTheme } from "../../../../types/customTheme";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";

import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { Autocomplete } from "@material-ui/lab";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";

const customerRoles = [
  { id: "ROLE_OWNER", label: "Owner" },
  { id: "ROLE_MANAGER", label: "Manager" },
];

export interface CustomerNodeProps {
  isOpen: any;
  setIsOpen: any;
  isEdit: any;
  handleReset: any;
  isLoadingButton: any;
  handleSubmitData: any;
  selectedRoleObj: any;
  setSelectedRoleObj: any;
  isActive: any;
  setIsActive: any;
  setApiKeyName: any;
  apiKeyName: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
  }),
);

const BannerCreateAndUpdateModal: React.FunctionComponent<
  CustomerNodeProps
> = ({
  isOpen,
  setIsOpen,
  isEdit,
  handleReset,
  isLoadingButton,
  handleSubmitData,
  selectedRoleObj,
  setSelectedRoleObj,
  isActive,
  setIsActive,
  setApiKeyName,
  apiKeyName,
}) => {
  const [open, setOpen] = useState(false);

  const handleDisable = () => {};

  const handleChangeRole = (e: any, role: any) => {
    // Update the state with the selected role
    setSelectedRoleObj(role);
  };

  const handleActivate = () => {
    setIsActive(!isActive);
  };
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
        backgroundColor={theme.palette.background.entity_background}
      >
        <DialogTitle>{isEdit ? "EDIT LOYALTY API KEY" : "ADD NEW LOYALTY API KEY"}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{marginBottom: "8px", }}>
              <Typography
                style={{ fontWeight: "bold" }}
              >
                API Key Name
              </Typography>
              <TextfieldCommon
                id="sign-email-input"
                name="sign-email-input"
                style={{ width: "100%" }}
                label="API Key Name"
                onChange={(e: any) => setApiKeyName(e.target.value)}
                value={apiKeyName}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: isEdit ? "80%" : "100%" }}>
                  <Typography style={{ fontWeight: "bold" }}>Role</Typography>
                  <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    size={"small"}
                    id="locationSelectGlobal"
                    color="inherit"
                    options={customerRoles}
                    value={selectedRoleObj}
                    getOptionLabel={(option: any) => option.label || ""}
                    disableClearable
                    onChange={handleChangeRole}
                    classes={{ root: classes.autoComplete }}
                    popupIcon={<ArrowDropDownIcon color={"inherit"} />}
                    renderOption={(props: any) => {
                      return (
                        <Grid
                          container
                          {...props}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            zIndex: 0,
                            height: "20px",
                            marginBottom: "4px",
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              className={classes.titleColor}
                            >
                              {props.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        color="inherit"
                        {...params}
                        label=""
                        placeholder="Select Role"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                {isEdit && (
                  <FormControlLabel
                    style={{ marginRight: "0px" }}
                    control={<SwitchCommon />}
                    checked={isActive}
                    onChange={handleActivate}
                    label="Active"
                    labelPlacement="top"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            disabled={isLoadingButton}
            color="yellow"
            onClick={handleReset}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={isLoadingButton}
            isLoadingPage={isLoadingButton}
            color={isEdit ? "orange" : "green"}
            onClick={handleSubmitData}
          >
            {isEdit ? "Save" : "Create"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default BannerCreateAndUpdateModal;
