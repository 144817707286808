import React from "react";
import {
  Box,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import SkeleTonLoading from "../Loading/SkeleTonLoading";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import PageHeaderMobile from "../PageHeader/PageHeaderMobile";
import ButtonCommon from "../../buttons/ButtonCommon";
import { buttonColors } from "../../../utils/enum";
import { CustomTheme } from "../../../types/customTheme";
import NoInventoryDataFound from "../NoDataFound/NoInventoryDataFound";
import AlertDialog from "../../alerts/AlertDialog";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      marginTop: "36px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    buttonStyle: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-between",
        marginTop: "12px",
      },
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      width: "200px",
      marginRight: "12px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface InventoryDetailsDefaultProps {
  locationSelectorList: any;
  isOpenSkeletonLoading: any;
  nodeList?: any;
  topic: any;
  children: any;
  handleOpenCreateModal?: any;
  buttonName?: any;
  isOrder?: any;
  handleChangeSearch?: any;
  searchName?: any;
  isSearch?: any;
  handleClearSearch?: any;
  isNeedAddButton: any;
  isActiveLocationId: any;
  isLocation?: any
}

/**
 * InventoryDetailsDefault is a React component that displays inventory details with options for creating items.
 * It handles responsive design and loading states while providing a user-friendly interface.
 *
 * @returns {JSX.Element} A React component that renders inventory details and options.
 */
const InventoryDetailsDefault: React.FunctionComponent<
  InventoryDetailsDefaultProps
> = ({
  isOpenSkeletonLoading,
  nodeList,
  topic,
  children,
  handleOpenCreateModal,
  buttonName,
  isOrder,
  handleChangeSearch,
  searchName,
  isSearch,
  handleClearSearch,
  isNeedAddButton,
  isActiveLocationId,
  isLocation,
}) => {
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down("md"));

  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/apps`;
  };

  return (
    <>
      <Box>
        <div
          style={
            matchesLg
              ? {
                  padding: "8px 6px 8px 8px",
                }
              : {
                  padding: "2px 12px 2px 12px",
                  marginTop: "6px",
                }
          }
        >
          <Hidden lgUp>
            <PageHeaderMobile />
          </Hidden>
          {!isOpenSkeletonLoading && (
            <div className={classes.root}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Typography
                  variant="h4"
                  style={{ fontWeight: "bold", cursor: "pointer" }}
                  color="secondary"
                >
                  {topic}
                </Typography>
              </div>
              <div className={classes.buttonStyle}>
                {isOrder && (
                  <TextField
                    classes={{ root: classes.textField }}
                    id="outlined-inline-static"
                    placeholder="Search Supplier"
                    variant="outlined"
                    onChange={(e: any) => handleChangeSearch(e.target.value)}
                    value={searchName}
                    style={{
                      height: "40px",
                      marginTop: "4px",
                      marginBottom: "4px",
                      width: "!00%",
                    }}
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={handleClearSearch}
                          >
                            {isSearch ? <ClearIcon /> : <SearchIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {isNeedAddButton && (
                  <ButtonCommon
                    style={
                      isOrder
                        ? {
                            marginRight: 4,
                            width: "210px",
                            height: "40px",
                          }
                        : {
                            marginRight: 4,
                            width: "210px",
                          }
                    }
                    variant="contained"
                    color={buttonColors.CREATE_BUTTON_COLOR}
                    onClick={handleOpenCreateModal}
                  >
                    <Typography
                      style={{ textTransform: "none", color: "white" }}
                    >
                      {buttonName}
                    </Typography>
                  </ButtonCommon>
                )}
              </div>
            </div>
          )}
        </div>
        {!isOpenSkeletonLoading ? (
          <>
            {!_.isEmpty(nodeList) ? (
              <div
                style={
                  matchesLg
                    ? { padding: "8px" }
                    : { padding: "8px 16px 8px 8px" }
                }
              >
                {children}
              </div>
            ) : (
              <NoInventoryDataFound title={topic} />
            )}
          </>
        ) : (
          <div
            style={
              matchesLg
                ? { marginTop: "0px", padding: "8px" }
                : { marginTop: "20px", padding: "8px 16px 8px 8px" }
            }
          >
            <SkeleTonLoading isReport={false} isLocation={isLocation} />
          </div>
        )}
      </Box>
      {!isActiveLocationId && (
        <AlertDialog
          open={!isActiveLocationId}
          title="Activation Required"
          desc="Stock & inventory management is disabled on your account. Please contact your Presto Express account manager to request access."
          severity="error"
          confirmLabel="Back to Apps"
          disableBackdropClick
          confirmAction={handleRedirect}
          color="secondary"
        />
      )}
    </>
  );
};

export default InventoryDetailsDefault;
