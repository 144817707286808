import React, { useState } from "react";
import _ from "lodash";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import "jspdf-autotable";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { CustomTheme } from "../../../../types/customTheme";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";

import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { Autocomplete } from "@material-ui/lab";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";

const customerRoles = [
  { id: "ROLE_OWNER", label: "Owner" },
  { id: "ROLE_MANAGER", label: "Manager" },
];

export interface CustomerNodeProps {
  isOpen: any;
  setIsOpen: any;selectedApiKey: any
}

const ApiKeyDisplayModal: React.FunctionComponent<CustomerNodeProps> = ({
  isOpen,
  setIsOpen,selectedApiKey
}) => {
  const theme: CustomTheme = useTheme();
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
        backgroundColor={theme.palette.background.entity_background}
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: "8px" }}>
              <Typography style={{ fontWeight: "bold" }}>
                API Key Name
              </Typography>
              <TextfieldCommon
                id="sign-email-input"
                name="sign-email-input"
                style={{ width: "100%" }}
                label="API Key Name"
                value={selectedApiKey}
                type="text"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default ApiKeyDisplayModal;
