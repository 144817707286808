import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";
import StrategyPageLoading from "./StrategyPageLoading";
import {
  fetchLoyaltyProgramInfo,
  fetchLoyaltyStrategyInfo,
} from "../../../../services/customerApp/customerDetails";
import _ from "lodash";
import AlertDialogDefault from "../../../../components/alerts/AlertDialogDefault";

export interface MappingStrategiesProps {}

/*
  This component is responsible for displaying mapping strategies for a particular location.
  It fetches strategy information from the backend and renders it on the page.
*/
const Strategy: React.FunctionComponent<MappingStrategiesProps> = () => {
  const [strategyNodeList, setStrategyNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [isOwner, setIsOwner] = useState(false);

  const match: any = useRouteMatch();

  const handleGetLocationProgram = async () => {
    try {
      const { data } = await fetchLoyaltyProgramInfo(match.params.locationId);
      const programDetails: any = Object.values(data.data)[0];
      setIsActive(programDetails?.status);
      if (programDetails?.status) {
        setIsOwner(
          programDetails?.owner === match.params.locationId ? true : false,
        );
        getStrategyInfo();
      } else {
        setIsLoading(false);
      }
      return data.data;
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * This function fetches strategy information from the backend for a specific location.
   * It uses the locationId from the route match object to make the API call.
   * Upon successful fetch, it sets the strategy node list and updates loading status.
   * If an error occurs during the fetch, it sets an error message and updates loading status.
   */
  const getStrategyInfo = async () => {
    try {
      // Fetch strategy information from the backend
      const res = await fetchLoyaltyStrategyInfo(match.params.locationId);
      // Set the strategy node list with the fetched data
      setStrategyNodeList(res.data.data);
      // Update loading status to indicate data loading is complete
      setIsLoading(false);
    } catch (error) {
      // Set an error message if an unexpected error occurs during fetch
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      // Update loading status to indicate data loading is complete (even if it failed)
      setIsLoading(false);
    }
  };

  /**
   * This useEffect hook runs once when the component is mounted.
   * It sets the document title to "Menu - Mapping Strategy" and calls the getStrategyInfo function to fetch strategy information.
   */
  useEffect(() => {
    // Set the document title to "Menu - Mapping Strategy"
    document.title = "Customer - Loyalty Strategies";
    // Call the getStrategyInfo function to fetch strategy information
    handleGetLocationProgram();
  }, []);

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <StrategyPageLoading
          nodes={strategyNodeList}
          isLoading={isLoading}
          setError={setError}
          getStrategyInfo={getStrategyInfo}
          setStrategyNodeList={setStrategyNodeList}
          isActive={isActive}
          isOwner={isOwner}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />

      <AlertDialogDefault
        open={!isActive}
        title="Warning"
        desc="Customer loyalty points management is disabled on your account. Please contact your EPOS account manager to request access."
        severity="warning"
        color="secondary"
        disableBackdropClick
      />
    </>
  );
};

export default withAuthority(Strategy, Authorities.DISCOUNT_READ);
