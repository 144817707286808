import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import React from "react";
import {
  DISCOUNT_TYPE_ALL,
  DISCOUNT_TYPE_CATEGORY,
  DISCOUNT_TYPE_ITEM,
  NODE_STATUS_CREATE,
} from "../../../../utils/consts";
import { CustomTheme, Theme } from "../../../../types/customTheme";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import { getNumberOfItems } from "../../../../utils/deliveryUtils";
import { useTheme } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.entity_background,
  },
  card: {
    // boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    boxShadow: "none",
    maxWidth: 250,
    borderRadius: 16,
    backgroundColor: theme.palette.custom.yellow.main,
    color: theme.palette.custom.yellow.contrastText,
    border: `3px solid ${theme.palette.custom.red.main}`,
    [theme.breakpoints.down("md")]: {
      maxWidth: "230px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "130px",
    },
  },
  notSelectedCard: {
    // boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    boxShadow: "none",
    maxWidth: 250,
    borderRadius: 16,
    backgroundColor: theme.palette.background.entity_highlight_background,
    border: `1px solid ${theme.palette.custom.red.main}`,
    [theme.breakpoints.down("md")]: {
      maxWidth: "230px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "130px",
    },
  },
  iconColor: {
    color: theme.palette.custom.red.main,
    fontWeight: "bolder",
  },
  diningIcon: {
    color: theme.palette.custom.red.main,
    fontSize: "130px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "100px",
    },
  },
  typography: {
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
     fontSize: "15px"
    },
  },
  typographyError: {
    fontWeight: "bold",
  },
  iconButton: {
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
      width: "26vw"
    },
    "&:hover": {
      background: "none",
    },
  },
}));

export interface AvailabilitySelectionModalProps {
  open: boolean;
  setOpen: Function;
  disabled?: boolean;
  availableApplyToList: any;
  handleApplyToCondition: any;
  handleApplyLToList: any;
  applicableList: any;
  handleSubmitData: any;
  handleCloseModal: any;
  applyToCondition: any;
  error: any;
  setApplyToConditionErrorMessage: any;
  nodeData: any;
  handleApplyLToListItems: any;
  applicableItemsList: any;
  applicableCategoryList: any;
}

const ApplyToConditionSelectionModal: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({
  open,
  setOpen,
  disabled,
  handleCloseModal,
  applicableList,
  handleApplyLToList,
  handleApplyToCondition,
  availableApplyToList,
  handleSubmitData,
  applyToCondition,
  error,
  setApplyToConditionErrorMessage,
  nodeData,
  handleApplyLToListItems,
  applicableItemsList,
  applicableCategoryList,
}) => {
  /* HTML editor can only be opened if the status is "create" */
  const handleClickOpen = () => {
    if (nodeData.status !== NODE_STATUS_CREATE) {
      handleApplyToCondition(applyToCondition);
      setOpen(true);
      setApplyToConditionErrorMessage(false);
    } else {
      setApplyToConditionErrorMessage(true);
    }
  };

  const handleClose = () => {
    handleCloseModal();
  };

  const handleSubmit = () => {
    handleSubmitData();
  };

  /* Disable applyToCondition button when same applyToCondition and same applicableList */
  const handleDisableButton = () => {
    if (applyToCondition !== nodeData.applyToCondition) {
      return false;
    } else if (
      applyToCondition === "categories" &&
      !_.isEqual(applicableList, nodeData.applyTo)
    ) {
      return false;
    } else if (
      applyToCondition === "items" &&
      !_.isEqual(applicableItemsList, nodeData.applyTo)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <div>
      {!disabled && (
        <Button variant="text" color="secondary" onClick={handleClickOpen}>
          Edit
        </Button>
      )}
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        className={classes.root}
        PaperProps={{
          style: {
            borderRadius: "10px",
            border: `1px solid ${theme.palette.background.entity_border}`,
            backgroundColor: theme.palette.background.entity_background,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Choose where to apply this discount
        </DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <IconButton
            className={classes.iconButton}
            onClick={() => handleApplyToCondition(DISCOUNT_TYPE_ALL)}
          >
            <Card
              className={
                applyToCondition === DISCOUNT_TYPE_ALL
                  ? classes.card
                  : classes.notSelectedCard
              }
            >
              <CardContent style={{ textAlign: "center" }}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className={classes.typography}
                >
                  All
                </Typography>
                <Typography gutterBottom variant="body2" component="div">
                  Discount applies to categories and items.
                </Typography>
              </CardContent>
            </Card>
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={() => handleApplyToCondition(DISCOUNT_TYPE_CATEGORY)}
          >
            <Card
              className={
                applyToCondition === DISCOUNT_TYPE_CATEGORY
                  ? classes.card
                  : classes.notSelectedCard
              }
            >
              <CardContent style={{ textAlign: "center" }}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className={classes.typography}
                >
                  Categories
                </Typography>
                <Typography gutterBottom variant="body2" component="div">
                  Discount applies only to specific categories.
                </Typography>
              </CardContent>
            </Card>
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={() => handleApplyToCondition(DISCOUNT_TYPE_ITEM)}
          >
            <Card
              className={
                applyToCondition === DISCOUNT_TYPE_ITEM
                  ? classes.card
                  : classes.notSelectedCard
              }
            >
              <CardContent style={{ textAlign: "center" }}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className={classes.typography}
                >
                  Items
                </Typography>
                <Typography gutterBottom variant="body2" component="div">
                  Discount applies only to specific items.
                </Typography>
              </CardContent>
            </Card>
          </IconButton>
        </div>

        {applyToCondition !== "all" ? (
          availableApplyToList.map((catMenu: any) =>
            applyToCondition === "items" ? (
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: "28px", marginTop: "20px" }}
                >
                  <Typography
                    variant="h5"
                    align="left"
                    style={{ fontWeight: "bold" }}
                  >
                    {catMenu.title && catMenu.title}
                  </Typography>
                </Grid>
                {Object.values(catMenu.data).map((val: any) => {
                  return (
                    <>
                      <Grid
                        key={val.catId}
                        xs={12}
                        item
                        style={{ paddingLeft: "52px", marginTop: "12px" }}
                      >
                        <FormControlLabel
                          checked={
                            !_.isEmpty(applicableCategoryList)
                              ? applicableCategoryList.includes(val.catId)
                              : false
                          }
                          value={val.catId}
                          name={val.catId}
                          onChange={(e) =>
                            handleApplyLToListItems(e, "category", val.data)
                          }
                          control={<Checkbox />}
                          label={
                            <>
                              <Typography variant="h6" color="textSecondary" style={{fontWeight: "bold"}}>
                                {val.title}
                              </Typography>
                            </>
                          }
                        />
                        <Grid container>
                          {Object.values(val.data).map((item: any) => (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              style={{ paddingLeft: "28px" }}
                            >
                              <FormControlLabel
                                checked={
                                  !_.isEmpty(applicableItemsList)
                                    ? applicableItemsList.includes(item.menuId)
                                    : false
                                }
                                value={item.menuId}
                                name={item.menuId}
                                onChange={(e) =>
                                  handleApplyLToListItems(e, "items", val)
                                }
                                control={<Checkbox />}
                                label={
                                  <>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {item.title}
                                    </Typography>
                                  </>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            ) : (
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: "28px", marginTop: "20px" }}
                >
                  <Typography
                    variant="h5"
                    align="left"
                    style={{ fontWeight: "bold" }}
                  >
                    {catMenu.title && catMenu.title}
                  </Typography>
                </Grid>
                {Object.values(catMenu.data).map((val: any) => {
                  return (
                    <Grid
                      key={val.catId}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      item
                      style={{ paddingLeft: "52px", marginTop: "12px" }}
                    >
                      <FormControlLabel
                        checked={
                          !_.isEmpty(applicableList)
                            ? applicableList.includes(val.catId)
                            : false
                        }
                        value={val.catId}
                        name={val.catId}
                        onChange={handleApplyLToList}
                        control={<Checkbox />}
                        label={
                          <>
                            <Typography variant="body2" color="textSecondary">
                              {val.title}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {getNumberOfItems(val.data)}
                            </Typography>
                          </>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ),
          )
        ) : (
          <div style={{ margin: "auto", marginTop: "25px" }}>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <LocalDiningIcon className={classes.diningIcon} />
            </div>
            <div style={{ marginTop: "5px" }}>
              <Typography
                style={{ textAlign: "center" }}
                variant="h5"
                color="textSecondary"
              >
                This discount will be applied to all items on your menu.
              </Typography>
            </div>
          </div>
        )}
        {!_.isEmpty(error) && (
          <Typography
            style={{ margin: "auto" }}
            gutterBottom
            color="error"
            variant="h6"
            component="div"
            className={classes.typographyError}
          >
            {error}
          </Typography>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={handleDisableButton()}
            onClick={handleSubmit}
            color="secondary"
            autoFocus
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApplyToConditionSelectionModal;
