// import { jsPDF } from "jspdf";
// import "jspdf-autotable";
// import _ from "lodash";
// import moment from "moment";
// import eatprestoLogo from "../../../../../assets/images/logos/logo.png";
// import { ExportPdfColor } from "../../../../../root/theme/exportColor";

// const handleConvertPdf = (doc: any, filterDetails: any) => {
//   const locationFilter = filterDetails.split("*")[0];
//   const pageWidth = doc.internal.pageSize.getWidth();
//   /* Create a pdf file. */
//   doc.autoTable({
//     // Add topic and sub topics.
//     didDrawPage: function (data: any) {
//       // Change topic font size
//       doc.setFontSize(12);
//       doc.text("Presto Express Solutions", data.settings.margin.left, 12);
//       // Modify the marginLeft according to the scape.
//       doc.text(
//         "Driver Sessions Summary Report",
//         data.settings.margin.left + 120,
//         12,
//       );
//       // Change main title font size
//       doc.setFontSize(16);
//       // Insert a topic for the first page only.
//       if (doc.internal.getNumberOfPages() === 1) {
//         /* Change the title according to the number of locations.
//         If the location size is increased, show presto express. if the location size is the one, show that location. */
//         doc.setTextColor("#32363E");
//         if (locationFilter.includes(",")) {
//         } else {
//           doc.text(
//             filterDetails
//               .split("*")[0]
//               .replace("Driver Sessions summary report for location/s:", ""),
//             pageWidth / 2,
//             36,
//             {
//               align: "center",
//             },
//           );
//         }

//         doc.setTextColor(0, 0, 0);
//         doc.text("Driver Sessions Summary Report", pageWidth / 2, 56, "center");
//         doc.setFontSize(10);

//         /* Change the sub title according to the number of locations.
//         If the location size is increased, show number of locations. if the location size is the one, show that location. */
//         let filterLocationsDetails = "";
//         if (locationFilter.split(",").length === 1) {
//           filterLocationsDetails =
//             "Driver Sessions Summary Report for " +
//             locationFilter.split(",")[0];
//         } else {
//           filterLocationsDetails =
//             "Driver Sessions Summary Report for " +
//             locationFilter.split(",").length +
//             " Locations";
//         }

//         doc.text(
//           doc.splitTextToSize(filterLocationsDetails, 210 - 15 - 15),
//           pageWidth / 2,
//           44,
//           {
//             align: "center",
//           },
//         );
//       }
//     },
//     margin: {
//       top: 30,
//       bottom: 30,
//     },
//   });
// };

// const handleTable = (
//   doc: any,
//   pdfHeader: any,
//   nodes: any,
//   startY: any,
//   lastIndex: any,
// ) => {
//   const columnLength: number = pdfHeader.length;
//   const pageWidth = doc.internal.pageSize.getWidth();
//   const totalPagesExp = "{total_pages_count_string}";

//   /* Create a new table. */
//   doc.autoTable({
//     columns: pdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
//     body: nodes,
//     startY: startY,
//     styles: {
//       align: "center",
//       fontSize: 8,
//       valign: "middle",
//       halign: "center",
//       borderRadius: "10px",
//     },
//     headStyles: {
//       fillColor: [ExportPdfColor.HeaderRowColor],
//       textColor: ["#ffffff"],
//       fontSize: 7,
//       valign: "middle",
//       halign: "center",
//       fontStyle: "bold",
//     },
//     columnStyles: {
//       0: {
//         halign: "left",
//         columnWidth: (pageWidth - 28.2) / columnLength,
//       },
//       1: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
//       2: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
//       3: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
//       4: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
//       5: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
//       6: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
//     },
//     alternateRowStyles: {
//       fillColor: [ExportPdfColor.OddRowColor],
//       textColor: ["#ffffff"],
//     },
//     tableLineWidth: 0.1,

//     /* Change the background color, font weight and alignment in the row. */
//     didParseCell: function (data: any, index: any) {
//       const rows = data.table.body;

//       if (data.section === "head") {
//         if (data.column.index === 0) {
//           data.cell.styles.halign = "left";
//         }
//       }
//       if (data.column.index === 5) {
//         data.cell.styles.halign = "right";
//       }
//       if (data.row.index !== 0) {
//         if (data.row.index % 2 === 0) {
//           data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
//           data.cell.styles.textColor = ["#ffffff"];
//         } else {
//           data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
//           data.cell.styles.textColor = ["#ffffff"];
//         }
//       }
//     },
//     didDrawPage: function (data: any) {
//       let pageNumber = "Page " + doc.internal.getNumberOfPages();

//       /* If there is a image, insert it into the eatprestoLogoImg variable. */
//       const eatprestoLogoImg = new Image();
//       eatprestoLogoImg.src = eatprestoLogo;
//       /* Set page number */
//       let pageSize = doc.internal.pageSize;
//       const pageHeight = pageSize.height
//         ? pageSize.height
//         : pageSize.getHeight();

//       // Total page number plugin only available in jspdf v1.0+
//       if (typeof doc.putTotalPages === "function") {
//         pageNumber = pageNumber + " of " + totalPagesExp;
//       }
//       doc.setFontSize(10);
//       doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);

//       doc.addImage(
//         eatprestoLogoImg,
//         "JPEG",
//         data.settings.margin.left + 144,
//         pageHeight - 24,
//         40,
//         16,
//       );
//     },
//     margin: {
//       top: 30,
//       bottom: 30,
//     },
//   });

//   if (typeof doc.putTotalPages === "function") {
//     doc.putTotalPages(totalPagesExp);
//   }
// };

// /* Create a pdf file */
// export const handlePdf = (filterDetails: any, pdfHeader: any, nodes: any) => {
//   const doc: any = new jsPDF("p");

//   const today = moment().format("MMM_Do_YY").toString();

//   /* If there is a image, insert it into the eatprestoLogoImg variable. */
//   const eatprestoLogoImg = new Image();
//   eatprestoLogoImg.src = eatprestoLogo;

//   let startY = 68;
//   // Box settings

//   /* Iterating table data */
//   nodes.map((driverDetails: any) => {
//     // Get the number of columns in the table
//     let lastIndex = (driverDetails?.orders?.length || 0) - 1;

//     // If the height of the previous table is greater than 240, a new page is added.
//     if (startY > 240) {
//       doc.addPage();
//       startY = 30;
//     }

//     handleConvertPdf(doc, filterDetails);

//     doc.setFontSize(12);

//     const startX = 14; // X position for the first box
//     const boxWidth = 41.6 // Width of each box
//     const boxHeight = 20; // Height of each box

//     const textOffsetY = startY + boxHeight / 2 - 5; // Vertical center offset for labels
// const valueOffsetY = textOffsetY + 7;

//     let currentX = startX + 0 * boxWidth; // Calculate the X position for each box

//     // Set fill color to yellow
//     doc.setFillColor(255, 255, 0);

//     // Draw the box (rectangle)
//     doc.rect(currentX, startY, boxWidth, boxHeight, "F"); // 'F' means filled

//     // Set text color to black
//     doc.setTextColor(0, 0, 0);

//     // Add the text inside the box (centered horizontally)
//     doc.text("Customer Name", currentX + boxWidth / 2, textOffsetY, {
//       align: "center",
//     });
//     doc.text(
//       driverDetails.cmsDriverName,
//       currentX + boxWidth / 2,
//       valueOffsetY,
//       { align: "center" },
//     );

//      currentX = (startX + 1 * boxWidth) +5; // Calculate the X position for each box

//     // Set fill color to yellow
//     doc.setFillColor(255, 255, 0);

//     // Draw the box (rectangle)
//     doc.rect(currentX, startY, boxWidth, boxHeight, "F"); // 'F' means filled

//     // Set text color to black
//     doc.setTextColor(0, 0, 0);

//     // Add the text inside the box (centered horizontally)
//     doc.text("Fixed Fee", currentX + boxWidth / 2, textOffsetY, {
//       align: "center",
//     });
//     doc.text(
//       driverDetails.fixedFee.toString(),
//       currentX + boxWidth / 2,
//       valueOffsetY,
//       { align: "center" },
//     );

//     currentX = (startX + 2 * boxWidth) +10; // Calculate the X position for each box

//     // Set fill color to yellow
//     doc.setFillColor(255, 255, 0);

//     // Draw the box (rectangle)
//     doc.rect(currentX, startY, boxWidth, boxHeight, "F"); // 'F' means filled

//     // Set text color to black
//     doc.setTextColor(0, 0, 0);

//     // Add the text inside the box (centered horizontally)
//     doc.text("Milage Fee", currentX + boxWidth / 2, textOffsetY, {
//       align: "center",
//     });
//     doc.text(
//       driverDetails.milageFee.toString(),
//       currentX + boxWidth / 2,
//       valueOffsetY,
//       { align: "center" },
//     );

//     currentX = (startX + 3 * boxWidth) +15; // Calculate the X position for each box

//     // Set fill color to yellow
//     doc.setFillColor(255, 255, 0);

//     // Draw the box (rectangle)
//     doc.rect(currentX, startY, boxWidth, boxHeight, "F"); // 'F' means filled

//     // Set text color to black
//     doc.setTextColor(0, 0, 0);

//     // Add the text inside the box (centered horizontally)
//     doc.text("Total Fee", currentX + boxWidth / 2, textOffsetY, {
//       align: "center",
//     });
//     doc.text(
//       ` :- ${(
//         parseFloat(driverDetails.fixedFee) + parseFloat(driverDetails.milageFee)
//       ).toString()}`,
//       currentX + boxWidth / 2,
//       valueOffsetY,
//       { align: "center" },
//     );

//     startY = startY + 16;

//     // doc.setFont("helvetica", "bold");
//     // doc.text("Total Fee", 14, startY);
//     // doc.setFont("helvetica", "normal");
//     // doc.text(
//     //   ` :- ${(
//     //     parseFloat(driverDetails.fixedFee) + parseFloat(driverDetails.milageFee)
//     //   ).toString()}`,
//     //   48,
//     //   startY,
//     // );
//     doc.setFontSize(10);
//     {
//       driverDetails?.orders?.length > 0 &&
//         handleTable(
//           doc,
//           pdfHeader,
//           driverDetails.orders,
//           startY + 8,
//           lastIndex,
//         );
//     }
//     startY = doc.lastAutoTable.finalY + 13;
//   });
//   // Object.entries(nodes).forEach(([key, value]: any) => {
//   //   if (!key.includes("_tableName") && !_.isEmpty(value)) {
//   //     // Get the number of columns in the table
//   //     let lastIndex = Object.values(value[0]).length - 1;
//   //     if (key === "paid-in-out") {
//   //       lastIndex = lastIndex - 1;
//   //     }
//   //     // If the height of the previous table is greater than 240, a new page is added.
//   //     if (startY > 240) {
//   //       doc.addPage();
//   //       startY = 30;
//   //     }

//   //     const { nodeLength } = handleNodeAndFooterLength(key, nodes);
//   //     handleConvertPdf(doc, filterDetails);
//   //     doc.text(nodes[`${key}_tableName`], 14, startY);
//   //     handleTable(
//   //       key,
//   //       doc,
//   //       pdfHeader[key],
//   //       value,
//   //       startY + 8,
//   //       lastIndex,
//   //       nodeLength,
//   //     );
//   //     startY = doc.lastAutoTable.finalY + 13;
//   //   }
//   // });

//   doc.save(
//     `Driver_Sessions_report_${today}_${Math.floor(
//       100000 + Math.random() * 900000,
//     )}.pdf`,
//   );
// };

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import moment from "moment";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";
import { ExportPdfColor } from "../../../../../root/theme/exportColor";
import {
  convertDateFormat,
  convertDateTimeFormatInventory,
} from "../../../../../utils/ConvertDateTimeFormat";

const handleConvertPdf = (doc: any, filterData: any, count: any) => {
  const locationFilter = filterData.split("*")[1];
  const pageWidth = doc.internal.pageSize.getWidth();
  /* Create a pdf file. */
  doc.autoTable({
    // Add topic and sub topics.
    didDrawPage: function (data: any) {
      // Set larger font size for main title
      doc.setFontSize(16);

      // Insert main title on the first page
      if (doc.internal.getNumberOfPages() === 1) {
        /* Change the title according to the number of locations.
        If the location size is increased, show presto express. if the location size is the one, show that location. */
        doc.setTextColor("#32363E");
        if (locationFilter.includes(",")) {
        }

        doc.setTextColor(0, 0, 0);
        doc.text("Driver Session Summary Report", pageWidth / 2, 36, "center");
        doc.setFontSize(10);

        /* Change the sub title according to the number of locations.
        If the location size is increased, show number of locations. if the location size is the one, show that location. */
        // filterLocationsDetails =
        //   "Driver Session Summary Report for " + locationId;

        doc.text(
          doc.splitTextToSize(
            `Driver Session Summary Report for ${filterData.split("*")[0]}`,
            210 - 15 - 15,
          ),
          pageWidth / 2,
          44,
          {
            align: "center",
          },
        );

        /* Set the start date and end date */
        doc.text(
          doc.splitTextToSize(
            "Generated for the period of " + filterData.split("*")[1],
            210 - 15 - 15,
          ),
          pageWidth / 2,
          50,
          {
            align: "center",
          },
        );
        doc.setFontSize(16);
        doc.text(
          doc.splitTextToSize(filterData.split("*")[0], 210 - 15 - 15),
          pageWidth / 2,
          26,
          {
            align: "center",
          },
        );
      }
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });
};

const handleLogTable = (doc: any, pdfHeader: any, nodes: any, startY: any) => {
  const columnLength: number = pdfHeader.length;
  const pageWidth = doc.internal.pageSize.getWidth();
  const totalPagesExp = "{total_pages_count_string}";

  /* Create a new table. */
  doc.autoTable({
    columns: pdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    startY: startY,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
      fontStyle: "bold",
    },
    columnStyles: {
      0: {
        halign: "left",
        columnWidth: (pageWidth - 28.2) / columnLength,
      },
      1: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      2: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      3: { halign: "right", columnWidth: (pageWidth - 28.2) / columnLength },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColor.OddRowColor],
      textColor: ["#ffffff"],
    },
    tableLineWidth: 0.1,

    /* Change the background color, font weight and alignment in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
        }
      }
      if (data.column.index === 3) {
        data.cell.styles.halign = "center";
      }
      if (data.row.index !== 0) {
        if (data.row.index % 2 === 0) {
          data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
          data.cell.styles.textColor = ["#ffffff"];
        } else {
          data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
          data.cell.styles.textColor = ["#ffffff"];
        }
      }
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
};

const handleTable = (
  doc: any,
  pdfHeader: any,
  nodes: any,
  startY: any,
  lastIndex: any,
  filterData: any,
  locationId: any,
) => {
  let isDrop = false;
  const clonePdfHeader = _.cloneDeep(pdfHeader);
  nodes.map((data: any) => {
    if (data?.isRedrop) {
      isDrop = true;
    }
  });

  if (isDrop) {
    clonePdfHeader.push({ field: "comments", title: "Comments" });
  }
  const columnLength: number = clonePdfHeader.length;
  const pageWidth = doc.internal.pageSize.getWidth();
  const totalPagesExp = "{total_pages_count_string}";
  /* Create a new table. */
  doc.autoTable({
    columns: clonePdfHeader.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes,
    startY: startY,
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColor.HeaderRowColor],
      textColor: ["#ffffff"],
      fontSize: 7,
      valign: "middle",
      halign: "center",
      fontStyle: "bold",
    },
    columnStyles: {
      0: {
        halign: "left",
        columnWidth: (pageWidth - 28.2) / columnLength,
      },
      1: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      2: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      3: { halign: "center", columnWidth: (pageWidth - 28.2) / columnLength },
      4: {
        halign: isDrop ? "center" : "right",
        columnWidth: (pageWidth - 28.2) / columnLength,
      },
      5: {
        halign: isDrop ? "right" : "center",
        columnWidth: (pageWidth - 28.2) / columnLength,
      },
    },
    // alternateRowStyles: {
    //   fillColor: [ExportPdfColor.OddRowColor],
    //   textColor: ["#ffffff"],
    // },
    tableLineWidth: 0.1,

    /* Change the background color, font weight and alignment in the row. */
    didParseCell: function (data: any, index: any) {
      const rows = data.table.body;

      if (data.section === "head") {
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
        }
      }
      if (data.column.index === (isDrop ? 5 : 4)) {
        data.cell.styles.halign = "right";
      }

      if (data.section !== "head") {
        if (rows[0].raw.isRedrop) {
          data.cell.styles.fillColor = [ExportPdfColor.red];
          data.cell.styles.textColor = ["#ffffff"];
        } else {
          if (data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [ExportPdfColor.OddRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          } else {
            data.cell.styles.fillColor = [ExportPdfColor.EvenRowColor];
            data.cell.styles.textColor = ["#ffffff"];
          }
        }
      }
    },
    didDrawPage: function (data: any) {
      // Set font size for topic and subtopics
      doc.setFontSize(12);
      doc.text("Presto Express Solutions", data.settings.margin.left, 12);

      doc.text(
        "Driver Sessions Summary Report",
        data.settings.margin.left + 115,
        12,
      );

      let pageNumber = "Page " + doc.internal.getNumberOfPages();

      /* If there is a image, insert it into the eatprestoLogoImg variable. */
      const eatprestoLogoImg = new Image();
      eatprestoLogoImg.src = eatprestoLogo;
      /* Set page number */
      let pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);

      doc.addImage(
        eatprestoLogoImg,
        "JPEG",
        data.settings.margin.left + 144,
        pageHeight - 24,
        40,
        16,
      );
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
};

/* Create a pdf file */
export const handlePdf = (
  pdfHeader: any,
  nodes: any,
  filterData: any,
  totalBox: any,
  totalOrders: any,
) => {
  const doc: any = new jsPDF("p");
  const today = moment().format("MMM_Do_YY").toString();

  /* If there is a image, insert it into the eatprestoLogoImg variable. */
  const eatprestoLogoImg = new Image();
  eatprestoLogoImg.src = eatprestoLogo;
  const pageWidth = doc.internal.pageSize.getWidth();

  let startY = 68;

  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text(
    `CASH COLLECT: ${parseFloat(totalBox.cashCollect).toFixed(2)}`,
    14,
    startY,
  );
  startY = startY + 8;
  doc.text(
    `DELIVERY WAGE: ${parseFloat(totalBox.deliveryWage).toFixed(2)}`,
    14,
    startY,
  );
  startY = startY + 8;
  doc.text(
    `DAILY WAGE: ${parseFloat(totalBox.dailyWage).toFixed(2)}`,
    14,
    startY,
  );
  doc.text(
    `ADJUSTMENTS: ${parseFloat(totalBox.adjustment).toFixed(2)}`,
    pageWidth - 15, // Set the x-position for right alignment with a margin of 15
    startY,
    { align: "right" }, // Align the text to the right
  );
  startY = startY + 8;
  doc.text(
    `TOTAL WAGE: ${parseFloat(totalBox.totalWage).toFixed(2)}`,
    14,
    startY,
  );
  startY = startY + 8;
  doc.text(`ORDERS: ${totalBox.totalOrders}`, 14, startY);

  startY = startY + 8;
  doc.text(`RE-DROPS: ${totalBox.totalReDrops}`, 14, startY);

  startY = startY + 8;
  doc.text(
    `TOTAL: ${totalBox.totalOrders + totalBox.totalReDrops}`,
    14,
    startY,
  );
  startY = startY + 16;

  // Box settings
  let count = 0;
  /* Iterating table data */
  nodes.map((driverDetails: any, index: any) => {
    if (
      driverDetails?.orders?.length > 0 &&
      driverDetails?.cmsDriverName !== "ALL"
    ) {
      // Get the number of columns in the table
      let lastIndex = (driverDetails?.orders?.length || 0) - 1;

      // If the height of the previous table is greater than 240, a new page is added.
      if (startY > 240) {
        doc.addPage();
        startY = 30;
      }
      const pageWidth = doc.internal.pageSize.getWidth();
      handleConvertPdf(doc, filterData, count);

      count = count + 1;
      doc.setFontSize(20);
      doc.setFont("helvetica", "bold");

      doc.setFontSize(16);

      doc.text(driverDetails?.cmsDriverName, 14, startY);

      startY = startY + 12;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.text("Shift Start", 14, startY);
      doc.text("Shift End", pageWidth - 14, startY, { align: "right" });

      doc.setFont("helvetica", "bold");

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.text(convertDateTimeFormatInventory(driverDetails.start), 14, startY);
      doc.text(
        convertDateTimeFormatInventory(driverDetails.end),
        pageWidth - (driverDetails.end ? 11 : 14),
        startY,
        { align: "right" },
      );

      doc.setFont("helvetica", "normal");

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.text(
        `Logged Time: ${
          driverDetails.startRecorded
            ? convertDateTimeFormatInventory(driverDetails.startRecorded)
            : "-"
        }`,
        14,
        startY,
      );
      doc.text(
        `Logged Time: ${
          driverDetails.endRecorded
            ? convertDateTimeFormatInventory(driverDetails.endRecorded)
            : "-"
        }`,
        pageWidth - (driverDetails.endRecorded ? 11 : 14),
        startY,
        { align: "right" },
      );

      let counts = false;
      const logTableBody: any = [];
      driverDetails &&
        driverDetails?.log &&
        driverDetails?.log?.length > 0 &&
        driverDetails.log.map((logInfo: any) => {
          const cloneLogInfo = _.cloneDeep(logInfo);
          if (
            cloneLogInfo.type === "Clock Out" ||
            cloneLogInfo.type === "Clock In"
          ) {
            doc.setTextColor(ExportPdfColor.red);
            doc.setFont("helvetica", "bold");
            if (!counts) {
              startY = startY + 8;
              counts = true;
              if (startY > 260) {
                doc.addPage();
                startY = 30;
              }
            }

            if (cloneLogInfo.type === "Clock In") {
              doc.text(cloneLogInfo.notes, 14, startY);
            } else {
              doc.text(cloneLogInfo.notes, pageWidth - 14, startY, {
                align: "right",
              });
            }
          } else {
            cloneLogInfo["date"] = convertDateTimeFormatInventory(
              cloneLogInfo.date,
            );
            logTableBody.push(cloneLogInfo);
          }
        });

      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");

      if (logTableBody.length > 0) {
        startY = startY + 8;
        if (startY > 260) {
          doc.addPage();
          startY = 30;
        }
        const header = [
          { title: "Type", field: "type" },
          { title: "Amount", field: "amount" },
          { title: "Notes", field: "notes" },
          { title: "Date", field: "date" },
        ];

        handleLogTable(doc, header, logTableBody, startY);
        startY = doc.lastAutoTable.finalY + 8;
      }
      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.text(convertDateTimeFormatInventory(driverDetails.start), 14, startY);
      doc.text(
        convertDateTimeFormatInventory(driverDetails.end),
        pageWidth - (driverDetails.end ? 11 : 14),
        startY,
        { align: "right" },
      );

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.setFont("helvetica", "bold");
      doc.text("Cash Collect", 14, startY);
      doc.setFont("helvetica", "normal");
      doc.text(` : £ ${driverDetails.cashCollect.toString()}`, 48, startY);

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.setFont("helvetica", "bold");
      doc.text("Delivery Wage", 14, startY);
      doc.setFont("helvetica", "normal");
      doc.text(` : £ ${driverDetails.milageFee.toString()}`, 48, startY);

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.setFont("helvetica", "bold");
      doc.text("Daily Wage", 14, startY);
      doc.setFont("helvetica", "normal");
      doc.text(
        ` : £ ${(
          parseFloat(driverDetails.fixedFee) +
          parseFloat(driverDetails.adjustments)
        ).toFixed(2)}`,
        48,
        startY,
      );

      doc.text(
        `${
          parseFloat(driverDetails.adjustments).toFixed(0) !== "0"
            ? `Adjustments: ${parseFloat(driverDetails.adjustments).toFixed(2)}`
            : ""
        }`,
        pageWidth - 14,
        startY,
        {
          align: "right",
        },
      );

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.setFont("helvetica", "bold");
      doc.text("Total Wage", 14, startY);
      doc.setFont("helvetica", "normal");
      doc.text(
        ` : £ ${parseFloat(
          (
            parseFloat(driverDetails.fixedFee) +
            parseFloat(driverDetails.milageFee) +
            parseFloat(driverDetails.adjustments)
          ).toString(),
        ).toFixed(2)}`,
        48,
        startY,
      );

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.setFont("helvetica", "bold");
      doc.text("ORDERS", 14, startY);
      doc.setFont("helvetica", "normal");
      doc.text(
        `: ${
          totalOrders[
            `${index + 1}_${driverDetails.cmsDriverName}_count_orders`
          ]
        }`,

        49,
        startY,
      );

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.setFont("helvetica", "bold");
      doc.text("RE-DROPS", 14, startY);
      doc.setFont("helvetica", "normal");
      doc.text(
        `: ${
          totalOrders[
            `${index + 1}_${driverDetails.cmsDriverName}_count_orders_redrops`
          ]
        }`,

        49,
        startY,
      );

      startY = startY + 8;
      if (startY > 260) {
        doc.addPage();
        startY = 30;
      }
      doc.setFont("helvetica", "bold");
      doc.text("TOTAL", 14, startY);
      doc.setFont("helvetica", "normal");
      doc.text(
        `: ${
          totalOrders[
            `${index + 1}_${driverDetails.cmsDriverName}_count_orders`
          ] +
          totalOrders[
            `${index + 1}_${driverDetails.cmsDriverName}_count_orders_redrops`
          ]
        } `,

        49,
        startY,
      );

      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      handleTable(
        doc,
        pdfHeader,
        driverDetails.orders,
        startY + 8,
        lastIndex,
        filterData,
        filterData.split("*")[1],
      );
      startY = doc.lastAutoTable.finalY + 13;
    }
  });
  // Object.entries(nodes).forEach(([key, value]: any) => {
  //   if (!key.includes("_tableName") && !_.isEmpty(value)) {
  //     // Get the number of columns in the table
  //     let lastIndex = Object.values(value[0]).length - 1;
  //     if (key === "paid-in-out") {
  //       lastIndex = lastIndex - 1;
  //     }
  //     // If the height of the previous table is greater than 240, a new page is added.
  //     if (startY > 240) {
  //       doc.addPage();
  //       startY = 30;
  //     }

  //     const { nodeLength } = handleNodeAndFooterLength(key, nodes);
  //     handleConvertPdf(doc, filterDetails);
  //     doc.text(nodes[`${key}_tableName`], 14, startY);
  //     handleTable(
  //       key,
  //       doc,
  //       pdfHeader[key],
  //       value,
  //       startY + 8,
  //       lastIndex,
  //       nodeLength,
  //     );
  //     startY = doc.lastAutoTable.finalY + 13;
  //   }
  // });

  doc.save(
    `Driver_Sessions_report_${today}_${Math.floor(
      100000 + Math.random() * 900000,
    )}.pdf`,
  );
};
