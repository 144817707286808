import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import { useLocation, useRouteMatch } from "react-router";
import _ from "lodash";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import {
  fetchAllCancelSalesInfo,
  fetchAllUserInfo,
} from "../../../../services/salesApp/salesService";
import Pagination from "../../../../components/common/Pagination";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { saleListFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import SaleList from "../../../../components/common/SaleList";
import { CustomTheme } from "../../../../types/customTheme";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import SalesReportInfoNode from "./SaleCategoryReportInfoNode";
import { fetchAllDiscountInfoSales } from "../../../../services/globalService";

/**
 * SalesReport Component:
 *
 * This component is responsible for displaying sales-related information and interactions,
 * including filtering, sorting, pagination, and error handling. It fetches data from the
 * backend APIs and renders the sales report and list accordingly. The component integrates
 * with user authentication to control access based on user permissions.
 */
const SalesReport: React.FunctionComponent<{}> = () => {
  const [salesNodeList, setSalesNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("date");
  const [sortingMethod, setSortingMethod] = useState("DESC");
  const [disableButton, setDisableButton] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [userList, setUserList] = useState<any>();
  const [userListFilter, setUserListFilter] = useState<any>([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [discountInfo, setDiscountInfo] = useState<any>({});
  const [isPaymentTypeLoading, setIsPaymentTypeLoading] = useState(false);
  const [isDiscountLoading, setIsDiscountLoading] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [isSort, setIsSort] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [isActiveUserSwitch, setIsActiveUserSwitch] = useState(false);
  const [isCategoryError, setIsCategoryError] = useState(false);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");
  const { search } = useLocation();
  const params = new URLSearchParams(search);


  /* Get sale list using query params */
  const getSaleFilterInfo = async (
    sortingMethod: any,
    headerName: any,
    filter: any,
  ) => {
    try {
      const res = await fetchAllCancelSalesInfo(
        idToken,
        match.params.locationId,
        200000,
        1,
        sortingMethod,
        headerName,
        filter,
      );
      setSalesNodeList(res.data.data);
      setTotalPages(res.data.totalPages);
      setTotalResult(res.data.totalResults);
      setIsLoadingPage(false);
      setIsPaymentTypeLoading(false);
      setIsDiscountLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingPage(false);
      setIsPaymentTypeLoading(false);
      setIsDiscountLoading(false);
    }
    setIsOpenSkeletonLoading(false);
  };

  /* The user details required for the filter and the user details required to be 
    displayed in the table are entered into the state. */
  const handleUserList = (userDetails: any) => {
    const userArray: any = [];
    const userObject: any = {};
    // Used the filter
    Object.values(userDetails).map((data: any) => {
      userArray.push({ id: data.id, label: data.name });
      if (!_.isEmpty(userList)) {
        if (_.isEmpty(userList[data.id])) {
          userObject[data.id] = { id: data.id, label: data.name };
        }
      } else {
        userObject[data.id] = { id: data.id, label: data.name };
      }
    });
    setUserListFilter(userArray);

    // Used the table
    // var idList = new Set(userList.map((data: any) => data.id));
    // var merged = [
    //   ...userList,
    //   ...userArray.filter((data: any) => !idList.has(data.id)),
    // ];
    // if (!_.isEmpty(userArray)) {
    //   setUserList(merged);
    // }
    setUserList((prevState: any) => ({ ...prevState, ...userObject }));
  };

  /* Get user details list */
  const getUserInfo = async (locationId: any) => {
    try {
      const res = await fetchAllUserInfo(idToken, locationId);
      if (!_.isEmpty(res.data.data)) {
        handleUserList(res.data.data);
      } else {
        setUserListFilter([]);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get location details. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        const locationList: any = [];
        if (!_.isEmpty(res.data.data)) {
          /* Setting up the list of locations as needed to select locations. */
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setIsLoadingPage(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  const getAllDiscountInfo =  async () => {
    fetchAllDiscountInfoSales(match.params.locationId)
      .then((res) => {
        setDiscountInfo(res.data.data)
    getAllFilterLocation("");
        
      })
      .catch(() => {
        setIsLoadingPage(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Sale Report";
    setIsLoadingPage(true);
    getAllDiscountInfo();
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any) => {
    setFilterData(filterData);
    setIsOpenSkeletonLoading(true);
    getSaleFilterInfo(sortingMethod, headerName, filterData);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /* Get location list API call after typing. */
  const handleLocationSelectorTypingList = () => {};

  return (
    <>
      <SaleReport
        handleFilterData={handleFilterData}
        locationSelectorList={locationSelectorList}
        handleLocationSelectorTypingList={handleLocationSelectorTypingList}
        isGetLocationInfo={isGetLocationInfo}
        getUserInfo={getUserInfo}
        userListFilter={userListFilter}
        setFilterDetails={setFilterDetails}
        availableFilter={saleListFilterObject}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        nodeList={salesNodeList}
        filterDetails={filterDetails}
        title=""
        isChart={false}
        isUserGet
        isSale
        locationSelectedList={locationSelectedList}
        setLocationSelectedList={setLocationSelectedList}
        openFilterCard={openFilterCard}
        setOpenFilterCard={setOpenFilterCard}
        handleOnClickText={handleOnClickText}
        topic="Sales Report"
        isReport={false}
      >
        {!isOpenSkeletonLoading && (
          <SalesReportInfoNode
            filterDetails={filterDetails}
            locationSelectedList={locationSelectedList}
            nodeList={salesNodeList}
            handleOnClickText={handleOnClickText}
            userList={userList}
            isActiveUserSwitch={isActiveUserSwitch}
            setIsActiveUserSwitch={setIsActiveUserSwitch}
            setIsOpenSkeletonLoading={setIsOpenSkeletonLoading}
            isCategoryError={isCategoryError}
            nodes={nodes}
            setNodes={setNodes}
            discountInfo={discountInfo}
          />
        )}
      </SaleReport>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(SalesReport, Authorities.SALE_READ);
