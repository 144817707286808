import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import "jspdf-autotable";
import { Icon } from "@iconify/react";
import { useTree } from "@table-library/react-table-library/tree";

import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import { CustomTheme } from "../../../../types/customTheme";

import {
  checkIncludedValue,
  sortNumbers,
  sortStrings,
} from "../../../../utils/commonArrayMap";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

export interface SaleCategoryReportInfoNodeProps {
  itemsReportNodeList: any;
  filterDetails: any;
  locationSelectedList: any;
  handleOnClickText: any;
}

const useStyles = (nodesHeadersLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        color: theme.palette.custom.blue.contrastText,
        justifyContent: "start",
        paddingLeft: "16px",
      },
      startNotNodeTextStyle: {
        fontWeight: "bold",
        display: "flex",
        color: theme.palette.text.primary,
        justifyContent: "start",
        paddingLeft: "16px",
      },
      startHeaderTextStyle: {
        fontWeight: "bold",
        fontSize: "12px",
        display: "flex",
        color: theme.palette.custom.orange.contrastText,
        justifyContent: "start",
        paddingLeft: "16px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "12px",
        color: theme.palette.custom.orange.contrastText,
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      },
      textStyleFooter: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "12px",
        color: "white",
      },
      finalTotalRow: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(n)`]: {
          color: theme.palette.custom.green.contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.entity_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      mainRow: {
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(n)": {
          color: theme.palette.custom.blue.contrastText,
          backgroundColor: theme.palette.custom.blue.main,
          "&:hover": {
            backgroundColor: theme.palette.custom.blue.light,
          },
        },
      },
      normalRow: {
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRowEnd: {
        placeItems: "center",
        fontSize: "12px",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        [`&:nth-of-type(-n+${nodesHeadersLength})`]: {
          minWidth: `${80 / 5}%`,
          width: `${80 / 5}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
          },
        },
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "20%",
          width: "20%",
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(-n+${nodesHeadersLength})`]: {
            minWidth: `${20}%`,
            width: `${20}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
            },
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(-n+${nodesHeadersLength})`]: {
            minWidth: `${32}%`,
            width: `${32}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "36%",
            width: "36%",
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        fontSize: "12px",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
          minWidth: `${80 / 5}%`,
          width: `${80 / 5}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(${nodesHeadersLength})`]: {
          minWidth: `${80 / 5}%`,
          width: `${80 / 5}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
            paddingRight: "12px",
          },
        },
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "20%",
          width: "20%",
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: `${20}%`,
            width: `${20}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: `${20}%`,
            width: `${20}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: `${32}%`,
            width: `${32}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: `${32}%`,
            width: `${32}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "36%",
            width: "36%",
          },
        },
      },
      baseCellRowChangeColor: {
        fontSize: "12px",
        "&.td:nth-of-type(n)": {
          color: theme.palette.custom["purple"].contrastText,
          backgroundColor: theme.palette.custom.purple.main,
        },
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
      },
    }),
  );

/**
 * SaleCategoryReportInfoNode Component
 *
 * Component for rendering a sale category report information node.
 * This component comprises a grid layout with location details, search functionality,
 * and export options. It also includes a table to display sale category report data,
 * with various columns like item name, amount, quantity, discount, VAT percent, and VAT amount.
 * Each row in the table represents a specific item, and the footer row displays total values.
 * The component utilizes various Material-UI components like Grid, Card, Table, Typography,
 * Button, TextField, Menu, MenuItem, InputAdornment, etc., along with custom styles.
 * It includes event handlers for searching, exporting data as PDF or CSV, and clicking on items
 * to open them in a new window.
 * Additionally, it contains a useEffect hook to reset the nodes state when the "startDate"
 * parameter changes, ensuring that the component fetches new data based on the updated start date.
 */
const SaleCategoryReportInfoNode: React.FunctionComponent<
  SaleCategoryReportInfoNodeProps
> = ({
  itemsReportNodeList,
  filterDetails,
  locationSelectedList,
  handleOnClickText,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesInitial, setNodesInitial] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [nodesPdfExcelInitial, setNodesPdfExcelInitial] = useState<any>([]);
  const [footer, setFooter] = useState<any>([]);
  const [footerInitial, setFooterInitial] = useState<any>([]);
  const [bodyLength, setBodyLength] = useState(0);
  const [bodyLengthInitial, setBodyLengthInitial] = useState(0);
  const [nodesHeadersLength, setNodesHeadersLength] = useState(6);
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2600px)");
  const params = new URLSearchParams(search);

  /**
   * This useEffect hook is responsible for resetting the state of "nodes" to an empty array
   * whenever the value of the "startDate" parameter changes. It ensures that the component
   * fetches new data based on the updated start date.
   */
  useEffect(() => {
    // Resetting the state of "nodes" to an empty array
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /**
   * This function handles checking for duplicate item names and duplicate VAT percents
   * within the provided nodes array.
   * @param {Array} nodes - The array of nodes containing items to be checked for duplicates.
   * @returns {Object} An object containing flags indicating whether there are duplicate
   * item names and duplicate VAT percents.
   */
  const handleDuplicateItem = (nodes: any) => {
    // Extracting the list of item names from the nodes array
    const itemNameList = nodes.map((item: any) => {
      return item.categoryName;
    });

    // Check for duplicate item names
    const isDuplicateItemName = itemNameList.some((item: any, idx: any) => {
      return itemNameList.indexOf(item) != idx;
    });

    // Extracting the list of VAT percents from the nodes array
    const valueArrVatPercent = nodes.map((item: any) => {
      return item.vatPercent;
    });

    // Check for duplicate VAT percents
    const isDuplicateVatPercent = valueArrVatPercent.some(
      (item: any, idx: any) => {
        return valueArrVatPercent.indexOf(item) != idx;
      },
    );

    // Returning an object containing flags for duplicate item names and VAT percents
    return {
      isDuplicateItemName,
      isDuplicateVatPercent,
    };
  };

  /**
   * This useEffect hook is responsible for processing the "itemsReportNodeList"
   * and generating the necessary data for rendering the table and creating a PDF.
   * It calculates totals, handles duplicate items, prepares data for display and PDF export,
   * and sets necessary state variables for the component.
   */
  useEffect(() => {
    // Deep clone of the itemsReportNodeList
    let cloneItemsReportNodeList: any = _.cloneDeep(itemsReportNodeList);
    let itemsReportNode: any = []; // Array to hold processed items for table display
    let pdfNodeList: any = []; // Array to hold processed items for PDF export

    let totalAmount = 0;
    let totalQty = 0;
    let totalDiscount = 0;
    let totalVatAmount = 0;

    // Process each key (group) in the itemsReportNodeList
    if (!_.isEmpty(cloneItemsReportNodeList)) {
      Object.keys(cloneItemsReportNodeList).map((key: any) => {
        if (cloneItemsReportNodeList[key].length === 1) {
          // If there is only one item with the same item name
          cloneItemsReportNodeList[key].map((data: any) => {
            // Processing single item data
            // Calculating vat
            let vat =
              data.vatPercent > 100 ? data.vatPercent / 1000 : data.vatPercent;

            // Pushing data for table and PDF export
            itemsReportNode.push({
              id: key,
              categoryName: data.categoryName,
              amount: parseFloat(data.amount).toFixed(2),
              qty: data.qty,
              discount: parseFloat(data.discount).toFixed(2),
              vatPercent: vat,
              vatAmount: parseFloat(data.vatAmount).toFixed(2),
            });

            pdfNodeList.push({
              id: key,
              categoryName: data.categoryName,
              amount: parseFloat(data.amount).toFixed(2),
              qty: data.qty,
              discount: parseFloat(data.discount).toFixed(2),
              vatPercent: vat,
              vatAmount: parseFloat(data.vatAmount).toFixed(2),
              nodes: "",
            });

            // Calculate total amount
            totalAmount = totalAmount + data.amount;
            // Calculate total quantity
            totalQty = totalQty + data.qty;
            // Calculate total discount
            totalDiscount = totalDiscount + data.discount;
            // Calculate total vat amount
            totalVatAmount = totalVatAmount + data.vatAmount;
          });
        } else {
          // If there are multiple items with the same item name
          let categoryName = "";
          let amount = 0;
          let qty = 0;
          let discount = 0;
          let joinedVatPercent = "";
          let vatPercent = "";
          let vatAmount = 0;
          let nodes: any = [];
          let pdfNodes: any = [];

          // Processing data for items with same name
          cloneItemsReportNodeList[key].map((data: any) => {
            // If the VAT percentage value is more than 100, it is divided by 1000.
            let vat =
              data.vatPercent > 100 ? data.vatPercent / 1000 : data.vatPercent;

            // Values ​​are entered into the variable only if there is an item name and the variable has no item name value.
            if (!_.isEmpty(data.categoryName) && _.isEmpty(categoryName)) {
              categoryName = data.categoryName;
            }

            // Entering the vat percent of item names with the same name into a variable.
            joinedVatPercent = Array.from(
              new Set([vat, vatPercent].join(",").split(",")),
            ).join(",");
            vatPercent = joinedVatPercent;

            // Calculating the total 'amount' of item names with the same name.
            amount = amount + data.amount;
            // Calculating the total 'qty' of item names with the same name.
            qty = qty + data.qty;
            // Calculating the total 'discount' of item names with the same name.
            discount = discount + data.discount;
            // Calculating the total 'vatAmount' of item names with the same name.
            vatAmount = vatAmount + data.vatAmount;

            /* Entering values ​​for the id, categoryName, amount, qty, discount, 
                 vatPercent, and vatAmount columns in the item report table.​​​​ */
            nodes.push({
              id: key,
              categoryName: data.categoryName,
              amount: parseFloat(data.amount).toFixed(2),
              qty: data.qty,
              discount: parseFloat(data.discount).toFixed(2),
              vatPercent: vat,
              vatAmount: parseFloat(data.vatAmount).toFixed(2),
            });

            /* Enter data required for PDF.​​​​ */
            pdfNodes.push({
              id: key,
              categoryName: data.categoryName,
              amount: parseFloat(data.amount).toFixed(2),
              qty: data.qty,
              discount: parseFloat(data.discount).toFixed(2),
              vatPercent: vat,
              vatAmount: parseFloat(data.vatAmount).toFixed(2),
              nodes: "sub",
            });

            // Calculate total amount
            totalAmount = totalAmount + data.amount;
            // Calculate total qty
            totalQty = totalQty + data.qty;
            // Calculate total discount
            totalDiscount = totalDiscount + data.discount;
            // Calculate total vatAmount
            totalVatAmount = totalVatAmount + data.vatAmount;
          });

          const { isDuplicateItemName, isDuplicateVatPercent } =
            handleDuplicateItem(nodes);

          nodes.map((data: any) => {
            data["isDuplicateItemName"] = isDuplicateItemName;
            data["isDuplicateVatPercent"] = isDuplicateVatPercent;
          });
          pdfNodes.map((data: any) => {
            data["isDuplicateItemName"] = isDuplicateItemName;
            data["isDuplicateVatPercent"] = isDuplicateVatPercent;
          });

          /* Entering values ​​for the id, categoryName, amount, qty, discount, 
                 vatPercent, and vatAmount columns in the item report table.​​​​ */
          itemsReportNode.push({
            id: key,
            categoryName: categoryName,
            amount: parseFloat(amount.toString()).toFixed(2),
            qty: qty,
            discount: parseFloat(discount.toString()).toFixed(2),
            vatPercent: vatPercent.slice(0, -1).replaceAll(",", ", "),
            vatAmount: parseFloat(vatAmount.toString()).toFixed(2),
            nodes: nodes,
          });

          /* Enter data required for PDF.​​​​ */
          pdfNodeList.push({
            id: key,
            categoryName: categoryName,
            amount: parseFloat(amount.toString()).toFixed(2),
            qty: qty,
            discount: parseFloat(discount.toString()).toFixed(2),
            vatPercent: vatPercent.slice(0, -1).replaceAll(",", ", "),
            vatAmount: parseFloat(vatAmount.toString()).toFixed(2),
            nodes: "main",
          });
          pdfNodes.map((data: any) => {
            pdfNodeList.push(data);
          });
        }
      });

      // Adding the total of 'totalAmount', 'totalQty ,'totalDiscount', and 'totalVatAmount'.
      const footer = [
        {
          id: "",
          categoryName: "Total",
          amount: parseFloat(totalAmount.toString()).toFixed(2),
          qty: totalQty,
          discount: parseFloat(totalDiscount.toString()).toFixed(2),
          vatPercent: "",
          vatAmount: parseFloat(totalVatAmount.toString()).toFixed(2),
          nodes: "",
        },
      ];
      setFooter(footer);
      setFooterInitial(footer);

      // Inserting the 'footer' of the table for the PDF.
      pdfNodeList.push({
        id: "",
        categoryName: "Total",
        amount: parseFloat(totalAmount.toString()).toFixed(2),
        qty: totalQty,
        discount: parseFloat(totalDiscount.toString()).toFixed(2),
        vatPercent: "",
        vatAmount: parseFloat(totalVatAmount.toString()).toFixed(2),
        nodes: "",
      });

      // Set table header
      setHeadersList([
        "Category Name",
        "Amount",
        "Quantity",
        "Discount",
        "Vat Percent",
        "Vat Amount",
      ]);

      // Set PDF header
      setPdfHeaders([
        { title: "Category Name", field: "categoryName" },
        { title: "Amount", field: "amount" },
        { title: "Quantity", field: "qty" },
        { title: "Discount", field: "discount" },
        { title: "Vat Percent", field: "vatPercent" },
        { title: "Vat Amount", field: "vatAmount" },
      ]);

      // set Excel header
      setExcelHeaders([
        { header: "Category Name", key: "categoryName", width: 52, height: 68 },
        { header: "Amount", key: "amount", width: 32, height: 68 },
        { header: "Quantity", key: "qty", width: 32, height: 68 },
        { header: "Discount", key: "discount", width: 32, height: 68 },
        { header: "Vat Percent", key: "vatPercent", width: 32, height: 68 },
        { header: "Vat Amount", key: "vatAmount", width: 32, height: 68 },
      ]);

      setNodesPdfExcel(pdfNodeList);
      setNodesPdfExcelInitial(pdfNodeList);
      setNodes({ nodes: itemsReportNode });
      setNodesInitial({ nodes: itemsReportNode });
      setBodyLength(pdfNodeList.length);
      setBodyLengthInitial(pdfNodeList.length);
    }
  }, [itemsReportNodeList]);

  // Create a pay In Out sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: {
        "Category Name": (array) => sortStrings(array, "categoryName"),
        Amount: (array) => sortNumbers(array, "amount"),
        Quantity: (array) => sortNumbers(array, "qty"),
        Discount: (array) => sortNumbers(array, "discount"),
        "Vat Percent": (array) => sortNumbers(array, "vatPercent"),
        "Vat Amount": (array) => sortNumbers(array, "vatAmount"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes: any = useStyles(nodesHeadersLength)();

  /* Change row span icon */
  const tree = useTree(
    nodes,
    {},
    {
      treeIcon: {
        margin: "4px",
        iconDefault: <ArrowDropDownIcon />,
        iconRight: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /* Item search function. */
  const handleChangeSearch = (e: any) => {
    let pdfNodeList: any = [];
    setSearchValue(e.target.value);

    // If the user has not made any search, initial data is entered.
    if (e.target.value === "") {
      setNodes(nodesInitial);
      setFooter(footerInitial);
      setNodesPdfExcel(nodesPdfExcelInitial);
      setBodyLength(bodyLengthInitial);
    } else {
      const filterNode = nodesInitial.nodes.filter((data: any) => {
        /* Check if the searched value is categoryName, amount, categoryName, qty, discount, vatPercent, or vatAmount. */
        if (
          checkIncludedValue(data.categoryName, e.target.value) ||
          checkIncludedValue(data.amount, e.target.value) ||
          checkIncludedValue(data.qty.toString(), e.target.value) ||
          checkIncludedValue(data.discount, e.target.value) ||
          checkIncludedValue(data.vatPercent.toString(), e.target.value) ||
          checkIncludedValue(data.vatAmount, e.target.value)
        ) {
          let pdfNodes: any = [];
          // This only works if there aren't multiple items with the same name.
          if (_.isEmpty(data.nodes)) {
            pdfNodeList.push({
              id: "",
              categoryName: data.categoryName,
              amount: parseFloat(data.amount).toFixed(2),
              qty: data.qty,
              discount: parseFloat(data.discount).toFixed(2),
              vatPercent: data.vatPercent,
              vatAmount: parseFloat(data.vatAmount).toFixed(2),
              nodes: "",
            });
          } else {
            // This only works if there are multiple items with the same name.
            data.nodes.map((data: any) => {
              pdfNodes.push({
                categoryName: data.categoryName,
                amount: parseFloat(data.amount).toFixed(2),
                qty: data.qty,
                discount: parseFloat(data.discount).toFixed(2),
                vatPercent: data.vatPercent,
                vatAmount: parseFloat(data.vatAmount).toFixed(2),
                nodes: "sub",
              });
            });
            const { isDuplicateItemName, isDuplicateVatPercent } =
              handleDuplicateItem(data.nodes);

            pdfNodes.map((data: any) => {
              data["isDuplicateItemName"] = isDuplicateItemName;
              data["isDuplicateVatPercent"] = isDuplicateVatPercent;
            });

            /* Enter data required for PDF.​​​​ */
            pdfNodeList.push({
              id: "",
              categoryName: data.categoryName,
              amount: data.amount,
              qty: data.qty,
              discount: data.discount,
              vatPercent: data.vatPercent,
              vatAmount: data.vatAmount,
              nodes: "main",
            });

            pdfNodes.map((data: any) => {
              pdfNodeList.push(data);
            });
          }

          return data;
        }
      });

      let totalAmount = 0;
      let totalQty = 0;
      let totalDiscount = 0;
      let totalVatAmount = 0;

      // Calculation of total amount, total qty, total discount, and total vat amount after filtering.
      filterNode.map((data: any) => {
        totalAmount = totalAmount + parseFloat(data.amount);
        totalQty = totalQty + data.qty;
        totalDiscount = totalDiscount + data.discount;
        totalVatAmount = totalVatAmount + parseFloat(data.vatAmount);
      });

      // Adding the values ​​obtained above to the footer of the PDF.
      pdfNodeList.push({
        id: "",
        categoryName: "Total",
        amount: parseFloat(totalAmount.toString()).toFixed(2),
        qty: totalQty,
        discount: parseFloat(totalDiscount.toString()).toFixed(2),
        vatPercent: "",
        vatAmount: parseFloat(totalVatAmount.toString()).toFixed(2),
        nodes: "",
      });

      // Adding item report table footer.
      setFooter([
        {
          id: "",
          categoryName: "Total",
          amount: parseFloat(totalAmount.toString()).toFixed(2),
          qty: totalQty,
          discount: parseFloat(totalDiscount.toString()).toFixed(2),
          vatPercent: "",
          vatAmount: parseFloat(totalVatAmount.toString()).toFixed(2),
          nodes: "",
        },
      ]);

      setBodyLength(pdfNodeList.length);
      setNodes({ nodes: filterNode });
      setNodesPdfExcel(pdfNodeList);
    }
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const theme: CustomTheme = useTheme();
  return (
    <>
      {!_.isEmpty(nodes.nodes) && (
        <>
          <Grid container spacing={2} style={{ marginTop: "8px" }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
              }}
            >
              <div>
                <LocationDetailsCard
                  locationSelectedList={locationSelectedList}
                  handleOnClickText={handleOnClickText}
                />
              </div>
            </Grid>
          </Grid>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block", marginTop: "8px", marginBottom: "20px" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }
            }
          >
            <TextfieldCommon
              id="search"
              name="search"
              label="Search"
              type="text"
              style={
                maxWidthLocationChips ? { width: "100%" } : { width: "240px" }
              }
              value={searchValue}
              onChange={handleChangeSearch}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={
                maxWidthLocationChips
                  ? {
                      marginBottom: "12px",
                      marginTop: "8px",
                    }
                  : {
                      marginTop: "8px",
                    }
              }
            >
              <MenuButtonCommon
                id="basic-button"
                variant="contained"
                style={{
                  fontSize: 11,
                  height: "38px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <GetAppIcon />
                <Typography
                  variant="body1"
                  style={{ paddingLeft: "16px", color: "white" }}
                >
                  Export
                </Typography>
              </MenuButtonCommon>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  style: {
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  },
                }}
                style={{ top: "56px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleDownloadPdf(
                      "p",
                      pdfHeaders,
                      nodesPdfExcel,
                      filterDetails,
                    );
                    handleClose();
                  }}
                >
                  Export as PDF
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDownloadCsv(
                      excelHeaders,
                      nodesPdfExcel,
                      filterDetails,
                      bodyLength,
                    );
                    handleClose();
                  }}
                >
                  Export as CSV
                </MenuItem>
              </Menu>
            </div>
          </div>
        </>
      )}

      <CardCommon>
        <Table
          data={nodes}
          tree={tree}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
          style={{ zIndex: 0 }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => (
                    <HeaderCellSort
                      sortKey={data}
                      pinLeft={index === 0 ? true : false}
                      className={classes.baseCellRow}
                    >
                      <Typography
                        variant="caption"
                        className={
                          index === 0
                            ? classes.startHeaderTextStyle
                            : classes.textStyleHeader
                        }
                      >
                        {data}
                      </Typography>
                    </HeaderCellSort>
                  ))}
                </HeaderRow>
              </Header>

              <Body className={classes.bodyStyle}>
                {tableList.map((item, bodyIndex) => (
                  <>
                    <Row
                      key={item.id}
                      item={item}
                      className={
                        item.nodes ? classes.mainRow : classes.normalRow
                      }
                    >
                      <Cell
                        pinLeft={true}
                        className={
                          item.isDuplicateItemName === false
                            ? classes.baseCellRowChangeColor
                            : classes.baseCellRow
                        }
                      >
                        <Button
                          className={
                            item.nodes || item.isDuplicateItemName === false
                              ? classes.startTextStyle
                              : classes.startNotNodeTextStyle
                          }
                          style={{ textTransform: "none" }}
                          onClick={() =>
                            window.open(
                              `presto-sales/items/${item.id}`,
                              "_blank",
                            )
                          }
                        >
                          <Typography
                            variant="caption"
                            align="left"
                            style={
                              item.nodes || item.isDuplicateItemName === false
                                ? {
                                    color: "white",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontSize: "12px",
                                  }
                                : {
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontSize: "12px",
                                  }
                            }
                          >
                            {item.categoryName}
                          </Typography>
                        </Button>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          className={classes.textStyle}
                          style={
                            item.nodes || item.isDuplicateItemName === false
                              ? { color: "white" }
                              : { marginTop: "0px" }
                          }
                        >
                          {item.amount}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          className={classes.textStyle}
                          style={
                            item.nodes || item.isDuplicateItemName === false
                              ? { color: "white" }
                              : { marginTop: "0px" }
                          }
                        >
                          {item.qty}
                        </Typography>
                      </Cell>

                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          className={classes.textStyle}
                          style={
                            item.nodes || item.isDuplicateItemName === false
                              ? { color: "white" }
                              : { marginTop: "0px" }
                          }
                        >
                          {item.discount}
                        </Typography>
                      </Cell>
                      <Cell
                        className={
                          item.isDuplicateItemName === false ||
                          item.isDuplicateVatPercent === false
                            ? classes.baseCellRowChangeColor
                            : classes.baseCellRow
                        }
                      >
                        <Typography
                          variant="caption"
                          className={
                            item.nodes || item.isDuplicateItemName === false
                              ? classes.baseCellRowChangeColor
                              : classes.baseCellRow
                          }
                          style={
                            item.nodes || item.isDuplicateItemName
                              ? { color: "white" }
                              : { marginTop: "0px" }
                          }
                        >
                          {item.vatPercent}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRowEnd}>
                        <Typography
                          variant="caption"
                          className={classes.textStyle}
                          style={
                            item.nodes || item.isDuplicateItemName === false
                              ? {
                                  color: "white",
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight: "16px",
                                }
                              : {
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight: "16px",
                                }
                          }
                        >
                          {item.vatAmount}
                        </Typography>
                      </Cell>
                    </Row>
                    {bodyIndex + 1 === tableList.length &&
                      footer.map((data1: any, footerIndex: any) => (
                        <>
                          <Row className={classes.finalTotalRow} item={item}>
                            <Cell
                              pinLeft={true}
                              className={classes.baseCellRow}
                            >
                              <Typography
                                variant="caption"
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  display: "flex",
                                  justifyContent: "start",
                                  paddingLeft: "16px",
                                  fontSize: "12px",
                                  color: "white",
                                }}
                              >
                                {data1.categoryName}
                              </Typography>
                            </Cell>
                            <Cell className={classes.baseCellRow}>
                              <Typography
                                variant="caption"
                                className={classes.textStyleFooter}
                              >
                                {data1.amount}
                              </Typography>
                            </Cell>
                            <Cell className={classes.baseCellRow}>
                              <Typography
                                variant="caption"
                                className={classes.textStyleFooter}
                              >
                                {data1.qty}
                              </Typography>
                            </Cell>

                            <Cell className={classes.baseCellRow}>
                              <Typography
                                variant="caption"
                                className={classes.textStyleFooter}
                              >
                                {data1.discount}
                              </Typography>
                            </Cell>
                            <Cell className={classes.baseCellRow}>
                              <Typography
                                variant="caption"
                                className={classes.baseCellRow}
                              >
                                {data1.vatPercent}
                              </Typography>
                            </Cell>
                            <Cell className={classes.baseCellRowEnd}>
                              <Typography
                                variant="caption"
                                className={classes.textStyleFooter}
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight: "16px",
                                }}
                              >
                                {data1.vatAmount}
                              </Typography>
                            </Cell>
                          </Row>
                        </>
                      ))}
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    </>
  );
};

export default SaleCategoryReportInfoNode;
