import React from "react";
import {
  Button,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import CardCommon from "../../../../components/card/CardCommon";
import CategoryMenuListStrip from "./CategoryMenuListStrip";
import _ from "lodash";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { CustomTheme } from "../../../../types/customTheme";
import CategoryMenuList from "./CategoryMenuList";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    dividerStyle: {
      margin: "0px 0px 20px 0px",
      backgroundColor: theme.palette.custom.grey.main,
      height: "2px",
      borderRadius: "10px",
      width: "100%",
    },
  }),
);

export interface MenuInfoPageProps {
  wrapperProduct: any[];
  removedLinkedIdList: any;
  handleUpdateIdList: any;
  index: any;
  isColorChange: any;
  revertExpand: any;
  handleSelectProduct: any;
  category: any;
  handleClickLinkedId: any;
  removedLinkedIdLists: any;
  handleNavigate: any;
  handleClearStrip: any;
  selectedWrapperIdList: any;
  setSelectedWrapperIdList: any;
  isOwner: any;
  editableRewardDetails: any;
}

const ProductWrapper: React.FC<MenuInfoPageProps> = ({
  wrapperProduct,
  removedLinkedIdList,
  handleUpdateIdList,
  index,
  isColorChange,
  revertExpand,
  handleSelectProduct,
  category,
  handleClickLinkedId,
  removedLinkedIdLists,
  handleNavigate,
  handleClearStrip,
  selectedWrapperIdList,
  setSelectedWrapperIdList,
  isOwner,
  editableRewardDetails,
}) => {
  const classes = useStyles();
  const handleWrapper = (product: any) => {
    return (
      <>
        {product?.wrapper ? (
          <Grid item md={6} xs={12} lg={4}>
            <div>
              <CategoryMenuListStrip
                item={product}
                handleClickLinkedId={handleClickLinkedId}
                isColorChange={isColorChange}
                handleUpdateIdList={handleUpdateIdList}
                category={product}
              />
            </div>
          </Grid>
        ) : (
          <Grid item md={6} xs={12} lg={4}>
            <CardCommon
              backgroundColor={"default"}
              style={{ borderRadius: "10px", marginTop: "12px" }}
            >
              <CategoryMenuList
                item={product}
                removedLinkedIdList={removedLinkedIdList}
                handleUpdateIdList={handleUpdateIdList}
                index={0}
                data={product}
                isColorChange={isColorChange}
                revertExpand={revertExpand}
                handleSelectProduct={handleSelectProduct}
                category={category}
                selectedWrapperIdList={selectedWrapperIdList}
                setSelectedWrapperIdList={setSelectedWrapperIdList}
                isOwner={isOwner}
                editableRewardDetails={editableRewardDetails}
              />
            </CardCommon>
          </Grid>
        )}
        {product.wrapper &&
          Object.keys(removedLinkedIdLists).includes(product.id.toString()) && (
            <Grid
              container
              spacing={2}
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                marginBottom: "8px",
                marginTop: "8px",
              }}
            >
              <CardCommon
                backgroundColor={"entity_highlight_directory_background"}
                style={{ width: "100%", overflow: "hidden" }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    paddingLeft: "14px",
                    paddingRight: "14px",
                    marginBottom: "6px",
                  }}
                >
                  {product.wrapper.map((data: any, index: number) => {
                    return (
                      <>
                        {removedLinkedIdLists[data["directoryId"].toString()]
                          .directoryLevel +
                          1 ===
                          data.directoryLevel &&
                          removedLinkedIdLists[data["directoryId"].toString()]
                            .id === data.directoryId && (
                            <>
                              {index === 0 && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                  }}
                                >
                                  <Button>
                                    <Typography variant="h6">
                                      {product.name}
                                    </Typography>
                                  </Button>
                                  <Button
                                    onClick={() => handleClearStrip(product)}
                                  >
                                    <HighlightOffIcon />
                                  </Button>
                                </Grid>
                              )}
                              {data?.wrapper?.length > 0 ? (
                                <Grid item md={6} xs={12} lg={4}>
                                  <div>
                                    <CategoryMenuListStrip
                                      item={data}
                                      handleClickLinkedId={handleClickLinkedId}
                                      isColorChange={isColorChange}
                                      handleUpdateIdList={handleUpdateIdList}
                                      category={data}
                                    />
                                  </div>
                                </Grid>
                              ) : (
                                <Grid item md={6} xs={12} lg={4}>
                                  <CardCommon
                                    backgroundColor={"default"}
                                    style={{
                                      borderRadius: "10px",
                                      marginTop: "14px",
                                    }}
                                  >
                                    <CategoryMenuList
                                      item={data}
                                      removedLinkedIdList={removedLinkedIdList}
                                      handleUpdateIdList={handleUpdateIdList}
                                      index={0}
                                      data={data}
                                      isColorChange={isColorChange}
                                      revertExpand={revertExpand}
                                      handleSelectProduct={handleSelectProduct}
                                      category={category}
                                      selectedWrapperIdList={
                                        selectedWrapperIdList
                                      }
                                      setSelectedWrapperIdList={
                                        setSelectedWrapperIdList
                                      }
                                      isOwner={isOwner}
                                      editableRewardDetails={
                                        editableRewardDetails
                                      }
                                    />
                                  </CardCommon>
                                </Grid>
                              )}
                            </>
                          )}
                        {data?.wrapper &&
                          data.wrapper.map((data1: any, index: any) => {
                            return (
                              <>
                                {removedLinkedIdLists[
                                  data["directoryId"].toString()
                                ].directoryLevel +
                                  1 ===
                                  data1.directoryLevel &&
                                  removedLinkedIdLists[
                                    data["directoryId"].toString()
                                  ].id === data1.directoryId && (
                                    <>
                                      {index === 0 && (
                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            onClick={() =>
                                              handleNavigate(product, 1, data)
                                            }
                                          >
                                            <Typography variant="h6">
                                              {data1.name}
                                            </Typography>
                                          </Button>
                                          <Typography variant="h6">
                                            {"->"}
                                          </Typography>
                                          <Button>
                                            <Typography variant="h6">
                                              {data.name}
                                            </Typography>
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handleClearStrip(product)
                                            }
                                          >
                                            <HighlightOffIcon />
                                          </Button>
                                        </Grid>
                                      )}
                                      {data1?.wrapper?.length > 0 ? (
                                        <Grid item md={6} xs={12} lg={4}>
                                          <div>
                                            <CategoryMenuListStrip
                                              item={data1}
                                              handleClickLinkedId={
                                                handleClickLinkedId
                                              }
                                              isColorChange={isColorChange}
                                              handleUpdateIdList={
                                                handleUpdateIdList
                                              }
                                              category={data}
                                            />
                                          </div>
                                        </Grid>
                                      ) : (
                                        <Grid item md={6} xs={12} lg={4}>
                                          <CardCommon
                                            backgroundColor={"default"}
                                            style={{
                                              borderRadius: "10px",
                                              marginTop: "14px",
                                            }}
                                          >
                                            <CategoryMenuList
                                              item={data1}
                                              removedLinkedIdList={
                                                removedLinkedIdList
                                              }
                                              handleUpdateIdList={
                                                handleUpdateIdList
                                              }
                                              index={0}
                                              data={data1}
                                              isColorChange={isColorChange}
                                              revertExpand={revertExpand}
                                              handleSelectProduct={
                                                handleSelectProduct
                                              }
                                              category={category}
                                              selectedWrapperIdList={
                                                selectedWrapperIdList
                                              }
                                              setSelectedWrapperIdList={
                                                setSelectedWrapperIdList
                                              }
                                              isOwner={isOwner}
                                              editableRewardDetails={
                                                editableRewardDetails
                                              }
                                            />
                                          </CardCommon>
                                        </Grid>
                                      )}
                                    </>
                                  )}
                                {data1?.wrapper &&
                                  data1.wrapper.map(
                                    (data2: any, index: any) => {
                                      return (
                                        <>
                                          {removedLinkedIdLists[
                                            data["directoryId"].toString()
                                          ].directoryLevel +
                                            1 ===
                                            data2.directoryLevel &&
                                            removedLinkedIdLists[
                                              data["directoryId"].toString()
                                            ].id === data2.directoryId && (
                                              <>
                                                {index === 0 && (
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "start",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Button
                                                      onClick={() =>
                                                        handleNavigate(
                                                          product,
                                                          1,
                                                          data,
                                                        )
                                                      }
                                                    >
                                                      <Typography variant="h6">
                                                        {product.name}
                                                      </Typography>
                                                    </Button>
                                                    <Typography variant="h6">
                                                      {"->"}
                                                    </Typography>
                                                    <Button>
                                                      <Typography
                                                        variant="h6"
                                                        onClick={() =>
                                                          handleNavigate(
                                                            data,
                                                            2,
                                                            data,
                                                          )
                                                        }
                                                      >
                                                        {data.name}
                                                      </Typography>
                                                    </Button>
                                                    <Typography variant="h6">
                                                      {"->"}
                                                    </Typography>
                                                    <Button>
                                                      <Typography variant="h6">
                                                        {data1.name}
                                                      </Typography>
                                                    </Button>
                                                    <Button
                                                      onClick={() =>
                                                        handleClearStrip(
                                                          product,
                                                        )
                                                      }
                                                    >
                                                      <HighlightOffIcon />
                                                    </Button>
                                                  </Grid>
                                                )}
                                                {data2?.wrapper?.length > 0 ? (
                                                  <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    lg={4}
                                                  >
                                                    <div>
                                                      <CategoryMenuListStrip
                                                        item={data2}
                                                        handleClickLinkedId={
                                                          handleClickLinkedId
                                                        }
                                                        isColorChange={
                                                          isColorChange
                                                        }
                                                        handleUpdateIdList={
                                                          handleUpdateIdList
                                                        }
                                                        category={data}
                                                      />
                                                    </div>
                                                  </Grid>
                                                ) : (
                                                  <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    lg={4}
                                                  >
                                                    <CardCommon
                                                      backgroundColor={
                                                        "default"
                                                      }
                                                      style={{
                                                        borderRadius: "10px",
                                                        marginTop: "14px",
                                                      }}
                                                    >
                                                      <CategoryMenuList
                                                        item={data2}
                                                        removedLinkedIdList={
                                                          removedLinkedIdList
                                                        }
                                                        handleUpdateIdList={
                                                          handleUpdateIdList
                                                        }
                                                        index={0}
                                                        data={data2}
                                                        isColorChange={
                                                          isColorChange
                                                        }
                                                        revertExpand={
                                                          revertExpand
                                                        }
                                                        handleSelectProduct={
                                                          handleSelectProduct
                                                        }
                                                        category={category}
                                                        selectedWrapperIdList={
                                                          selectedWrapperIdList
                                                        }
                                                        setSelectedWrapperIdList={
                                                          setSelectedWrapperIdList
                                                        }
                                                        isOwner={isOwner}
                                                        editableRewardDetails={
                                                          editableRewardDetails
                                                        }
                                                      />
                                                    </CardCommon>
                                                  </Grid>
                                                )}
                                              </>
                                            )}

                                          {data2?.wrapper &&
                                            data2.wrapper.map(
                                              (data3: any, index: any) => {
                                                return (
                                                  <>
                                                    {removedLinkedIdLists[
                                                      data[
                                                        "directoryId"
                                                      ].toString()
                                                    ].directoryLevel +
                                                      1 ===
                                                      data3.directoryLevel &&
                                                      removedLinkedIdLists[
                                                        data[
                                                          "directoryId"
                                                        ].toString()
                                                      ].id ===
                                                        data3.directoryId && (
                                                        <>
                                                          {index === 0 && (
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                  "start",
                                                                alignItems:
                                                                  "center",
                                                              }}
                                                            >
                                                              <Button
                                                                onClick={() =>
                                                                  handleNavigate(
                                                                    product,
                                                                    1,
                                                                    data,
                                                                  )
                                                                }
                                                              >
                                                                <Typography variant="h6">
                                                                  {product.name}
                                                                </Typography>
                                                              </Button>
                                                              <Typography variant="h6">
                                                                {"->"}
                                                              </Typography>
                                                              <Button
                                                                onClick={() =>
                                                                  handleNavigate(
                                                                    data,
                                                                    2,
                                                                    data,
                                                                  )
                                                                }
                                                              >
                                                                <Typography variant="h6">
                                                                  {data.name}
                                                                </Typography>
                                                              </Button>
                                                              <Typography variant="h6">
                                                                {"->"}
                                                              </Typography>
                                                              <Button
                                                                onClick={() =>
                                                                  handleNavigate(
                                                                    data1,
                                                                    3,
                                                                    data,
                                                                  )
                                                                }
                                                              >
                                                                <Typography variant="h6">
                                                                  {data1.name}
                                                                </Typography>
                                                              </Button>
                                                              <Typography variant="h6">
                                                                {"->"}
                                                              </Typography>
                                                              <Button>
                                                                <Typography variant="h6">
                                                                  {data2.name}
                                                                </Typography>
                                                              </Button>
                                                              <Button
                                                                onClick={() =>
                                                                  handleClearStrip(
                                                                    product,
                                                                  )
                                                                }
                                                              >
                                                                <HighlightOffIcon />
                                                              </Button>
                                                            </Grid>
                                                          )}
                                                          {data3?.wrapper
                                                            ?.length > 0 ? (
                                                            <Grid
                                                              item
                                                              md={6}
                                                              xs={12}
                                                              lg={4}
                                                            >
                                                              <div>
                                                                <CategoryMenuListStrip
                                                                  item={data3}
                                                                  handleClickLinkedId={
                                                                    handleClickLinkedId
                                                                  }
                                                                  isColorChange={
                                                                    isColorChange
                                                                  }
                                                                  handleUpdateIdList={
                                                                    handleUpdateIdList
                                                                  }
                                                                  category={
                                                                    data
                                                                  }
                                                                />
                                                              </div>
                                                            </Grid>
                                                          ) : (
                                                            <Grid
                                                              item
                                                              md={6}
                                                              xs={12}
                                                              lg={4}
                                                            >
                                                              <CardCommon
                                                                backgroundColor={
                                                                  "default"
                                                                }
                                                                style={{
                                                                  borderRadius:
                                                                    "10px",
                                                                  marginTop:
                                                                    "14px",
                                                                }}
                                                              >
                                                                <CategoryMenuList
                                                                  item={data3}
                                                                  removedLinkedIdList={
                                                                    removedLinkedIdList
                                                                  }
                                                                  handleUpdateIdList={
                                                                    handleUpdateIdList
                                                                  }
                                                                  index={0}
                                                                  data={data3}
                                                                  isColorChange={
                                                                    isColorChange
                                                                  }
                                                                  revertExpand={
                                                                    revertExpand
                                                                  }
                                                                  handleSelectProduct={
                                                                    handleSelectProduct
                                                                  }
                                                                  category={
                                                                    category
                                                                  }
                                                                  selectedWrapperIdList={
                                                                    selectedWrapperIdList
                                                                  }
                                                                  setSelectedWrapperIdList={
                                                                    setSelectedWrapperIdList
                                                                  }
                                                                  isOwner={
                                                                    isOwner
                                                                  }
                                                                  editableRewardDetails={
                                                                    editableRewardDetails
                                                                  }
                                                                />
                                                              </CardCommon>
                                                            </Grid>
                                                          )}
                                                        </>
                                                      )}
                                                    {data3?.wrapper &&
                                                      data3.wrapper.map(
                                                        (
                                                          data4: any,
                                                          index: any,
                                                        ) => {
                                                          return (
                                                            <>
                                                              {removedLinkedIdLists[
                                                                data[
                                                                  "directoryId"
                                                                ].toString()
                                                              ].directoryLevel +
                                                                1 ===
                                                                data4.directoryLevel &&
                                                                removedLinkedIdLists[
                                                                  data[
                                                                    "directoryId"
                                                                  ].toString()
                                                                ].id ===
                                                                  data4.directoryId && (
                                                                  <>
                                                                    {index ===
                                                                      0 && (
                                                                      <Grid
                                                                        item
                                                                        xs={12}
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          justifyContent:
                                                                            "start",
                                                                          alignItems:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        <Button
                                                                          onClick={() =>
                                                                            handleNavigate(
                                                                              product,
                                                                              1,
                                                                              data,
                                                                            )
                                                                          }
                                                                        >
                                                                          <Typography variant="h6">
                                                                            {
                                                                              product.name
                                                                            }
                                                                          </Typography>
                                                                        </Button>
                                                                        <Typography variant="h6">
                                                                          {"->"}
                                                                        </Typography>
                                                                        <Button
                                                                          onClick={() =>
                                                                            handleNavigate(
                                                                              data,
                                                                              2,
                                                                              data,
                                                                            )
                                                                          }
                                                                        >
                                                                          <Typography variant="h6">
                                                                            {
                                                                              data.name
                                                                            }
                                                                          </Typography>
                                                                        </Button>
                                                                        <Typography variant="h6">
                                                                          {"->"}
                                                                        </Typography>
                                                                        <Button
                                                                          onClick={() =>
                                                                            handleNavigate(
                                                                              data1,
                                                                              3,
                                                                              data,
                                                                            )
                                                                          }
                                                                        >
                                                                          <Typography variant="h6">
                                                                            {
                                                                              data1.name
                                                                            }
                                                                          </Typography>
                                                                        </Button>
                                                                        <Typography variant="h6">
                                                                          {"->"}
                                                                        </Typography>
                                                                        <Button
                                                                          onClick={() =>
                                                                            handleNavigate(
                                                                              data2,
                                                                              4,
                                                                              data,
                                                                            )
                                                                          }
                                                                        >
                                                                          <Typography variant="h6">
                                                                            {
                                                                              data2.name
                                                                            }
                                                                          </Typography>
                                                                        </Button>
                                                                        <Button>
                                                                          <Typography variant="h6">
                                                                            {
                                                                              data3.name
                                                                            }
                                                                          </Typography>
                                                                        </Button>
                                                                        <Button
                                                                          onClick={() =>
                                                                            handleClearStrip(
                                                                              product,
                                                                            )
                                                                          }
                                                                        >
                                                                          <HighlightOffIcon />
                                                                        </Button>
                                                                      </Grid>
                                                                    )}
                                                                    {data4
                                                                      ?.wrapper
                                                                      ?.length >
                                                                    0 ? (
                                                                      <Grid
                                                                        item
                                                                        md={6}
                                                                        xs={12}
                                                                        lg={4}
                                                                      >
                                                                        <div>
                                                                          <CategoryMenuListStrip
                                                                            item={
                                                                              data4
                                                                            }
                                                                            handleClickLinkedId={
                                                                              handleClickLinkedId
                                                                            }
                                                                            isColorChange={
                                                                              isColorChange
                                                                            }
                                                                            handleUpdateIdList={
                                                                              handleUpdateIdList
                                                                            }
                                                                            category={
                                                                              data
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </Grid>
                                                                    ) : (
                                                                      <Grid
                                                                        item
                                                                        md={6}
                                                                        xs={12}
                                                                        lg={4}
                                                                      >
                                                                        <CardCommon
                                                                          backgroundColor={
                                                                            "default"
                                                                          }
                                                                          style={{
                                                                            borderRadius:
                                                                              "10px",
                                                                            marginTop:
                                                                              "14px",
                                                                          }}
                                                                        >
                                                                          <CategoryMenuList
                                                                            item={
                                                                              data4
                                                                            }
                                                                            removedLinkedIdList={
                                                                              removedLinkedIdList
                                                                            }
                                                                            handleUpdateIdList={
                                                                              handleUpdateIdList
                                                                            }
                                                                            index={
                                                                              0
                                                                            }
                                                                            data={
                                                                              data4
                                                                            }
                                                                            isColorChange={
                                                                              isColorChange
                                                                            }
                                                                            revertExpand={
                                                                              revertExpand
                                                                            }
                                                                            handleSelectProduct={
                                                                              handleSelectProduct
                                                                            }
                                                                            category={
                                                                              category
                                                                            }
                                                                            selectedWrapperIdList={
                                                                              selectedWrapperIdList
                                                                            }
                                                                            setSelectedWrapperIdList={
                                                                              setSelectedWrapperIdList
                                                                            }
                                                                            isOwner={
                                                                              isOwner
                                                                            }
                                                                            editableRewardDetails={
                                                                              editableRewardDetails
                                                                            }
                                                                          />
                                                                        </CardCommon>
                                                                      </Grid>
                                                                    )}
                                                                  </>
                                                                )}
                                                              {data4?.wrapper &&
                                                                data4.wrapper.map(
                                                                  (
                                                                    data5: any,
                                                                    index: any,
                                                                  ) => {
                                                                    return (
                                                                      <>
                                                                        {removedLinkedIdLists[
                                                                          data[
                                                                            "directoryId"
                                                                          ].toString()
                                                                        ]
                                                                          .directoryLevel +
                                                                          1 ===
                                                                          data5.directoryLevel &&
                                                                          removedLinkedIdLists[
                                                                            data[
                                                                              "directoryId"
                                                                            ].toString()
                                                                          ]
                                                                            .id ===
                                                                            data5.directoryId && (
                                                                            <>
                                                                              {index ===
                                                                                0 && (
                                                                                <Grid
                                                                                  item
                                                                                  xs={
                                                                                    12
                                                                                  }
                                                                                  style={{
                                                                                    display:
                                                                                      "flex",
                                                                                    justifyContent:
                                                                                      "start",
                                                                                    alignItems:
                                                                                      "center",
                                                                                  }}
                                                                                >
                                                                                  <Button
                                                                                    onClick={() =>
                                                                                      handleNavigate(
                                                                                        product,
                                                                                        1,
                                                                                        data,
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <Typography variant="h6">
                                                                                      {
                                                                                        product.name
                                                                                      }
                                                                                    </Typography>
                                                                                  </Button>
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      "->"
                                                                                    }
                                                                                  </Typography>
                                                                                  <Button
                                                                                    onClick={() =>
                                                                                      handleNavigate(
                                                                                        data,
                                                                                        2,
                                                                                        data,
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <Typography variant="h6">
                                                                                      {
                                                                                        data.name
                                                                                      }
                                                                                    </Typography>
                                                                                  </Button>
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      "->"
                                                                                    }
                                                                                  </Typography>
                                                                                  <Button
                                                                                    onClick={() =>
                                                                                      handleNavigate(
                                                                                        data1,
                                                                                        3,
                                                                                        data,
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <Typography variant="h6">
                                                                                      {
                                                                                        data1.name
                                                                                      }
                                                                                    </Typography>
                                                                                  </Button>
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      "->"
                                                                                    }
                                                                                  </Typography>
                                                                                  <Button
                                                                                    onClick={() =>
                                                                                      handleNavigate(
                                                                                        data2,
                                                                                        4,
                                                                                        data,
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <Typography variant="h6">
                                                                                      {
                                                                                        data2.name
                                                                                      }
                                                                                    </Typography>
                                                                                  </Button>
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      "->"
                                                                                    }
                                                                                  </Typography>
                                                                                  <Button
                                                                                    onClick={() =>
                                                                                      handleNavigate(
                                                                                        data3,
                                                                                        5,
                                                                                        data,
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <Typography variant="h6">
                                                                                      {
                                                                                        data3.name
                                                                                      }
                                                                                    </Typography>
                                                                                  </Button>
                                                                                  <Typography variant="h6">
                                                                                    {
                                                                                      "->"
                                                                                    }
                                                                                  </Typography>
                                                                                  <Button
                                                                                    onClick={() =>
                                                                                      handleNavigate(
                                                                                        data4,
                                                                                        6,
                                                                                        data,
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <Typography variant="h6">
                                                                                      {
                                                                                        data4.name
                                                                                      }
                                                                                    </Typography>
                                                                                  </Button>
                                                                                  <Button
                                                                                    onClick={() =>
                                                                                      handleClearStrip(
                                                                                        product,
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <HighlightOffIcon />
                                                                                  </Button>
                                                                                </Grid>
                                                                              )}
                                                                              {data5
                                                                                ?.wrapper
                                                                                ?.length >
                                                                              0 ? (
                                                                                <Grid
                                                                                  item
                                                                                  md={
                                                                                    6
                                                                                  }
                                                                                  xs={
                                                                                    12
                                                                                  }
                                                                                  lg={
                                                                                    4
                                                                                  }
                                                                                >
                                                                                  <div>
                                                                                    <CategoryMenuListStrip
                                                                                      item={
                                                                                        data5
                                                                                      }
                                                                                      handleClickLinkedId={
                                                                                        handleClickLinkedId
                                                                                      }
                                                                                      isColorChange={
                                                                                        isColorChange
                                                                                      }
                                                                                      handleUpdateIdList={
                                                                                        handleUpdateIdList
                                                                                      }
                                                                                      category={
                                                                                        data
                                                                                      }
                                                                                    />
                                                                                  </div>
                                                                                </Grid>
                                                                              ) : (
                                                                                <Grid
                                                                                  item
                                                                                  md={
                                                                                    6
                                                                                  }
                                                                                  xs={
                                                                                    12
                                                                                  }
                                                                                  lg={
                                                                                    4
                                                                                  }
                                                                                >
                                                                                  <CardCommon
                                                                                    backgroundColor={
                                                                                      "default"
                                                                                    }
                                                                                    style={{
                                                                                      borderRadius:
                                                                                        "10px",
                                                                                      marginTop:
                                                                                        "14px",
                                                                                    }}
                                                                                  >
                                                                                    <CategoryMenuList
                                                                                      item={
                                                                                        data5
                                                                                      }
                                                                                      removedLinkedIdList={
                                                                                        removedLinkedIdList
                                                                                      }
                                                                                      handleUpdateIdList={
                                                                                        handleUpdateIdList
                                                                                      }
                                                                                      index={
                                                                                        0
                                                                                      }
                                                                                      data={
                                                                                        data5
                                                                                      }
                                                                                      isColorChange={
                                                                                        isColorChange
                                                                                      }
                                                                                      revertExpand={
                                                                                        revertExpand
                                                                                      }
                                                                                      handleSelectProduct={
                                                                                        handleSelectProduct
                                                                                      }
                                                                                      category={
                                                                                        category
                                                                                      }
                                                                                      selectedWrapperIdList={
                                                                                        selectedWrapperIdList
                                                                                      }
                                                                                      setSelectedWrapperIdList={
                                                                                        setSelectedWrapperIdList
                                                                                      }
                                                                                      isOwner={
                                                                                        isOwner
                                                                                      }
                                                                                      editableRewardDetails={
                                                                                        editableRewardDetails
                                                                                      }
                                                                                    />
                                                                                  </CardCommon>
                                                                                </Grid>
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        {data5?.wrapper &&
                                                                          data5.wrapper.map(
                                                                            (
                                                                              data6: any,
                                                                              index: any,
                                                                            ) => {
                                                                              return (
                                                                                <>
                                                                                  {removedLinkedIdLists[
                                                                                    data[
                                                                                      "directoryId"
                                                                                    ].toString()
                                                                                  ]
                                                                                    .directoryLevel +
                                                                                    1 ===
                                                                                    data6.directoryLevel &&
                                                                                    removedLinkedIdLists[
                                                                                      data[
                                                                                        "directoryId"
                                                                                      ].toString()
                                                                                    ]
                                                                                      .id ===
                                                                                      data6.directoryId && (
                                                                                      <>
                                                                                        {index ===
                                                                                          0 && (
                                                                                          <Grid
                                                                                            item
                                                                                            xs={
                                                                                              12
                                                                                            }
                                                                                            style={{
                                                                                              display:
                                                                                                "flex",
                                                                                              justifyContent:
                                                                                                "start",
                                                                                              alignItems:
                                                                                                "center",
                                                                                            }}
                                                                                          >
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  product,
                                                                                                  1,
                                                                                                  data,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  product.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data,
                                                                                                  2,
                                                                                                  data,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data1,
                                                                                                  3,
                                                                                                  data,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data1.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data2,
                                                                                                  4,
                                                                                                  data,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data2.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data3,
                                                                                                  5,
                                                                                                  data,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data3.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data4,
                                                                                                  6,
                                                                                                  data,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data4.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Typography variant="h6">
                                                                                              {
                                                                                                "->"
                                                                                              }
                                                                                            </Typography>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleNavigate(
                                                                                                  data5,
                                                                                                  7,
                                                                                                  data,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <Typography variant="h6">
                                                                                                {
                                                                                                  data5.name
                                                                                                }
                                                                                              </Typography>
                                                                                            </Button>
                                                                                            <Button
                                                                                              onClick={() =>
                                                                                                handleClearStrip(
                                                                                                  product,
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <HighlightOffIcon />
                                                                                            </Button>
                                                                                          </Grid>
                                                                                        )}
                                                                                        {data6
                                                                                          ?.wrapper
                                                                                          ?.length >
                                                                                        0 ? (
                                                                                          <Grid
                                                                                            item
                                                                                            md={
                                                                                              6
                                                                                            }
                                                                                            xs={
                                                                                              12
                                                                                            }
                                                                                            lg={
                                                                                              4
                                                                                            }
                                                                                          >
                                                                                            <div>
                                                                                              <CategoryMenuListStrip
                                                                                                item={
                                                                                                  data6
                                                                                                }
                                                                                                handleClickLinkedId={
                                                                                                  handleClickLinkedId
                                                                                                }
                                                                                                isColorChange={
                                                                                                  isColorChange
                                                                                                }
                                                                                                handleUpdateIdList={
                                                                                                  handleUpdateIdList
                                                                                                }
                                                                                                category={
                                                                                                  data
                                                                                                }
                                                                                              />
                                                                                            </div>
                                                                                          </Grid>
                                                                                        ) : (
                                                                                          <Grid
                                                                                            item
                                                                                            md={
                                                                                              6
                                                                                            }
                                                                                            xs={
                                                                                              12
                                                                                            }
                                                                                            lg={
                                                                                              4
                                                                                            }
                                                                                          >
                                                                                            <CardCommon
                                                                                              backgroundColor={
                                                                                                "default"
                                                                                              }
                                                                                              style={{
                                                                                                borderRadius:
                                                                                                  "10px",
                                                                                                marginTop:
                                                                                                  "14px",
                                                                                              }}
                                                                                            >
                                                                                              <CategoryMenuList
                                                                                                item={
                                                                                                  data6
                                                                                                }
                                                                                                removedLinkedIdList={
                                                                                                  removedLinkedIdList
                                                                                                }
                                                                                                handleUpdateIdList={
                                                                                                  handleUpdateIdList
                                                                                                }
                                                                                                index={
                                                                                                  0
                                                                                                }
                                                                                                data={
                                                                                                  data6
                                                                                                }
                                                                                                isColorChange={
                                                                                                  isColorChange
                                                                                                }
                                                                                                revertExpand={
                                                                                                  revertExpand
                                                                                                }
                                                                                                handleSelectProduct={
                                                                                                  handleSelectProduct
                                                                                                }
                                                                                                category={
                                                                                                  category
                                                                                                }
                                                                                                selectedWrapperIdList={
                                                                                                  selectedWrapperIdList
                                                                                                }
                                                                                                setSelectedWrapperIdList={
                                                                                                  setSelectedWrapperIdList
                                                                                                }
                                                                                                isOwner={
                                                                                                  isOwner
                                                                                                }
                                                                                                editableRewardDetails={
                                                                                                  editableRewardDetails
                                                                                                }
                                                                                              />
                                                                                            </CardCommon>
                                                                                          </Grid>
                                                                                        )}
                                                                                      </>
                                                                                    )}

                                                                                  {data6?.wrapper &&
                                                                                    data6.wrapper.map(
                                                                                      (
                                                                                        data7: any,
                                                                                        index: any,
                                                                                      ) => {
                                                                                        return (
                                                                                          <>
                                                                                            {removedLinkedIdLists[
                                                                                              data[
                                                                                                "directoryId"
                                                                                              ].toString()
                                                                                            ]
                                                                                              .directoryLevel +
                                                                                              1 ===
                                                                                              data7.directoryLevel &&
                                                                                              removedLinkedIdLists[
                                                                                                data[
                                                                                                  "directoryId"
                                                                                                ].toString()
                                                                                              ]
                                                                                                .id ===
                                                                                                data7.directoryId && (
                                                                                                <>
                                                                                                  {index ===
                                                                                                    0 && (
                                                                                                    <Grid
                                                                                                      item
                                                                                                      xs={
                                                                                                        12
                                                                                                      }
                                                                                                      style={{
                                                                                                        display:
                                                                                                          "flex",
                                                                                                        justifyContent:
                                                                                                          "start",
                                                                                                        alignItems:
                                                                                                          "center",
                                                                                                      }}
                                                                                                    >
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleNavigate(
                                                                                                            product,
                                                                                                            1,
                                                                                                            data,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            product.name
                                                                                                          }
                                                                                                        </Typography>
                                                                                                      </Button>
                                                                                                      <Typography variant="h6">
                                                                                                        {
                                                                                                          "->"
                                                                                                        }
                                                                                                      </Typography>
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleNavigate(
                                                                                                            data,
                                                                                                            2,
                                                                                                            data,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            data.name
                                                                                                          }
                                                                                                        </Typography>
                                                                                                      </Button>
                                                                                                      <Typography variant="h6">
                                                                                                        {
                                                                                                          "->"
                                                                                                        }
                                                                                                      </Typography>
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleNavigate(
                                                                                                            data1,
                                                                                                            3,
                                                                                                            data,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            data1.name
                                                                                                          }
                                                                                                        </Typography>
                                                                                                      </Button>
                                                                                                      <Typography variant="h6">
                                                                                                        {
                                                                                                          "->"
                                                                                                        }
                                                                                                      </Typography>
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleNavigate(
                                                                                                            data2,
                                                                                                            4,
                                                                                                            data,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            data2.name
                                                                                                          }
                                                                                                        </Typography>
                                                                                                      </Button>
                                                                                                      <Typography variant="h6">
                                                                                                        {
                                                                                                          "->"
                                                                                                        }
                                                                                                      </Typography>
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleNavigate(
                                                                                                            data3,
                                                                                                            5,
                                                                                                            data,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            data3.name
                                                                                                          }
                                                                                                        </Typography>
                                                                                                      </Button>
                                                                                                      <Typography variant="h6">
                                                                                                        {
                                                                                                          "->"
                                                                                                        }
                                                                                                      </Typography>
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleNavigate(
                                                                                                            data4,
                                                                                                            6,
                                                                                                            data,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            data4.name
                                                                                                          }
                                                                                                        </Typography>
                                                                                                      </Button>
                                                                                                      <Typography variant="h6">
                                                                                                        {
                                                                                                          "->"
                                                                                                        }
                                                                                                      </Typography>
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleNavigate(
                                                                                                            data5,
                                                                                                            7,
                                                                                                            data,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            data5.name
                                                                                                          }
                                                                                                        </Typography>
                                                                                                      </Button>
                                                                                                      <Typography variant="h6">
                                                                                                        {
                                                                                                          "->"
                                                                                                        }
                                                                                                      </Typography>
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleNavigate(
                                                                                                            data6,
                                                                                                            8,
                                                                                                            data,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <Typography variant="h6">
                                                                                                          {
                                                                                                            data6.name
                                                                                                          }
                                                                                                        </Typography>
                                                                                                      </Button>
                                                                                                      <Button
                                                                                                        onClick={() =>
                                                                                                          handleClearStrip(
                                                                                                            product,
                                                                                                          )
                                                                                                        }
                                                                                                      >
                                                                                                        <HighlightOffIcon />
                                                                                                      </Button>
                                                                                                    </Grid>
                                                                                                  )}
                                                                                                  {data7
                                                                                                    ?.wrapper
                                                                                                    ?.length >
                                                                                                  0 ? (
                                                                                                    <Grid
                                                                                                      item
                                                                                                      md={
                                                                                                        6
                                                                                                      }
                                                                                                      xs={
                                                                                                        12
                                                                                                      }
                                                                                                      lg={
                                                                                                        4
                                                                                                      }
                                                                                                    >
                                                                                                      <div>
                                                                                                        <CategoryMenuListStrip
                                                                                                          item={
                                                                                                            data7
                                                                                                          }
                                                                                                          handleClickLinkedId={
                                                                                                            handleClickLinkedId
                                                                                                          }
                                                                                                          isColorChange={
                                                                                                            isColorChange
                                                                                                          }
                                                                                                          handleUpdateIdList={
                                                                                                            handleUpdateIdList
                                                                                                          }
                                                                                                          category={
                                                                                                            data
                                                                                                          }
                                                                                                        />
                                                                                                      </div>
                                                                                                    </Grid>
                                                                                                  ) : (
                                                                                                    <Grid
                                                                                                      item
                                                                                                      md={
                                                                                                        6
                                                                                                      }
                                                                                                      xs={
                                                                                                        12
                                                                                                      }
                                                                                                      lg={
                                                                                                        4
                                                                                                      }
                                                                                                    >
                                                                                                      <CardCommon
                                                                                                        backgroundColor={
                                                                                                          "default"
                                                                                                        }
                                                                                                        style={{
                                                                                                          borderRadius:
                                                                                                            "10px",
                                                                                                          marginTop:
                                                                                                            "14px",
                                                                                                        }}
                                                                                                      >
                                                                                                        <CategoryMenuList
                                                                                                          item={
                                                                                                            data7
                                                                                                          }
                                                                                                          removedLinkedIdList={
                                                                                                            removedLinkedIdList
                                                                                                          }
                                                                                                          handleUpdateIdList={
                                                                                                            handleUpdateIdList
                                                                                                          }
                                                                                                          index={
                                                                                                            0
                                                                                                          }
                                                                                                          data={
                                                                                                            data7
                                                                                                          }
                                                                                                          isColorChange={
                                                                                                            isColorChange
                                                                                                          }
                                                                                                          revertExpand={
                                                                                                            revertExpand
                                                                                                          }
                                                                                                          handleSelectProduct={
                                                                                                            handleSelectProduct
                                                                                                          }
                                                                                                          category={
                                                                                                            category
                                                                                                          }
                                                                                                          selectedWrapperIdList={
                                                                                                            selectedWrapperIdList
                                                                                                          }
                                                                                                          setSelectedWrapperIdList={
                                                                                                            setSelectedWrapperIdList
                                                                                                          }
                                                                                                          isOwner={
                                                                                                            isOwner
                                                                                                          }
                                                                                                          editableRewardDetails={
                                                                                                            editableRewardDetails
                                                                                                          }
                                                                                                        />
                                                                                                      </CardCommon>
                                                                                                    </Grid>
                                                                                                  )}
                                                                                                </>
                                                                                              )}
                                                                                          </>
                                                                                        );
                                                                                      },
                                                                                    )}
                                                                                </>
                                                                              );
                                                                            },
                                                                          )}
                                                                      </>
                                                                    );
                                                                  },
                                                                )}
                                                            </>
                                                          );
                                                        },
                                                      )}
                                                  </>
                                                );
                                              },
                                            )}
                                        </>
                                      );
                                    },
                                  )}
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </Grid>
              </CardCommon>
            </Grid>
          )}
      </>
    );
  };
  return (
    <Grid container spacing={2}>
      {Object.values(wrapperProduct).map((data: any) => {
        return (
          <React.Fragment key={data.id}>{handleWrapper(data)}</React.Fragment>
        );
      })}
    </Grid>
  );
};

export default ProductWrapper;
