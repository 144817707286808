import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import WithLoading from "../../../../utils/WithLoading";
import BannerInfoNode from "./BannerInfoNode";
import BannerHeader from "./BannerHeader";
import CreateNewBanner from "./CreateNewBanner";
import BannerCreateAndUpdateModal from "./BannerCreateAndUpdateModal";
import {
  createNewBannerInfo,
  deleteBannerInfo,
  updateBannerInfo,
} from "../../../../services/customerApp/customerDetails";
import {
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

export interface CustomerNodeProps {
  isLoading: any;
  bannerNode: any;
  isOwner: any;
}

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

const BannerNode: React.FunctionComponent<CustomerNodeProps> = ({
  isLoading,
  bannerNode,
  isOwner,
}) => {
  const [bannerList, setBannerList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isOpenBannerAddEditModal, setIsOpenBannerAddEditModal] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const [isError, setIsError] = useState(false);
  const [bannerId, setBannerId] = useState("");
  const [success, setSuccess] = useState("");

  const match: any = useRouteMatch();

  useEffect(() => {
    if (!_.isEmpty(bannerNode)) {
      setBannerList(bannerNode);
    }
  }, [bannerNode]);

  const handleOpenBannerAddModal = () => {
    setIsOpenBannerAddEditModal(true);
  };

  const handleReset = () => {
    setIsEdit(false);
    setIsOpenBannerAddEditModal(false);
  };

  const handleCreateBanner = async () => {
    try {
      const formData = {
        title: title,
        subTitle: subTitle,
        description: description,
        img: img,
      };
      const res = await createNewBannerInfo(match.params.locationId, formData);
      const cloneBannerList = _.cloneDeep(bannerList);
      cloneBannerList.push(res.data.data);
      setBannerList(cloneBannerList);
      setIsLoadingButton(false);
      setIsEdit(false);
      setIsOpenBannerAddEditModal(false);
      setBannerId("");
      setTitle("");
      setSubTitle("");
      setDescription("");
      setImg("");
      setSuccess(SUCCESSFULLY_CREATED);
    } catch (error) {
      setIsError(true);
    }
  };

  const handleUpdateBanner = async () => {
    try {
      const formData = {
        bannerId,
        title: title,
        subTitle: subTitle,
        description: description,
        img: img,
      };
      const res = await updateBannerInfo(
        match.params.locationId,
        bannerId,
        formData,
      );
      const cloneBannerList = _.cloneDeep(bannerList);
      const {
        title: resTitle,
        subTitle: resSubTitle,
        description: resDescription,
        img: resImg,
      } = res.data.data;
      cloneBannerList.map((data: any) => {
        if (data.bannerId === bannerId) {
          data["title"] = resTitle;
          data["subTitle"] = resSubTitle;
          data["description"] = resDescription;
          data["img"] = resImg;
        }
      });
      setBannerList(cloneBannerList);
      setIsLoadingButton(false);
      setIsEdit(false);

      setBannerId("");
      setTitle("");
      setSubTitle("");
      setDescription("");
      setImg("");

      setIsOpenBannerAddEditModal(false);
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      setIsError(true);
    }
  };

  const handleSubmitData = () => {
    if (!title) {
      setIsError(true);
    } else {
      setIsLoadingButton(true);
      setIsError(false);
      if (isEdit) {
        handleUpdateBanner();
      } else {
        handleCreateBanner();
      }
    }
  };

  const handleEditBannerDetails = (banner: any) => {
    const { bannerId, title, subTitle, description, img } = banner;
    setBannerId(bannerId);
    setTitle(title);
    setSubTitle(subTitle);
    setDescription(description);
    setImg(img);
    setIsEdit(true);
    setIsOpenBannerAddEditModal(true);
  };

  const handleDeleteBannerDetails = async (bannerId: any) => {
    try {
      const res = await deleteBannerInfo(match.params.locationId, bannerId);
      const cloneBannerList = _.cloneDeep(bannerList);
      const filterCloneBannerList = cloneBannerList.filter(
        (data: any) => data.bannerId !== res.data.data.bannerId,
      );
      setBannerList(filterCloneBannerList);
      setIsLoadingButton(false);
      setIsEdit(false);
      setIsOpenBannerAddEditModal(false);
      setBannerId("");
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <>
      <CreateNewBanner
        handleOpenBannerAddModal={handleOpenBannerAddModal}
        isOwner={isOwner}
      />
      {bannerList && <BannerHeader />}
      <Grid container>
        {bannerList ? (
          bannerList.map((bannerInfo: any) => (
            <Grid
              item
              xs={12}
              key={bannerInfo.bannerId}
              style={{ marginTop: "8px" }}
            >
              <BannerInfoNode
                banner={bannerInfo}
                handleEditBannerDetails={handleEditBannerDetails}
                handleDeleteBannerDetails={handleDeleteBannerDetails}
                isOwner={isOwner}
              />
            </Grid>
          ))
        ) : (
          <Typography style={{ marginTop: "12%" }} variant="h5">
            No Banner
          </Typography>
        )}
      </Grid>

      <BannerCreateAndUpdateModal
        isOpen={isOpenBannerAddEditModal}
        setIsOpen={setIsOpenBannerAddEditModal}
        isEdit={isEdit}
        handleReset={handleReset}
        isLoadingButton={isLoadingButton}
        handleSubmitData={handleSubmitData}
        title={title}
        subTitle={subTitle}
        description={description}
        img={img}
        isError={isError}
        setIsError={setIsError}
        setTitle={setTitle}
        setSubTitle={setSubTitle}
        setDescription={setDescription}
        setImg={setImg}
      />

      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default WithLoading(BannerNode);
