import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import WithLoading from "../../../../utils/WithLoading";
import BannerInfoNode from "./BannerInfoNode";
import BannerHeader from "./BannerHeader";
import CreateNewBanner from "./CreateNewBanner";
import BannerCreateAndUpdateModal from "./BannerCreateAndUpdateModal";
import {
  createNewApiKey,
  createNewBannerInfo,
  deleteBannerInfo,
  updateApiKey,
  updateBannerInfo,
} from "../../../../services/customerApp/customerDetails";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import ApiKeyDisplayModal from "./ApiKeyDisplayModal";

export interface CustomerNodeProps {
  isLoading: any;
  bannerNode: any;
  isOwner: any;
}

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

const BannerNode: React.FunctionComponent<CustomerNodeProps> = ({
  isLoading,
  bannerNode,
  isOwner,
}) => {
  const [bannerList, setBannerList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isOpenBannerAddEditModal, setIsOpenBannerAddEditModal] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const [isError, setIsError] = useState(false);
  const [bannerId, setBannerId] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedRoleObj, setSelectedRoleObj] = useState<any>({});
  const [isActive, setIsActive] = useState(true);
  const [apiKey, setApiKey] = useState("");
  const [selectedApiKey, setSelectedApiKey] = useState("");
  const [isOpenApiKeyModal, setIsOpenApiKeyModal] = useState(false);
  const [apiKeyName, setApiKeyName] = useState("");

  const match: any = useRouteMatch();

  useEffect(() => {
    if (!_.isEmpty(bannerNode)) {
      setBannerList(bannerNode);
    }
  }, [bannerNode]);

  const handleOpenBannerAddModal = () => {
    setIsOpenBannerAddEditModal(true);
  };

  const handleReset = () => {
    setIsEdit(false);
    setIsOpenBannerAddEditModal(false);
  };

  const handleCreateBanner = async () => {
    try {
      const formData = {
        role: selectedRoleObj.id,
        api_key_name: apiKeyName,
      };
      const res = await createNewApiKey(match.params.locationId, formData);
      const cloneBannerList = _.cloneDeep(bannerList);
      const apiKey = Object.keys(res.data.data)[0];
      const apiData = Object.values(res.data.data)[0];

      cloneBannerList[apiKey] = apiData;
      setBannerList(cloneBannerList);
      setIsLoadingButton(false);
      setIsEdit(false);
      setIsOpenBannerAddEditModal(false);
      setSuccess(SUCCESSFULLY_CREATED);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);

      setIsError(true);
    }
  };

  const handleUpdateBanner = async () => {
    try {
      const formData = {
        active: isActive,
        api_key: apiKey,
        api_key_name: apiKeyName,
        role: selectedRoleObj.id,
      };
      const res = await updateApiKey(match.params.locationId, formData);
      const cloneBannerList = _.cloneDeep(bannerList);
      // const apiKey = Object.keys(res.data.data)[0];
      const apiData = Object.values(res.data)[0];
      cloneBannerList[apiKey] = apiData;
      setBannerList(cloneBannerList);
      setIsLoadingButton(false);
      setIsEdit(false);

      setBannerId("");
      setTitle("");
      setSubTitle("");
      setDescription("");
      setImg("");

      setIsOpenBannerAddEditModal(false);
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);

      setIsError(true);
    }
  };

  const handleSubmitData = () => {
    setIsLoadingButton(true);
    if (isEdit) {
      handleUpdateBanner();
    } else {
      handleCreateBanner();
    }
  };

  const handleEditBannerDetails = (banner: any) => {
    const { api_key, active, role, api_key_name } = banner;
    setIsActive(active);
    setApiKey(api_key);
    if (role === "ROLE_OWNER") {
      setSelectedRoleObj({ id: role, label: "Owner" });
    } else if (role === "ROLE_MANAGER") {
      setSelectedRoleObj({ id: role, label: "Manager" });
    }
    setApiKeyName(api_key_name);
    setIsEdit(true);
    setIsOpenBannerAddEditModal(true);
  };

  const handleDeleteBannerDetails = async (bannerId: any) => {
    try {
      const res = await deleteBannerInfo(match.params.locationId, bannerId);
      const cloneBannerList = _.cloneDeep(bannerList);
      const filterCloneBannerList = cloneBannerList.filter(
        (data: any) => data.bannerId !== res.data.data.bannerId,
      );
      setBannerList(filterCloneBannerList);
      setIsLoadingButton(false);
      setIsEdit(false);
      setIsOpenBannerAddEditModal(false);
      setBannerId("");
    } catch (error) {
      setIsError(true);
    }
  };

  // Function to copy text
  const handleCopyClick = async (api_key: any) => {
    try {
      await navigator.clipboard.writeText(api_key);
      setSuccess("Copied!");
    } catch (err) {
      setSuccess("Failed to copy!");
    }
  };

  const handleOpenAPIKey = (api_key: any) => {
    setIsOpenApiKeyModal(true);
    setSelectedApiKey(api_key)
  };

  return (
    <>
      <CreateNewBanner
        handleOpenBannerAddModal={handleOpenBannerAddModal}
        isOwner={isOwner}
      />
      {Object.values(bannerList).length > 0 && <BannerHeader />}
      <Grid container>
        {Object.values(bannerList).length > 0 ? (
          Object.values(bannerList).map((bannerInfo: any) => (
            <Grid
              item
              xs={12}
              key={bannerInfo.bannerId}
              style={{ marginTop: "8px" }}
            >
              <BannerInfoNode
                banner={bannerInfo}
                handleEditBannerDetails={handleEditBannerDetails}
                handleDeleteBannerDetails={handleDeleteBannerDetails}
                isOwner={isOwner}
                handleCopyClick={handleCopyClick}
                handleOpenAPIKey={handleOpenAPIKey}
              />
            </Grid>
          ))
        ) : (
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography style={{ marginTop: "12%" }} variant="h5">
              No API Key
            </Typography>
          </Grid>
        )}
      </Grid>

      <BannerCreateAndUpdateModal
        isOpen={isOpenBannerAddEditModal}
        setIsOpen={setIsOpenBannerAddEditModal}
        isEdit={isEdit}
        handleReset={handleReset}
        isLoadingButton={isLoadingButton}
        handleSubmitData={handleSubmitData}
        selectedRoleObj={selectedRoleObj}
        setSelectedRoleObj={setSelectedRoleObj}
        isActive={isActive}
        setIsActive={setIsActive}
        setApiKeyName={setApiKeyName}
        apiKeyName={apiKeyName}
      />

      <ApiKeyDisplayModal
        isOpen={isOpenApiKeyModal}
        setIsOpen={setIsOpenApiKeyModal}
        selectedApiKey={selectedApiKey}
      />

      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default WithLoading(BannerNode);
