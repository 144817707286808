import { axiosClient } from "../client";

const queryString = require("query-string");

const DASHBOARD_SALE_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/dashboard/sales-per-date`;

const DASHBOARD_PAYMENT_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/dashboard/payments-per-date`;

const DASHBOARD_ORDERS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/dashboard/orders-per-date`;

const DASHBOARD_AVERAGE_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/dashboard/amount-per-date`;

const DASHBOARD_PLATFORM_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/dashboard/platform-sales-per-date`;

const DASHBOARD_MODE_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/dashboard/mode-sales-per-date`;

const DASHBOARD_CHANNEL_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/dashboard/channel-sales-per-date`;

const DASHBOARD_SALES_TOTAL_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/dashboard/sales-total`;

const DASHBOARD_SALES_MODE_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/sales/mode`;

const DASHBOARD_SALES_PLATFORMS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/sales/platform`;

const DASHBOARD_SALES_CHANNELS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/reports/sales/channel`;

export const fetchAllDashboardSaleInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_SALE_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardPaymentInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_PAYMENT_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardOrdersInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_ORDERS_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardAverageInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_AVERAGE_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardPlatformInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_PLATFORM_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardModeInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_MODE_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardChannelInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_CHANNEL_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardSalesTotalInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_SALES_TOTAL_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardSalesModeInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_SALES_MODE_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardSalesPlatformsInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,
  signal,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_SALES_PLATFORMS_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

export const fetchAllDashboardSalesChannelsInfo = (
  token,
  locationId,
  filterData,
  basedOnShift,signal
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: DASHBOARD_SALES_CHANNELS_INFO_GET_ALL(locationId),
        query: { where: filterData, based_on_shift: basedOnShift },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },signal
  });
