import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSort } from "@table-library/react-table-library/sort";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Icon } from "@iconify/react";
import GenerateTable from "./GenerateTable";

export interface AvailabilitySelectionModalProps {
  shiftReportNode: any;
  platform?: any;
}

/* Here the necessary data for the table is entered. */
const TableInfo: React.FunctionComponent<AvailabilitySelectionModalProps> = ({
  shiftReportNode,
  platform,
}) => {
  const [tableName, setTableName] = useState("");
  const [headersList, setHeadersList] = useState<any>([]);

  const [footer, setFooter] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesLength, setNodeLength] = useState(0);
  const [nodesHeadersLength, setNodesHeadersLength] = useState(0);
  const [footerLength, setFooterLength] = useState(0);
  const [firstColumnWidth, setFirstColumnWidth] = useState("0%");
  const [columnWidth, setColumnWidth] = useState("0%");
  const [sortFns, setSortFns] = useState<any>({});
  const [max, setMax] = useState("(max-width: 600px)");

  useEffect(() => {
    setNodes({ nodes: [] });
    setHeadersList([]);
  }, [shiftReportNode]);

  const handleSort = (data: any, prev: any, current: any) => {
    if (
      typeof prev[data] === "string" &&
      data !== "Vat" &&
      data !== "Total" &&
      data !== "VAT Rate" &&
      data !== "Amount"
    ) {
      return prev[data].localeCompare(current[data]);
    } else {
      return prev[data] - current[data];
    }
  };

  useEffect(() => {
    if (!_.isEmpty(shiftReportNode)) {
      const { header, data, footer, tableName } = shiftReportNode;
      if (header !== undefined) {
        setTableName(tableName);
        setNodes({ nodes: data });
        setHeadersList(header);
        if (header.length > 4) {
          const result = window.matchMedia("(max-width: 960px)");
          setFirstColumnWidth("28%");
          if (result.matches) {
            setColumnWidth(`20%`);
          } else {
            setColumnWidth(`${72 / (header.length - 1)}%`);
          }
          setMax("(max-width: 960px)");
        } else {
          setFirstColumnWidth(`${100 / header.length}%`);
          setColumnWidth(`${100 / header.length}%`);
        }
        setNodesHeadersLength(header.length);
        setNodeLength(data.length - 1);
        setFooter(footer);
        setFooterLength(footer.length);

        let sortFns: any = {};
        // Tables are sorted using headers.
        header.map(
          (data: any) =>
            (sortFns[data] = (array: any) =>
              array.sort((prev: any, current: any) =>
                handleSort(data, prev, current),
              )),
        );
        setSortFns(sortFns);
      }
    }
  }, [shiftReportNode]);

  const sort = useSort(
    nodes,
    {},
    {
      sortFns: sortFns,
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  return (
    <>
      {!_.isEmpty(nodes) && (
        <GenerateTable
          nodesLength={nodesLength}
          nodesHeadersLength={nodesHeadersLength}
          firstColumnWidth={firstColumnWidth}
          columnWidth={columnWidth}
          tableName={tableName}
          nodes={nodes}
          sort={sort}
          footer={footer}
          headersList={headersList}
          footerLength={footerLength}
          max={max}
          platform={platform}
        />
      )}
    </>
  );
};

export default TableInfo;
