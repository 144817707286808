import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import _ from "lodash";

import { CustomTheme } from "../../../../types/customTheme";
import { Autocomplete } from "@material-ui/lab";
import { getLocalStore } from "../../../../utils/store/localStore";

const useStyles = makeStyles((theme: CustomTheme) => ({
  autoComplete: {
    marginTop: "4px",
    "& .MuiAutocomplete-input": {
      color: theme.palette.custom.orange.contrastText,
    },
    "& .MuiInputBase-root": {
      color: "inherit",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: 10,
    },
    [`& fieldset`]: {
      borderRadius: 10,
      border: "none",
      color: theme.palette.custom.orange.contrastText,
    },
  },
  titleColor: {
    color: theme.palette.custom.orange.contrastText,
  },
}));

export interface passwordAddModalProps {
  handleSelectRole: any;
  selectedRole: any;
}

// Predefined roles for the Autocomplete options
const roles = [
  { id: "ROLE_OWNER", label: "Owner" },
  { id: "ROLE_MANAGER", label: "Manager" },
  { id: "ROLE_ACCOUNTANT", label: "Accountant" },
  { id: "ROLE_STAFF", label: "Staff" },
];



/**
 * RoleSelection Component
 *
 * This component provides a selection interface for user roles based on user authorities.
 * It uses the Material-UI Autocomplete component to display a dropdown with role options.
 */

const RoleSelection: React.FunctionComponent<passwordAddModalProps> = ({
  handleSelectRole,
  selectedRole,
}) => {
  const [open, setOpen] = React.useState(false);
  const [grantedRoles, setGrantedRoles] = useState<any>([]);

  /**
   * useEffect to fetch user authorities from local storage and determine granted roles
   */
  useEffect(() => {
    // Get user authorities from local storage
    const userAuthorities = getLocalStore("authorities");

    // Initialize an array to store granted roles
    let grantedRoles: any = [];

    // Switch statement to determine roles based on user authorities
    switch (userAuthorities.toLowerCase()) {
      case "role_owner":
      case "role_super_admin":
      case "role_admin":
      case "role_prex_dev":
        grantedRoles = roles;
        break;

      // For manager, grant specific roles
      case "role_manager":
        grantedRoles = [
          { id: "ROLE_MANAGER", label: "Manager" },
          { id: "ROLE_ACCOUNTANT", label: "Accountant" },
          { id: "ROLE_STAFF", label: "Staff" },
        ];
        break;

      // For accountant, grant specific roles
      case "role_accountant":
        grantedRoles = [
          { id: "ROLE_ACCOUNTANT", label: "Accountant" },
          { id: "ROLE_STAFF", label: "Staff" },
        ];
        break;

      // For staff, grant staff role only
      case "role_staff":
        grantedRoles = [{ id: "ROLE_STAFF", label: "Staff" }];
        break;

      // Default case for unknown authorities
      default:
        break;
    }

    // Set the granted roles in the state
    setGrantedRoles(grantedRoles);
  }, []);

  const classes = useStyles();

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        size={"small"}
        id="locationSelectGlobal"
        color="inherit"
        options={grantedRoles}
        value={selectedRole}
        getOptionLabel={(option: any) => option.label || ""}
        disableClearable
        onChange={handleSelectRole}
        classes={{ root: classes.autoComplete }}
        popupIcon={<ArrowDropDownIcon color={"inherit"} />}
        renderOption={(props: any) => {
          return (
            <Grid
              container
              {...props}
              style={{
                display: "flex",
                justifyContent: "space-between",
                zIndex: 0,
                height: "20px",
                marginBottom: "4px",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.titleColor}>
                  {props.label}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
        renderInput={(params: any) => (
          <TextField
            color="inherit"
            {...params}
            label=""
            placeholder="Select Role"
            variant="outlined"
          />
        )}
      />
    </>
  );
};

export default RoleSelection;
