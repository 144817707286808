import React from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";

import ValidationMessage from "../../../../components/validation/ValidationMessage";
import { EMAIL_PATTERN_CUSTOMER } from "../../../../utils/consts";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";

export interface permissionAddModalProps {
  register: any;
  errors: any;
}

/**
 * UserDetails component renders input fields for user details (Name, Email)
 * and provides validation messages for form errors.
 */

const UserDetails: React.FunctionComponent<permissionAddModalProps> = ({
  register,
  errors,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextfieldCommon
          id="name"
          name="name"
          type="text"
          label="Name"
          inputRef={register({
            required: "Name is required",
          })}
        />
        {errors.name ? <ValidationMessage message={errors.name.message} /> : ""}
      </Grid>
      <Grid item xs={12}>
        <TextfieldCommon
          id="email"
          name="email"
          type="text"
          label="Email"
          inputRef={register({
            required: "Email is required",
            pattern: {
              value: EMAIL_PATTERN_CUSTOMER,
              message: "Enter a valid email address",
            },
          })}
        />
        {errors.email ? (
          <ValidationMessage message={errors.email.message} />
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default UserDetails;
