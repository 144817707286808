import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Card,
  createStyles,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CardCommon from "../../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../../types/customTheme";

interface styleProps {
  isCashDeducted: any;
}

const useStyles = makeStyles<any, styleProps>((theme: CustomTheme) =>
  createStyles({
    cardStyle: {
      height: "39px",
      boxShadow: "none",
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.background.entity_background,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    cardStyleFooter: {
      height: "39px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: ({ isCashDeducted }) =>
          isCashDeducted
            ? theme.palette.custom.red.main
            : theme.palette.custom.green.main,
        "&:hover": {
          backgroundColor: ({ isCashDeducted }) =>
            isCashDeducted
              ? theme.palette.custom.red.main
              : theme.palette.custom.green.light,
        },
      },
    },
  }),
);

export interface AvailabilitySelectionModalProps {
  shiftReportNode: any;
  isCashDeducted?: any;
}

/* This creates the banking and summery tables. This component only works if the key is banking or summery. */
const BankingPayInOutTable: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({ shiftReportNode, isCashDeducted }) => {
  const [nodes, setNodes] = useState<any>([]);

  useEffect(() => {
    setNodes(shiftReportNode);
  }, [shiftReportNode]);

  const classes = useStyles({ isCashDeducted });
  const theme: CustomTheme = useTheme();
  return (
    <div style={{ marginTop: "16px" }}>
      {nodes.length > 1 && (
        <Card
          style={{
            backgroundColor: theme.palette.background.table_header_background,
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          }}
        >
          <Grid container style={{ placeItems: "center", height: "39px" }}>
            <Grid
              item
              xs={6}
              style={{ paddingLeft: "16px", fontWeight: "bold" }}
            >
              <Typography style={{ display: "flex", alignItems: "center" }}>
              Type
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingRight: "16px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              Amount
            </Grid>
          </Grid>
        </Card>
      )}

      <Card
        style={{
          borderTopLeftRadius: nodes.length > 1 ? "0px" : "10px",
          borderTopRightRadius: nodes.length > 1 ? "0px" : "10px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        {!_.isEmpty(nodes) &&
          nodes.map((data: any, index: any) => (
            <div
              className={
                nodes.length === index + 1
                  ? classes.cardStyleFooter
                  : classes.cardStyle
              }
            >
              <Grid container style={{ placeItems: "center", height: "39px" }}>
                <Grid
                  item
                  xs={6}
                  style={{ paddingLeft: "16px", fontWeight: "bold" }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color:
                        nodes.length === index + 1
                          ? "white"
                          : theme.palette.custom.orange.contrastText,
                    }}
                  >
                    {data.type}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingRight: "16px",
                    display: "flex",
                    justifyContent: "end",
                    color:
                      nodes.length === index + 1
                        ? "white"
                        : theme.palette.custom.orange.contrastText,
                  }}
                >
                  {data.amount}
                </Grid>
              </Grid>
            </div>
          ))}
      </Card>
    </div>
  );
};

export default BankingPayInOutTable;
