import React from "react";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import {
  Button,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import "jspdf-autotable";
import { Icon } from "@iconify/react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CardCommon from "../../../../components/card/CardCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import { convertDateFormat } from "../../../../utils/ConvertDateTimeFormat";
import { CustomTheme } from "../../../../types/customTheme";

export interface CustomerNodeProps {
  banner: any;
  handleEditBannerDetails: any;
  handleDeleteBannerDetails: any;
  isOwner: any;
  handleCopyClick: any;handleOpenAPIKey: any
}

const BannerInfoNode: React.FunctionComponent<CustomerNodeProps> = ({
  banner,
  handleEditBannerDetails,
  handleDeleteBannerDetails,
  isOwner,
  handleCopyClick,handleOpenAPIKey
}) => {
  const theme: CustomTheme = useTheme();
  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Grid container style={{ minHeight: "40px", placeItems: "center" }}>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingLeft: "12px",
            }}
          >
            <Typography align="left"> {banner.api_key_name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              {" "}
              {banner.role
                .replace("ROLE_", "")
                .replace(/_/g, " ")
                .toLowerCase()
                .replace(/\b\w/g, (char: any) => char.toUpperCase())}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{convertDateFormat(banner.created_at)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button style={{ minWidth: "28px" }} onClick={() => handleOpenAPIKey(banner.api_key)}>
              <Typography>
                <Icon
                  icon="solar:mask-happly-bold"
                  height="26"
                  width="26"
                  style={{ paddingTop: "4px" }}
                />
              </Typography>
            </Button>
            <Button style={{ minWidth: "28px" }} onClick={() => handleCopyClick(banner.api_key)}>
              <Typography>
                <FileCopyIcon />
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "12px",
              alignItems: "center",
            }}
          >
            {banner.active ? (
              <Typography
                style={{
                  paddingRight: "16px",
                  color: theme.palette.custom.green.main,
                }}
              >
                <Brightness1Icon />
              </Typography>
            ) : (
              <Typography
                style={{
                  paddingRight: "16px",
                  color: theme.palette.custom.red.main,
                }}
              >
                <Brightness1Icon />
              </Typography>
            )}

            <ButtonCommon
              style={{
                fontSize: 11,
                minWidth: "28px",
                height: "36px",
              }}
              disabled={!isOwner}
              variant="contained"
              color="yellow"
              onClick={() => handleEditBannerDetails(banner)}
            >
              <EditIcon />
            </ButtonCommon>
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default BannerInfoNode;
