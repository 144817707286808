import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import _ from "lodash";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { CustomTheme } from "../../../../types/customTheme";
import SwitchCommon from "../../../../components/switches/SwitchCommon";

const useStyles = makeStyles((theme: any) => createStyles({}));

export interface CategoryMenuListProps {
  isSelectCash: any;
  handleSelectProductOrCash: any;
  setIsOpen: any;
  handleDisable: any;
  level: any;
  handleSubmitData: any;
  isOwner: any;
}

/**
 * StepLevelOne Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const StepLevelOne: React.FunctionComponent<CategoryMenuListProps> = ({
  isSelectCash,
  handleSelectProductOrCash,
  setIsOpen,
  handleDisable,
  level,
  handleSubmitData,
  isOwner,
}) => {
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <>
      <DialogTitle>Add New Rewards</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "12px",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: "0px", marginRight: "0px" }}
                value="dinein"
                control={<SwitchCommon />}
                label={
                  <Typography variant="caption" color="textSecondary">
                    Product
                  </Typography>
                }
                name="dinein"
                checked={isSelectCash}
                disabled={true}
                labelPlacement="start"
                onChange={handleSelectProductOrCash}
              />
              <Typography variant="caption" color="textSecondary">
                Cash
              </Typography>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "8px" }}>
        <ButtonCommon
          variant="contained"
          style={{ fontSize: 11, width: "100px", marginRight: 8 }}
          color="yellow"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          variant="contained"
          style={{
            fontSize: 11,
            marginLeft: 4,
            width: "100px",
          }}
          disabled={handleDisable()}
          color={level === 1 ? "green" : "orange"}
          onClick={handleSubmitData}
        >
          {"Next"}
        </ButtonCommon>
      </DialogActions>
    </>
  );
};

export default StepLevelOne;
