import React from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { convertDateTimeFormatInventory } from "../../../../utils/ConvertDateTimeFormat";

export interface ShiftHeaderProps {
  driverDetails: any;
  ordersLength: any;
  index: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    startTextStyle: {
      display: "flex",
      justifyContent: "start",
      paddingLeft: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: "8px",
      },
    },
    endTextStyle: {
      display: "flex",
      justifyContent: "end",
      paddingRight: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: "8px",
      },
    },
  }),
);

/**
 * DriverSessionsBody Component:
 *
 * This component handles the header section of the Shift component, including
 * sorting controls, location selection, and shift generation. It displays shift
 * information in a table-like format with sortable columns and provides options
 * for selecting shifts, generating reports, and handling user interactions.
 */
const DriverSessionsBody: React.FunctionComponent<ShiftHeaderProps> = ({
  driverDetails,
  ordersLength,
  index,
}) => {
  const matchesLargeScreen = useMediaQuery("(max-width: 960px)");
  const theme: CustomTheme = useTheme();
  let headerRendered = false;
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <Typography
                variant="h5"
                style={{ fontWeight: "bold" }}
                align="left"
              >
                {driverDetails.cmsDriverName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CardCommon backgroundColor={"entity_background"}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px",
                  }}
                >
                  <div>
                    <Typography align="left" variant="body2">
                      Shift Start
                    </Typography>
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                    >
                      {convertDateTimeFormatInventory(driverDetails.start)}
                    </Typography>
                    <Typography align="left" variant="body1">
                      {`logged Time: ${
                        driverDetails.startRecorded
                          ? convertDateTimeFormatInventory(
                              driverDetails.startRecorded,
                            )
                          : "-"
                      }`}
                    </Typography>

                    {driverDetails &&
                      driverDetails?.log &&
                      driverDetails?.log?.length > 0 &&
                      driverDetails.log.map(
                        (logInfo: any) =>
                          logInfo.type === "Clock In" && (
                            <Typography
                              style={{
                                color: theme.palette.custom.red.main,
                                fontWeight: "bold",
                              }}
                              align="left"
                              variant="body1"
                            >
                              {logInfo.notes}
                            </Typography>
                          ),
                      )}
                  </div>
                  <div>
                    <Typography align="right" variant="body2">
                      Shift End
                    </Typography>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                    >
                      {convertDateTimeFormatInventory(driverDetails.end)}
                    </Typography>
                    <Typography align="right" variant="body1">
                      {`logged Time: ${
                        driverDetails.endRecorded
                          ? convertDateTimeFormatInventory(
                              driverDetails.endRecorded,
                            )
                          : "-"
                      }`}
                    </Typography>

                    {driverDetails &&
                      driverDetails?.log &&
                      driverDetails?.log?.length > 0 &&
                      driverDetails.log.map(
                        (logInfo: any) =>
                          logInfo.type === "Clock Out" && (
                            <Typography
                              style={{
                                color: theme.palette.custom.red.main,
                                fontWeight: "bold",
                              }}
                              align="left"
                              variant="body1"
                            >
                              {logInfo.notes}
                            </Typography>
                          ),
                      )}
                  </div>
                </div>
              </CardCommon>
            </Grid>
            {driverDetails &&
              driverDetails?.log &&
              driverDetails?.log?.length > 0 &&
              driverDetails.log.map((logInfo: any, index: number) => {
                // Only proceed for entries that are not "Clock In" or "Clock Out"
                if (
                  logInfo.type !== "Clock In" &&
                  logInfo.type !== "Clock Out"
                ) {
                  // Render the header only if it hasn't been rendered yet
                  if (!headerRendered) {
                    headerRendered = true; // Mark the header as rendered after the first valid entry
                    return (
                      <React.Fragment key={index}>
                        {/* Header */}
                        <Grid
                          container
                          style={{
                            marginTop: "16px",
                            marginLeft: "6px",
                            marginRight: "6px",
                          }}
                        >
                          <Grid item xs={12}>
                            <CardCommon
                              backgroundColor={"entity_background"}
                              style={{ height: "36px" }}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    align="left"
                                    style={{
                                      paddingLeft: "12px",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    Type
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography style={{ paddingTop: "3px" }}>
                                    Amount
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography style={{ paddingTop: "3px" }}>
                                    Notes
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography
                                    align="right"
                                    style={{
                                      paddingRight: "12px",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    Date
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardCommon>
                          </Grid>
                        </Grid>

                        <Grid container style={{ margin: "1px 6px" }}>
                          <Grid item xs={12}>
                            <CardCommon
                              backgroundColor={"entity_highlight_background"}
                              style={{ height: "36px" }}
                            >
                              <Grid container>
                                <Grid item xs={3}>
                                  <Typography
                                    align="left"
                                    style={{
                                      paddingLeft: "12px",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    {logInfo.type}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography style={{ paddingTop: "3px" }}>
                                    {logInfo.amount}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography style={{ paddingTop: "3px" }}>
                                    {logInfo.notes}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography
                                    align="right"
                                    style={{
                                      paddingRight: "12px",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    {convertDateTimeFormatInventory(
                                      logInfo.date,
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardCommon>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    );
                  }
                  {
                    /* Body */
                  }
                  return (
                    <Grid container style={{ margin: "0px  6px" }}>
                      <Grid item xs={12}>
                        <CardCommon
                          backgroundColor={"entity_highlight_background"}
                          style={{ height: "36px" }}
                        >
                          <Grid container>
                            <Grid item xs={3}>
                              <Typography
                                align="left"
                                style={{
                                  paddingLeft: "12px",
                                  paddingTop: "3px",
                                }}
                              >
                                {logInfo.type}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography style={{ paddingTop: "3px" }}>
                                {logInfo.amount}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography style={{ paddingTop: "3px" }}>
                                {logInfo.notes}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                align="right"
                                style={{
                                  paddingRight: "12px",
                                  paddingTop: "3px",
                                }}
                              >
                                {convertDateTimeFormatInventory(logInfo.date)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardCommon>
                      </Grid>
                    </Grid>
                  );
                }
              })}
            <Grid
              item
              xs={12}
              style={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "19%",
                }}
              >
                <div>
                  <Typography variant={"body2"}>CASH COLLECT</Typography>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {`£ ${parseFloat(driverDetails.cashCollect).toFixed(2)}`}
                  </Typography>
                </div>
              </CardCommon>

              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "19%",
                }}
              >
                <div>
                  <Typography variant={"body2"}>DELIVERY WAGE</Typography>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {`£ ${parseFloat(driverDetails.milageFee).toFixed(2)}`}
                  </Typography>
                </div>
              </CardCommon>

              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "19%",
                }}
              >
                <div>
                  <Typography variant={"body2"}>DAILY WAGE</Typography>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    <div>
                      {`£ ${(
                        parseFloat(driverDetails.fixedFee) +
                        parseFloat(driverDetails.adjustments)
                      ).toFixed(2)}`}
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: "normal" }}>
                      {parseFloat(driverDetails.adjustments).toFixed(0) !==
                        "0" &&
                        `Adjustment: ${parseFloat(
                          driverDetails.adjustments,
                        ).toFixed(2)}`}
                    </div>
                  </Typography>
                </div>
              </CardCommon>

              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "19%",
                }}
              >
                <div>
                  <Typography variant={"body2"}>TOTAL WAGE</Typography>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {`£ ${parseFloat(
                      (
                        parseFloat(driverDetails.fixedFee) +
                        parseFloat(driverDetails.milageFee) +
                        parseFloat(driverDetails.adjustments)
                      ).toString(),
                    ).toFixed(2)}`}
                  </Typography>
                </div>
              </CardCommon>

              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "19%",
                }}
              >
                <Grid container>
                  <Grid item xs={4}>
                    <div>
                      <Typography variant={"body2"}>ORDERS</Typography>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "bold", marginTop: "8px" }}
                      >
                        {
                          ordersLength[
                            `${index}_${driverDetails.cmsDriverName}_count_orders`
                          ]
                        }
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography variant={"body2"}>RE-DROPS</Typography>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "bold", marginTop: "8px" }}
                      >
                        {
                          ordersLength[
                            `${index}_${driverDetails.cmsDriverName}_count_orders_redrops`
                          ]
                        }
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div>
                      <Typography variant={"body2"}>TOTAL</Typography>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "bold", marginTop: "8px" }}
                      >
                        {ordersLength[
                          `${index}_${driverDetails.cmsDriverName}_count_orders`
                        ] +
                          ordersLength[
                            `${index}_${driverDetails.cmsDriverName}_count_orders_redrops`
                          ]}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </CardCommon>
            </Grid>
          </Grid>
          {driverDetails?.orders ? (
            <>
              {!matchesLargeScreen && (
                <Grid container style={{ marginTop: "28px" }}>
                  <Grid item xs={12}>
                    <CardCommon backgroundColor={"entity_background"}>
                      <Grid
                        container
                        style={{ height: "36px", placeItems: "center" }}
                      >
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            paddingLeft: "16px",
                          }}
                        >
                          Customer Name
                        </Grid>
                        <Grid item xs={4}>
                          Address
                        </Grid>
                        <Grid item xs={2}>
                          Payment Type
                        </Grid>
                        <Grid item xs={2}>
                          Driver Earning
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "16px",
                          }}
                        >
                          Distance
                        </Grid>
                      </Grid>
                    </CardCommon>
                  </Grid>
                </Grid>
              )}
              {driverDetails?.orders.map((order: any, index: any) => (
                <>
                  <Grid
                    container
                    style={{
                      marginTop:
                        index === 0 && matchesLargeScreen ? "24px" : "2px",
                    }}
                  >
                    <Grid item xs={12}>
                      <CardCommon
                        color={order?.isRedrop && "red"}
                        backgroundColor={
                          index % 2 === 0
                            ? "entity_highlight_background"
                            : "entity_background"
                        }
                      >
                        <Grid
                          container
                          style={{
                            minHeight: "36px",
                            placeItems: "center",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.startTextStyle}
                          >
                            <Typography
                              variant={matchesLargeScreen ? "h6" : "body1"}
                              style={{
                                color: order?.isRedrop
                                  ? "white"
                                  : theme.palette.custom.orange.contrastText,
                              }}
                            >
                              {order.customerName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography
                              variant={matchesLargeScreen ? "h6" : "body1"}
                              style={{
                                color: order?.isRedrop
                                  ? "white"
                                  : theme.palette.custom.orange.contrastText,
                              }}
                            >
                              {order.address}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Typography
                              variant={matchesLargeScreen ? "h6" : "body1"}
                              style={{
                                color: order?.isRedrop
                                  ? "white"
                                  : theme.palette.custom.orange.contrastText,
                              }}
                            >
                              {order.paymentType}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={2}>
                            <Typography
                              variant={matchesLargeScreen ? "h6" : "body1"}
                              style={{
                                color: order?.isRedrop
                                  ? "white"
                                  : theme.palette.custom.orange.contrastText,
                              }}
                            >
                              {parseFloat(order.driverEarning).toFixed(2)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.endTextStyle}
                          >
                            <Typography
                              variant={matchesLargeScreen ? "h6" : "body1"}
                              style={{
                                color: order?.isRedrop
                                  ? "white"
                                  : theme.palette.custom.orange.contrastText,
                              }}
                            >
                              {`${parseFloat(order.drivingDistance).toFixed(
                                2,
                              )} Miles`}
                            </Typography>
                          </Grid>
                          {order?.isRedrop && (
                            <Grid
                              item
                              xs={12}
                              className={classes.startTextStyle}
                            >
                              <Typography
                                variant={matchesLargeScreen ? "h6" : "body1"}
                                style={{
                                  color: order?.isRedrop
                                    ? "white"
                                    : theme.palette.custom.orange.contrastText,
                                }}
                              >
                                {order?.comments}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </CardCommon>
                    </Grid>
                  </Grid>
                </>
              ))}
            </>
          ) : (
            <Grid container style={{ marginTop: "28px" }}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  This driver has not orders.
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      </Grid>
    </>
  );
};

export default DriverSessionsBody;
