import { Grid, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import CardCommon from "../../../../components/card/CardCommon";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

export interface StrategyInfoNodeProps {
  nodeData: any;
  handleOpenEditModal: any;
  handleDeactivateStrategy: any;
  isLoadingButtonDeactivate: any;
  handleOpenRewardsAddModal: any;
  isOwner: any;
}

/**
 * Component: StrategyInfoNode
 *
 * This component represents a node for displaying information about a discount strategy.
 * It includes the strategy name, options to edit, deactivate/activate, and duplicate the strategy.
 */
const StrategyInfoNode: React.FunctionComponent<StrategyInfoNodeProps> = ({
  nodeData,
  handleOpenEditModal,
  handleDeactivateStrategy,
  isLoadingButtonDeactivate,
  handleOpenRewardsAddModal,
  isOwner,
}) => {
  const [name, setName] = useState("");
  const [success, setSuccess] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [strategyId, setStrategyId] = useState("");

  /**
   * Function: setNodeDataToState
   *
   * This function is a useCallback hook used to update the state variables strategyName, isActive, and id based on the provided nodeValues.
   */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { name, isActive } = nodeValues;
    setName(name);
    setIsActive(isActive);
    setStrategyId(strategyId);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const screenWidth = useMediaQuery("(max-width: 600px)");

  return (
    <Grid style={{ width: "100%" }}>
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <CardCommon backgroundColor={"entity_background"}>
        <Grid
          container
          style={{
            height: screenWidth ? "100px" : "60px",
            placeItems: "center",
          }}
        >
          <Grid item xs={12} sm={6} style={{ paddingLeft: "12px" }}>
            <Typography variant="h5" align="left">
              {name}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={2} style={{ paddingLeft: "12px" }}>
            <Typography variant="body2" align="left">
              {`${
                nodeData?.rewards?.length ? nodeData?.rewards?.length : 0
              } rewards`}
            </Typography>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "12px",
              paddingLeft: screenWidth ? "12px" : "0px",
              marginBottom: screenWidth ? "6px" : "0px",
            }}
          >
            {/* <ButtonCommon
              style={{
                fontSize: 11,
                marginRight: 12,
                width: "100px",
              }}
              disabled={!isOwner}
              variant="contained"
              color="yellow"
              onClick={() => handleOpenEditModal(nodeData)}
            >
              Edit
            </ButtonCommon> */}
            <ButtonCommon
              isLoadingPage={isLoadingButtonDeactivate?.includes(strategyId)}
              variant="contained"
              disabled={!isOwner}
              style={{ fontSize: 11, marginRight: 12, width: "100px" }}
              color={isActive ? "red" : "green"}
              onClick={() => handleDeactivateStrategy(nodeData, isActive)}
            >
              {isActive ? "Deactivate" : "Activate"}
            </ButtonCommon>
            <ButtonCommon
              style={{
                fontSize: 11,
                marginRight: 12,
                width: "100px",
              }}
              disabled={!isOwner}
              variant="contained"
              color="yellow"
              onClick={() => handleOpenEditModal(nodeData)}
            >
              Edit
            </ButtonCommon>
          </Grid>
        </Grid>
      </CardCommon>
    </Grid>
  );
};

export default withAuthority(StrategyInfoNode, Authorities.DISCOUNT_READ);
