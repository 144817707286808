import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import CancelIcon from "@material-ui/icons/Cancel";
import CardCommon from "../../../../components/card/CardCommon";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { CustomTheme } from "../../../../types/customTheme";

export interface ShiftHeaderProps {
  open: any;
  setOpen: any;
  driverDetails: any;
  totalModalName: any;
  totalOrders: any;
  totalBox: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    startTextStyle: {
      display: "flex",
      justifyContent: "start",
      paddingLeft: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: "8px",
      },
    },
    endTextStyle: {
      display: "flex",
      justifyContent: "end",
      paddingRight: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: "8px",
      },
    },
  }),
);

/**
 * TotalModal Component:
 *
 * This component handles the header section of the Shift component, including
 * sorting controls, location selection, and shift generation. It displays shift
 * information in a table-like format with sortable columns and provides options
 * for selecting shifts, generating reports, and handling user interactions.
 */
const TotalModal: React.FunctionComponent<ShiftHeaderProps> = ({
  open,
  setOpen,
  driverDetails,
  totalModalName,
  totalOrders,
  totalBox,
}) => {
  const handleModalName = () => {
    if (totalModalName === "cashCollect") {
      return "Cash Collect";
    } else if (totalModalName === "deliveryWage") {
      return "Delivery Wage";
    } else if (totalModalName === "dailyWage") {
      return "Daily Wage";
    } else if (totalModalName === "totalWage") {
      return "Total Wage";
    }
  };

  const handleData = (data: any) => {
    if (totalModalName === "cashCollect") {
      return parseFloat(data["cashCollect"]).toFixed(2);
    } else if (totalModalName === "deliveryWage") {
      return parseFloat(data["milageFee"]).toFixed(2);
    } else if (totalModalName === "dailyWage") {
      return parseFloat(
        (parseFloat(data.fixedFee) + parseFloat(data.adjustments)).toString(),
      ).toFixed(2);
    } else if (totalModalName === "totalWage") {
      return parseFloat(
        (
          parseFloat(data.fixedFee) +
          parseFloat(data.milageFee) +
          parseFloat(data.adjustments)
        ).toFixed(2),
      );
    }
  };

  const handleDailyWage = () => {
    const dailyWageObj: any = { dailyWage: 0, adjustments: 0, totalWage: 0 };
    driverDetails.map((data: any) => {
      dailyWageObj["dailyWage"] = parseFloat(
        (
          parseFloat(dailyWageObj["dailyWage"]) + parseFloat(data.fixedFee)
        ).toString(),
      ).toFixed(2);
      dailyWageObj["adjustments"] = parseFloat(
        (
          parseFloat(dailyWageObj["adjustments"]) + parseFloat(data.adjustments)
        ).toString(),
      ).toFixed(2);
      dailyWageObj["totalWage"] = parseFloat(
        parseFloat(dailyWageObj["totalWage"]) +
          data.fixedFee +
          parseFloat(data.adjustments),
      ).toFixed(2);
    });
    return dailyWageObj;
  };

  const handleName = () => {
    if (totalModalName === "deliveryWage") {
      return "DELIVERY WAGE";
    } else if (totalModalName === "cashCollect") {
      return "CASH COLLECT";
    } else if (totalModalName === "totalWage") {
      return "TOTAL WAGE";
    }
  };

  const handleBoxData = () => {
    if (totalModalName === "deliveryWage") {
      return `£ ${parseFloat(totalBox?.deliveryWage).toFixed(2)}`;
    } else if (totalModalName === "cashCollect") {
      return `£ ${parseFloat(totalBox?.cashCollect).toFixed(2)}`;
    } else if (totalModalName === "totalWage") {
      return `£ ${parseFloat(totalBox?.totalWage).toFixed(2)}`;
    }
  };

  const theme: CustomTheme = useTheme();
  console.log("asdasddsfsdgdfgdfg", driverDetails);
  return (
    <DialogCommonDefault
      open={open}
      setOpen={setOpen}
      isNeedFixedHeight={false}
      maxWidth={
        totalModalName === "dailyWage" || totalModalName === "deliveryTotalWage"
          ? "lg"
          : "sm"
      }
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{handleModalName()}</Typography>

          <CancelIcon
            onClick={() => setOpen(false)}
            style={{ color: theme.palette.custom.orange.contrastText, cursor: "pointer" }}
          />
        </div>
      </DialogTitle>
      <DialogContent style={{ marginBottom: "26px" }}>
        {(totalModalName === "cashCollect" ||
          totalModalName === "deliveryWage" ||
          totalModalName === "totalWage") && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography variant={"body2"} align="center">
                    {handleName()}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {handleBoxData()}
                  </Typography>
                </div>
              </CardCommon>
            </Grid>
          </Grid>
        )}
        {totalModalName === "deliveryTotalWage" && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography variant={"body2"} align="center">
                    ORDERS
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {totalBox.totalOrders}
                  </Typography>
                </div>
              </CardCommon>
            </Grid>
            <Grid item xs={4}>
              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography variant={"body2"} align="center">
                    RE-DROPS
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {totalBox.totalReDrops}
                  </Typography>
                </div>
              </CardCommon>
            </Grid>
            <Grid item xs={4}>
              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography variant={"body2"} align="center">
                    TOTAL
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {totalBox.totalOrders + totalBox.totalReDrops}
                  </Typography>
                </div>
              </CardCommon>
            </Grid>
          </Grid>
        )}
        {totalModalName === "dailyWage" && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography variant={"body2"} align="center">
                    DAILY WAGE
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {`£ ${handleDailyWage().dailyWage}`}
                  </Typography>
                </div>
              </CardCommon>
            </Grid>
            <Grid item xs={4}>
              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography variant={"body2"} align="center">
                    ADJUSTMENTS
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {`£ ${handleDailyWage().adjustments}`}
                  </Typography>
                </div>
              </CardCommon>
            </Grid>
            <Grid item xs={4}>
              <CardCommon
                backgroundColor="entity_background"
                style={{
                  height: "100px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography variant={"body2"} align="center">
                    Total
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ fontWeight: "bold", marginTop: "8px" }}
                  >
                    {`£ ${handleDailyWage().totalWage}`}
                  </Typography>
                </div>
              </CardCommon>
            </Grid>
          </Grid>
        )}
        {totalModalName === "dailyWage" ? (
          <Grid
            container
            style={{
              marginTop: "16px",
            }}
          >
            <Grid item xs={12}>
              <CardCommon backgroundColor={"entity_background"}>
                <Grid container style={{ placeItems: "center" }}>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "16px",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography variant="body1">Driver Name</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography variant="body1">Daily Wage</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography variant="body1">Adjustments</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography variant="body1">Total</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "6px",
                      justifyContent: "end",
                      paddingRight: "16px",
                    }}
                  >
                    <Typography variant="body1">Comments</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      paddingRight: "16px",
                      paddingTop: "6px",
                    }}
                  ></Grid>
                </Grid>
              </CardCommon>
            </Grid>
          </Grid>
        ) : totalModalName === "deliveryTotalWage" ? (
          <Grid
            container
            style={{
              marginTop: "16px",
            }}
          >
            <Grid item xs={12}>
              <CardCommon backgroundColor={"entity_background"}>
                <Grid
                  container
                  style={{ placeItems: "center", minHeight: "36px" }}
                >
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "16px",
                    }}
                  >
                    <Typography variant="body1">Driver Name</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Orders</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">Re-Drops</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      paddingRight: "16px",
                    }}
                  >
                    <Typography variant="body1">Total</Typography>
                  </Grid>
                </Grid>
              </CardCommon>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            style={{
              marginTop: "16px",
            }}
          >
            <Grid item xs={12}>
              <CardCommon
                backgroundColor={"entity_background"}
                style={{ height: "36px" }}
              >
                <Grid container style={{ placeItems: "center" }}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "16px",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography variant="body1">Name</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      paddingRight: "16px",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography variant="body1">
                      {" "}
                      {handleModalName()}
                    </Typography>
                  </Grid>
                </Grid>
              </CardCommon>
            </Grid>
          </Grid>
        )}
        {driverDetails.map((data: any, index: any) => (
          <Grid container>
            <Grid item xs={12} style={{ marginTop: "2px" }}>
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{ minHeight: "36px" }}
              >
                {totalModalName === "dailyWage" ? (
                  <Grid container style={{ placeItems: "center" }}>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "16px",
                        paddingTop: "6px",
                      }}
                    >
                      <Typography variant="body1">
                        {data.cmsDriverName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        {parseFloat(data.fixedFee).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        {parseFloat(data.adjustments).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        {parseFloat(
                          (
                            parseFloat(data.fixedFee) +
                            parseFloat(data.adjustments)
                          ).toString(),
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid container>
                        {data?.log &&
                          data.log.map((log: any) => (
                            <Grid item xs={12}>
                              <Typography variant="body1" align="right">
                                {log.type === "Adjustment" && log.notes}
                              </Typography>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : totalModalName === "deliveryTotalWage" ? (
                  <Grid container style={{ placeItems: "center" }}>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "16px",
                        paddingTop: "6px",
                      }}
                    >
                      <Typography variant="body1">
                        {data.cmsDriverName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        {
                          totalOrders[
                            `${index + 1}_${data.cmsDriverName}_count_orders`
                          ]
                        }
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        {
                          totalOrders[
                            `${index + 1}_${
                              data.cmsDriverName
                            }_count_orders_redrops`
                          ]
                        }
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        paddingRight: "16px",
                      }}
                    >
                      <Typography variant="body1">
                        {totalOrders[
                          `${index + 1}_${data.cmsDriverName}_count_orders`
                        ] +
                          totalOrders[
                            `${index + 1}_${
                              data.cmsDriverName
                            }_count_orders_redrops`
                          ]}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container style={{ placeItems: "center" }}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "16px",
                        paddingTop: "6px",
                      }}
                    >
                      <Typography variant="body1">
                        {data.cmsDriverName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        paddingRight: "16px",
                        paddingTop: "6px",
                      }}
                    >
                      <Typography variant="body1">
                        {handleData(data)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </CardCommon>
            </Grid>
          </Grid>
        ))}

        {/* {driverDetails.map((data: any) => )} */}
      </DialogContent>
      <DialogActions style={{ marginBottom: "20px" }}>
        <ButtonCommon
          variant="contained"
          style={{ fontSize: 11, width: "100px", marginRight: 8 }}
          color="yellow"
          onClick={() => setOpen(false)}
        >
          Cancel
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default TotalModal;
