import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import Loading from "./Loading";
import {
  fetchGlobalDepartmentInfo,
  fetchGlobalGroupInfo,
  fetchProductWrapperInfo,
} from "../../../../services/menuApp/printerMenuService";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";

export interface PrinterMappingProps {}

/**
 * Component: PrinterMappingNew
 *
 * This component is responsible for rendering the printer mapping page. It retrieves global group, department, and
 * product information from the backend, restructures the data, and passes it down to the Loading component for rendering.
 * It also handles error states and displays an alert in case of unexpected errors.
 */
const PrinterMappingNew: React.FunctionComponent<PrinterMappingProps> = () => {
  const [menuNodeList, setMenuNodeList] = useState<any>({});
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [linkedIdList, setLinkedIdList] = useState<any>([]);

  const match: any = useRouteMatch();

  function convertInputToOutput(productWrapper: any) {
    const output = [];
    const directories: any = {};

    // Group items by their directoryId
    productWrapper.forEach((item: any) => {
      if (item.directoryId in directories) {
        directories[item.directoryId].push(item);
      } else {
        directories[item.directoryId] = [item];
      }
    });

    // Recursive function to build wrapper structure
    function buildWrapper(directoryId: any) {
      if (directoryId in directories) {
        const items = directories[directoryId];
        return items.map((item: any) => {
          const {
            directoryId,
            directoryLevel,
            id,
            name,
            allYouCanEat,
            categoryId,
            colour,
            cover,
            created,
            desc,
            directory,
            img,
            itemType,
            kiosk,
            locationId,
            nameShort,
            template,
            timeFrom,
            timeTo,
            updated,
            valid,
            version,
            viewOrder,
            weekFrom,
            weekTo,
          } = item;
          const wrapper = buildWrapper(id);
          if (wrapper.length > 0) {
            return {
              directoryId,
              directoryLevel,
              id,
              name,
              wrapper,
              allYouCanEat,
              categoryId,
              colour,
              cover,
              created,
              desc,
              directory,
              img,
              itemType,
              kiosk,
              locationId,
              nameShort,
              template,
              timeFrom,
              timeTo,
              updated,
              valid,
              version,
              viewOrder,
              weekFrom,
              weekTo,
            };
          } else {
            return {
              directoryId,
              directoryLevel,
              id,
              name,
              allYouCanEat,
              categoryId,
              colour,
              cover,
              created,
              desc,
              directory,
              img,
              itemType,
              kiosk,
              locationId,
              nameShort,
              template,
              timeFrom,
              timeTo,
              updated,
              valid,
              version,
              viewOrder,
              weekFrom,
              weekTo,
            };
          }
        });
      } else {
        return [];
      }
    }

    // Build wrapper structure for items with directoryId -99
    output.push(...buildWrapper(-99));

    return output;
  }

  /**
   * Function: getGroupInfo
   *
   * This function is responsible for fetching global group information from the backend API using the provided location ID.
   * It then calls the getDepartmentInfo function to proceed with fetching department information.
   */
  const getGroupInfo = async () => {
    try {
      // Fetch global group information from the backend
      const res = await fetchGlobalGroupInfo(match.params.locationId);
      // Proceed with fetching department information
      getDepartmentInfo(res.data.data);
    } catch (err) {
      // Handle unexpected errors by setting the error state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: getDepartmentInfo
   *
   * This function is responsible for fetching global department information from the backend API using the provided location ID.
   * It then calls the getProductInfo function to proceed with fetching product information.
   */
  const getDepartmentInfo = async (groupNode: any) => {
    try {
      // Fetch global department information from the backend
      const res = await fetchGlobalDepartmentInfo(match.params.locationId);
      // Proceed with fetching product information
      getProductInfo(groupNode, res.data.data);
    } catch (err) {
      // Handle unexpected errors by setting the error state and isLoading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * Function: getProductInfo
   *
   * This function is responsible for fetching global product information from the backend API using the provided location ID.
   * It then calls the handleMenuRestructure function to restructure the menu data accordingly.
   */
  const getProductInfo = async (groupNode: any, departmentNode: any) => {
    try {
      // Fetch global product information from the backend
      const res = await fetchProductWrapperInfo(match.params.locationId);
      // // Restructure the menu data based on group, department, and product information
      const menu = await handleMenuRestructure(
        // group,
        // department,
        // productWrapper,
        groupNode,
        departmentNode,
        res.data.data,
      );
      // // Update the menuNodeList state with the restructured menu data
      setMenuNodeList(menu);
      // Set isLoading to false to indicate the loading process has ended
      setIsLoading(false);
    } catch (err) {
      // Handle unexpected errors by setting the error state and isLoading state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * Function: handleMenuRestructure
   *
   * This function is responsible for restructuring the menu data based on the provided group, department, and product nodes.
   * It filters and sorts the nodes and organizes them into a hierarchical structure.
   */
  const handleMenuRestructure = (
    groupNode: any,
    departmentNode: any,
    productNode: any,
  ) => {
    // Check if the group, department, and product nodes are not empty
    if (
      !_.isEmpty(groupNode) &&
      !_.isEmpty(departmentNode) &&
      !_.isEmpty(productNode)
    ) {
      // Sort department, product, and group nodes based on viewOrder property
      const filterDepartment = departmentNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterProduct = productNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterGroup = groupNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );

      // Initialize variables to store linked IDs and restructured menu data
      const linkedId: any = [];
      const output: any = [];
      let groupObject: any = {};
      // Iterate over each group node
      filterGroup.forEach((groupItem: any) => {
        if (groupItem.id > 0) {
          groupObject = {
            ...groupItem,
            department: [],
          };
        }

        // Filter matching departments for the current group
        const matchingDepartments = filterDepartment.filter(
          (departmentItem: any) =>
            departmentItem.id > 0 &&
            departmentItem.groupId === groupItem.id &&
            departmentItem.name !== "Online Hidden" &&
            departmentItem.name !== "Templates" &&
            departmentItem.name !== "Hidden Food" &&
            departmentItem.name !== "Hidden Drinks",
        );

        // Iterate over each matching department
        matchingDepartments.forEach((departmentItem: any) => {
          // Create a department object with product object
          const departmentObject: any = {
            ...departmentItem,
            product: [],
          };

          // Filter matching products for the current department
          const matchingWrapper = filterProduct.filter(
            (wrapperItem: any) =>
              wrapperItem.categoryId === departmentItem.id &&
              wrapperItem.id > 0,
          );

          if (convertInputToOutput(matchingWrapper).length > 0) {
            departmentObject.product.push(
              ...convertInputToOutput(matchingWrapper),
            );
          }

          // // Add department to the group
          if (departmentObject?.product?.length !== 0) {
            groupObject.department.push(departmentObject);
          }
        });
        if (
          groupObject &&
          groupObject?.department &&
          groupObject?.department?.length !== 0
        ) {
          // Add group to the output
          output.push(groupObject);
        }
      });
      // Update linkedIdList state with the linked IDs
      setLinkedIdList(linkedId);
      // Return the restructured menu data
      return output;
    }
  };

  /**
   * useEffect hook to handle side effects after component mount
   */
  useEffect(() => {
    // Set document title
    document.title = "Menu - Menu Printer Information";

    // Call getGroupInfo function to fetch group information
    getGroupInfo();

    // Set isLoading state to true to indicate loading state
    setIsLoading(true);
  }, []); // Empty dependency array ensures that this effect runs only oncse after component mount

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile isNeedFixed={true} />
      </Hidden>

      <Box>
        <Loading
          isLoading={isLoading}
          menuNodeList={menuNodeList}
          linkedIdList={linkedIdList}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(PrinterMappingNew, Authorities.MENU_READ);
